import { createSlice } from "@reduxjs/toolkit";

const defaultNetwork = {
  chainId: 0,
  name: "",
  provider: null,
  web3: null,
};

const networkSlice = createSlice({
  name: "network",
  initialState: defaultNetwork,
  reducers: {
    updateNetwork(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    clearNetwork(state) {
      Object.keys(defaultNetwork).forEach((key) => {
        state[key] = defaultNetwork[key];
      });
    },
  },
});

export const { updateNetwork, clearNetwork } = networkSlice.actions;

export default networkSlice.reducer;
