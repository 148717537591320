import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import ProfilePin from "./profilePin";
import axios from "axios";
import { allCountriesApi } from "../../utils/constants";
import unitedStatesFlag from "../../assests/page/Flag-United-States-of-America 1.svg";
import myLocation from "../../assests/page/my_location.svg";
import searchIcon from "../../assests/sideBar/search-icon.svg";
import { TermAndConditionOverlay } from "../overlays/utils";
import SuccessPopup from "./success";
import ProfilePopup from "./profile";

const ProfileEditCountry = (props) => {
  const [selectedCountry, setSelectedCountry] = useState({
    name: "",
    flag: unitedStatesFlag,
  });
  const [selectedCountryIdx, setSelectedCountryIdx] = useState(null);
  const [userInput, setUserInput] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [allCountries, setAllCountries] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const getAllcountries = async () => {
        await axios({ method: "GET", url: allCountriesApi })
          .then((res) => {
            setAllCountries(res.data);
          })
          .catch((err) => {
            console.error("get all countries failled with error: ", err);
            //Todo: toast here
          });
      };

      await getAllcountries();
    })();
  }, []);

  return (
    <div className="search-filter-popup">
      <div style={{ height: "79.26%" }} className="search-filter-popup-content">
        <div className="asset-info-popup-header">
          <div className="asset-info-popup-header-main">
            <div className="asset-info-popup-header-main-text">
              <img
                onClick={() => {
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <ProfilePin
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                      setFavouriteClicked={props.setFavouriteClicked}
                      showProfileDetails={true}
                      nextPopup={
                        <ProfileEditCountry
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                          setFavouriteClicked={props.setFavouriteClicked}
                        />
                      }
                    />
                  );
                }}
                alt="Back Icon"
                src={backIcon}
              />
              <p className="search-filter-popup-header-text">
                Change Default Country
              </p>
            </div>
            <div className="asset-info-popup-icons">
              <img
                onClick={() => {
                  props.setPopupContentPosition("center");
                  props.setPopupContent(null);
                  props.setFavouriteClicked(false);
                  props.setShowPopup(false);
                  navigate(
                    window.location.href
                      .replace(window.location.search, "")
                      .replace(window.location.origin, "")
                  );
                }}
                alt="Close Icon"
                src={popupCloseIcon}
              />
            </div>
          </div>
        </div>

        <div className="profile-edit-contact-body">
          <div className="profile-edit-country-header">
            <p className="profile-edit-country-header-text">
              Set your default country
            </p>

            <div className="profile-edit-country-header-body">
              <img src={myLocation} alt="Location Icon" />

              <div className="profile-edit-country-header-body-text-container">
                <p className="profile-edit-country-header-body-text">
                  Use my current location as default
                </p>
              </div>
            </div>
          </div>

          <div className="profile-edit-country-body">
            <div className="profile-edit-country-body-first">
              <div className="profile-edit-country-body-line"></div>
              <p className="profile-edit-country-body-text">OR</p>
              <div className="profile-edit-country-body-line"></div>
            </div>

            <div className="profile-edit-country-body-content-container">
              <div className="profile-edit-country-body-input-container">
                <img src={searchIcon} alt="Search Icon" />
                <input
                  className="profile-edit-country-body-input"
                  type="text"
                  placeholder="Search for country"
                />
              </div>

              <div className="profile-edit-country-body-content-group">
                {allCountries.map((cnt, idx) => (
                  <>
                    {selectedCountryIdx !== `${idx}` && (
                      <div
                        onClick={() => {
                          setSelectedCountry({
                            name: cnt.name.common,
                            flag: cnt.flags.svg,
                          });
                          setSelectedCountryIdx(`${idx}`);
                        }}
                        className="profile-edit-country-body-content"
                      >
                        <div className="profile-edit-country-body-content-icon">
                          <img src={cnt.flags.svg} alt="Country Flag" />
                        </div>
                        <p className="profile-edit-country-body-content-text">
                          {cnt.name.common}
                        </p>
                      </div>
                    )}
                    {selectedCountryIdx === `${idx}` && (
                      <div
                        onClick={() => {
                          setSelectedCountry({
                            name: cnt.name.common,
                            flag: cnt.flags.svg,
                          });
                          setSelectedCountryIdx(`${idx}`);
                        }}
                        className="profile-edit-country-body-content-active"
                      >
                        <div className="profile-edit-country-body-content-icon">
                          <img src={cnt.flags.svg} alt="Country Flag" />
                        </div>
                        <p className="profile-edit-country-body-content-text">
                          {cnt.name.common}
                        </p>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ marginBottom: "50px" }}
        className="profile-edit-contact-footer"
      >
        <div className="profile-edit-contact-footer-first-section">
          <TermAndConditionOverlay />
          <button
            id="profile-edit-contact-button"
            onClick={(e) => {
              if (
                e.target.className.includes("startup-button") &&
                e.target.className !== "startup-button"
              ) {
                e.target.className = "startup-button-click";
                props.setPopupContentPosition("flex-end");
                props.setPopupContent(
                  <SuccessPopup
                    setShowPopup={props.setShowPopup}
                    setPopupContent={props.setPopupContent}
                    setPopupContentPosition={props.setPopupContentPosition}
                    isCustom={true}
                    customTextHeader="Congratulations!"
                    customTextFooter="Your Default Country has successfully changed!"
                    setFavouriteClicked={props.setFavouriteClicked}
                    nextPopup={
                      <ProfilePopup
                        setShowPopup={props.setShowPopup}
                        setPopupContent={props.setPopupContent}
                        setPopupContentPosition={props.setPopupContentPosition}
                        setFavouriteClicked={props.setFavouriteClicked}
                        showProfileDetails={true}
                      />
                    }
                  />
                );
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <SuccessPopup
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                      isCustom={true}
                      customTextHeader="Congratulations!"
                      customTextFooter="Your Default Country has successfully changed!"
                      setFavouriteClicked={props.setFavouriteClicked}
                      nextPopup={
                        <ProfilePopup
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                          setFavouriteClicked={props.setFavouriteClicked}
                          showProfileDetails={true}
                        />
                      }
                    />
                  );
                }
              }
            }}
            className="startup-button-active"
          >
            <p className="st-button-text">Proceed</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileEditCountry;
