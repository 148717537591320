import dashboardIcon from "../../assests/sideBar/dashboard-icon.svg";
import dashboardActiveIcon from "../../assests/sideBar/dashboard-active-icon.svg";
import paymentsIcon from "../../assests/sideBar/payments-icon.svg";
import paymentsActiveIcon from "../../assests/sideBar/payments-active-icon.svg";
import investmentsIcon from "../../assests/sideBar/investments-icon.svg";
import investmentsInactiveIcon from "../../assests/sideBar/investments-inactive-icon.svg";
import loansIcon from "../../assests/sideBar/loans-icon.svg";
import loansActiveIcon from "../../assests/sideBar/loans-active-icon.svg";
import { useNavigate } from "react-router-dom";

const CommonFooter = () => {
  const navigate = useNavigate();
  return (
    <footer className="common-footer">
      {window.location.pathname === "/dashboard" ? (
        <div
          onClick={() => {
            navigate("/dashboard");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container-active">
            <img
              className="footer-menu-icon"
              alt="Dashboard Icon"
              src={dashboardActiveIcon}
            />
          </div>
          <p className="fotter-menu-text-active">Dashboard</p>
        </div>
      ) : (
        <div
          onClick={() => {
            navigate("/dashboard");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container">
            <img
              className="footer-menu-icon"
              alt="Dashboard Icon"
              src={dashboardIcon}
            />
          </div>
          <p className="fotter-menu-text">Dashboard</p>
        </div>
      )}
      {/* {window.location.pathname === "/payments" ? (
        <div
          onClick={() => {
            navigate("/payments");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container-active">
            <img
              className="footer-menu-icon"
              alt="Payments Icon"
              src={paymentsActiveIcon}
            />
          </div>
          <p className="fotter-menu-text-active">Payments</p>
        </div>
      ) : (
        <div
          onClick={() => {
            navigate("/payments");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container">
            <img
              className="footer-menu-icon"
              alt="Payments Icon"
              src={paymentsIcon}
            />
          </div>
          <p className="fotter-menu-text">Payments</p>
        </div>
      )} */}
      {window.location.pathname.includes("/investments") ? (
        <div
          onClick={() => {
            navigate("/investments");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container-active">
            <img
              className="footer-menu-icon"
              alt="Investmnts Icon"
              src={investmentsIcon}
            />
          </div>
          <p className="fotter-menu-text-active">Investments</p>
        </div>
      ) : (
        <div
          onClick={() => {
            navigate("/investments");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container">
            <img
              className="footer-menu-icon"
              alt="Investmnts Icon"
              src={investmentsInactiveIcon}
            />
          </div>
          <p className="fotter-menu-text">Investments</p>
        </div>
      )}
      {/* {window.location.pathname === "/loans" ? (
        <div
          onClick={() => {
            navigate("/loans");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container-active">
            <img
              className="footer-menu-icon"
              alt="Loans Icon"
              src={loansActiveIcon}
            />
          </div>
          <p className="fotter-menu-text-active">Loans</p>
        </div>
      ) : (
        <div
          onClick={() => {
            navigate("/loans");
          }}
          className="footer-menu"
        >
          <div className="footer-menu-icon-container">
            <img
              className="footer-menu-icon"
              alt="Loans Icon"
              src={loansIcon}
            />
          </div>
          <p className="fotter-menu-text">Loans</p>
        </div>
      )} */}
    </footer>
  );
};

export default CommonFooter;
