import backIcon from "../../assests/page/chevron-left.svg";
import searchIcon from "../../assests/sideBar/search-icon.svg";
import checkboxIcon from "../../assests/page/checkbox-icon.svg";
import checkboxIconClicked from "../../assests/page/checkbox-icon-clicked.svg";
import { searchFilterTypes } from "../../utils/constants";
import { useState } from "react";

const SearchFilterPopup = (props) => {
  const [typeClikedName, setTypeClickedName] = useState(
    Object.keys(searchFilterTypes)[0]
  );
  const [typeCliked, setTypeClicked] = useState(
    searchFilterTypes[Object.keys(searchFilterTypes)[0]]
  );

  const clearAllClickedCheckboxes = (forceClear, type) => {
    if (props.setNewProductToSearch) {
      props.setNewProductToSearch(null);
    }
    const checkboxes = document.getElementsByClassName(
      "search-filter-popup-footer-type-result-checkbox"
    );
    if (checkboxes.length > 0) {
      if (type) {
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].id.includes(type)) {
            checkboxes[i].src = checkboxIcon;
          }
        }
      } else {
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].src = checkboxIcon;
        }
      }
    }
    const selectAllBox = document.getElementById(
      `select-all-checkbox-${typeClikedName.replace(" ", "-")}`
    );
    selectAllBox.src = checkboxIcon;
    if (forceClear && type) {
      props.allFilters[type] = [];
      props.setAllFilters(props.allFilters);
    } else if (forceClear) {
      props.setAllFilters({});
    }
  };

  const handleTextClicked = (idx, type) => {
    const prevActive = document.getElementsByClassName(
      "search-filter-popup-footer-type-text-active"
    );
    if (prevActive.length > 0) {
      prevActive[prevActive.length - 1].className =
        "search-filter-popup-footer-type-text";
    }
    const nextActive = document.getElementById(`search-type-${idx}`);
    nextActive.className = "search-filter-popup-footer-type-text-active";
    setTypeClicked(searchFilterTypes[type]);
    clearAllClickedCheckboxes(false);
  };

  const selectAllCheckboxes = (forceSelect, type) => {
    const checkboxes = document.getElementsByClassName(
      "search-filter-popup-footer-type-result-checkbox"
    );
    if (checkboxes.length > 0) {
      if (type) {
        for (let i = 0; i < checkboxes.length; i++) {
          if (checkboxes[i].id.includes(type)) {
            checkboxes[i].src = checkboxIconClicked;
          }
        }
      } else {
        for (let i = 0; i < checkboxes.length; i++) {
          checkboxes[i].src = checkboxIconClicked;
        }
      }
    }
    if (forceSelect && type) {
      if (searchFilterTypes[type][0]["name"]) {
        // eslint-disable-next-line array-callback-return
        searchFilterTypes[type].map((typ) => {
          if (
            props.allFilters[type] &&
            !props.allFilters[type].includes(typ["name"])
          ) {
            props.allFilters[type] = [typ["name"], ...props.allFilters[type]];
          } else if (!props.allFilters[type]) {
            props.allFilters[type] = [typ["name"]];
          }
        });
      } else {
        // eslint-disable-next-line array-callback-return
        searchFilterTypes[type].map((typ) => {
          if (props.allFilters[type] && !props.allFilters[type].includes(typ)) {
            props.allFilters[type] = [typ, ...props.allFilters[type]];
          } else if (!props.allFilters[type]) {
            props.allFilters[type] = [typ];
          }
        });
      }
    }
  };

  return (
    <div className="search-filter-popup">
      <div className="search-filter-popup-content">
        <div className="search-filter-popup-header">
          <div className="search-filter-popup-header-main">
            <img
              onClick={() => {
                props.setShowPopup(false);
                props.setPopupContentPosition("center");
                props.setPopupContent(null);
                props.setFavouriteClicked(false);
              }}
              className="add-funds-popup-back-icon"
              alt="Back Icon"
              src={backIcon}
            />
            <p className="search-filter-popup-header-text">Filters</p>
          </div>
          <p
            onClick={() => {
              clearAllClickedCheckboxes(true);
            }}
            className="search-filter-popup-header-label"
          >
            Clear all
          </p>
        </div>
        <div className="search-filter-popup-footer">
          <div className="search-filter-popup-footer-search">
            <img src={searchIcon} alt="Search Icon" />
            <input
              type="text"
              className="search-filter-popup-footer-input"
              placeholder="Search a Currency"
            />
          </div>
          <div className="search-filter-popup-footer-content">
            <div className="search-filter-popup-footer-first">
              {Object.keys(searchFilterTypes).map((typ, idx) => (
                <>
                  {idx !== Object.keys(searchFilterTypes).length - 1 && (
                    <>
                      {idx === 0 && (
                        <div
                          id={idx}
                          className="search-filter-popup-footer-type"
                        >
                          <p
                            id={`search-type-${idx}`}
                            onClickCapture={() => {
                              setTypeClickedName(typ);
                            }}
                            onClick={() => {
                              handleTextClicked(idx, typ);
                            }}
                            className="search-filter-popup-footer-type-text-active"
                          >
                            {typ}
                          </p>
                        </div>
                      )}
                      {idx !== 0 && (
                        <div
                          id={idx}
                          className="search-filter-popup-footer-type"
                        >
                          <p
                            id={`search-type-${idx}`}
                            onClickCapture={() => {
                              setTypeClickedName(typ);
                            }}
                            onClick={() => {
                              handleTextClicked(idx, typ);
                            }}
                            className="search-filter-popup-footer-type-text"
                          >
                            {typ}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  {idx === Object.keys(searchFilterTypes).length - 1 && (
                    <div
                      id={idx}
                      style={{ borderBottom: "none" }}
                      className="search-filter-popup-footer-type"
                    >
                      <p
                        id={`search-type-${idx}`}
                        onClickCapture={() => {
                          setTypeClickedName(typ);
                        }}
                        onClick={() => {
                          handleTextClicked(idx, typ);
                        }}
                        className="search-filter-popup-footer-type-text"
                      >
                        {typ}
                      </p>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="search-filter-popup-footer-second">
              <div className="search-filter-popup-footer-type-result-header">
                <img
                  id={`select-all-checkbox-${typeClikedName.replace(" ", "-")}`}
                  onClick={(e) => {
                    if (
                      e.target.src.split("/")[
                        e.target.src.split("/").length - 1
                      ] ===
                      checkboxIcon.split("/")[
                        checkboxIcon.split("/").length - 1
                      ]
                    ) {
                      e.target.src = checkboxIconClicked;
                      selectAllCheckboxes(true, typeClikedName);
                    } else {
                      e.target.src = checkboxIcon;
                      clearAllClickedCheckboxes(true, typeClikedName);
                    }
                  }}
                  className="search-filter-popup-footer-type-result-checkbox"
                  src={checkboxIcon}
                  alt="Checkbox Icon"
                />
                <p className="search-filter-popup-footer-type-result-text">
                  Select all
                </p>
              </div>
              <div className="search-filter-popup-footer-type-result-main">
                <p className="search-filter-popup-footer-type-result-main-text">
                  All categories
                </p>
                <div className="search-filter-popup-footer-type-result-main-body">
                  {props.allFilters &&
                    Object.keys(props.allFilters).length === 0 && (
                      <>
                        {typeCliked.map((typs, idx) => (
                          <>
                            {!typs["label"] && (
                              <div
                                id={`typ-${idx}`}
                                className="search-filter-popup-footer-type-result-header"
                              >
                                <img
                                  id={`type-result-checkbox-${typeClikedName}`}
                                  onClick={(e) => {
                                    e.target.src.split("/")[
                                      e.target.src.split("/").length - 1
                                    ] ===
                                    checkboxIcon.split("/")[
                                      checkboxIcon.split("/").length - 1
                                    ]
                                      ? (e.target.src = checkboxIconClicked)
                                      : (e.target.src = checkboxIcon);
                                    let newTypeFilters;
                                    if (
                                      props.allFilters[typeClikedName] &&
                                      !props.allFilters[
                                        typeClikedName
                                      ].includes(typs)
                                    ) {
                                      newTypeFilters = [
                                        typs,
                                        ...props.allFilters[typeClikedName],
                                      ];
                                    } else if (
                                      props.allFilters[typeClikedName] &&
                                      props.allFilters[typeClikedName].includes(
                                        typs
                                      )
                                    ) {
                                      const prevIndex =
                                        props.allFilters[
                                          typeClikedName
                                        ].indexOf(typs);
                                      props.allFilters[typeClikedName].splice(
                                        prevIndex,
                                        1
                                      );
                                    } else if (
                                      !props.allFilters[typeClikedName]
                                    ) {
                                      newTypeFilters = [typs];
                                    }

                                    if (newTypeFilters) {
                                      props.allFilters[typeClikedName] =
                                        newTypeFilters;
                                      props.setAllFilters(props.allFilters);
                                    }
                                  }}
                                  className="search-filter-popup-footer-type-result-checkbox"
                                  src={checkboxIcon}
                                  alt="Checkbox Icon"
                                />
                                <p className="search-filter-popup-footer-type-result-text">
                                  {typs}
                                </p>
                              </div>
                            )}
                            {typs["label"] && (
                              <div
                                id={`typ-${idx}`}
                                className="search-filter-popup-footer-type-result-header"
                              >
                                <img
                                  id={`type-result-checkbox-${typeClikedName}`}
                                  onClick={(e) => {
                                    e.target.src.split("/")[
                                      e.target.src.split("/").length - 1
                                    ] ===
                                    checkboxIcon.split("/")[
                                      checkboxIcon.split("/").length - 1
                                    ]
                                      ? (e.target.src = checkboxIconClicked)
                                      : (e.target.src = checkboxIcon);
                                    let newTypeFilters;
                                    if (
                                      props.allFilters[typeClikedName] &&
                                      !props.allFilters[
                                        typeClikedName
                                      ].includes(typs["name"])
                                    ) {
                                      newTypeFilters = [
                                        typs["name"],
                                        ...props.allFilters[typeClikedName],
                                      ];
                                    } else if (
                                      props.allFilters[typeClikedName] &&
                                      props.allFilters[typeClikedName].includes(
                                        typs["name"]
                                      )
                                    ) {
                                      const prevIndex = props.allFilters[
                                        typeClikedName
                                      ].indexOf(typs["name"]);
                                      props.allFilters[typeClikedName].splice(
                                        prevIndex,
                                        1
                                      );
                                    } else if (
                                      !props.allFilters[typeClikedName]
                                    ) {
                                      newTypeFilters = [typs["name"]];
                                    }

                                    if (newTypeFilters) {
                                      props.allFilters[typeClikedName] =
                                        newTypeFilters;
                                      props.setAllFilters(props.allFilters);
                                    }
                                  }}
                                  className="search-filter-popup-footer-type-result-checkbox"
                                  src={checkboxIcon}
                                  alt="Checkbox Icon"
                                />
                                <div className="search-filter-popup-footer-type-result-text-container">
                                  <p className="search-filter-popup-footer-type-result-text-header">
                                    {typs["name"]}
                                  </p>
                                  <p className="search-filter-popup-footer-type-result-text-footer">
                                    {typs["label"]}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    )}
                  {props.allFilters &&
                    Object.keys(props.allFilters).length > 0 && (
                      <>
                        {typeCliked.map((typs, idx) => (
                          <>
                            {!typs["label"] && (
                              <div
                                id={`typ-${idx}`}
                                className="search-filter-popup-footer-type-result-header"
                              >
                                {props.allFilters &&
                                  !props.allFilters[typeClikedName]?.includes(
                                    typs
                                  ) && (
                                    <img
                                      id={`type-result-checkbox-${typeClikedName}`}
                                      onClick={(e) => {
                                        e.target.src.split("/")[
                                          e.target.src.split("/").length - 1
                                        ] ===
                                        checkboxIcon.split("/")[
                                          checkboxIcon.split("/").length - 1
                                        ]
                                          ? (e.target.src = checkboxIconClicked)
                                          : (e.target.src = checkboxIcon);
                                        let newTypeFilters;
                                        if (
                                          props.allFilters[typeClikedName] &&
                                          !props.allFilters[
                                            typeClikedName
                                          ].includes(typs)
                                        ) {
                                          newTypeFilters = [
                                            typs,
                                            ...props.allFilters[typeClikedName],
                                          ];
                                        } else if (
                                          props.allFilters[typeClikedName] &&
                                          props.allFilters[
                                            typeClikedName
                                          ].includes(typs)
                                        ) {
                                          const prevIndex =
                                            props.allFilters[
                                              typeClikedName
                                            ].indexOf(typs);
                                          props.allFilters[
                                            typeClikedName
                                          ].splice(prevIndex, 1);
                                        } else if (
                                          !props.allFilters[typeClikedName]
                                        ) {
                                          newTypeFilters = [typs];
                                        }

                                        if (newTypeFilters) {
                                          props.allFilters[typeClikedName] =
                                            newTypeFilters;
                                          props.setAllFilters(props.allFilters);
                                        }
                                      }}
                                      className="search-filter-popup-footer-type-result-checkbox"
                                      src={checkboxIcon}
                                      alt="Checkbox Icon"
                                    />
                                  )}
                                {props.allFilters &&
                                  props.allFilters[typeClikedName]?.includes(
                                    typs
                                  ) && (
                                    <img
                                      id={`type-result-checkbox-${typeClikedName}`}
                                      onClick={(e) => {
                                        e.target.src.split("/")[
                                          e.target.src.split("/").length - 1
                                        ] ===
                                        checkboxIcon.split("/")[
                                          checkboxIcon.split("/").length - 1
                                        ]
                                          ? (e.target.src = checkboxIconClicked)
                                          : (e.target.src = checkboxIcon);
                                        let newTypeFilters;
                                        if (
                                          props.allFilters[typeClikedName] &&
                                          !props.allFilters[
                                            typeClikedName
                                          ].includes(typs)
                                        ) {
                                          newTypeFilters = [
                                            typs,
                                            ...props.allFilters[typeClikedName],
                                          ];
                                        } else if (
                                          props.allFilters[typeClikedName] &&
                                          props.allFilters[
                                            typeClikedName
                                          ].includes(typs)
                                        ) {
                                          const prevIndex =
                                            props.allFilters[
                                              typeClikedName
                                            ].indexOf(typs);
                                          props.allFilters[
                                            typeClikedName
                                          ].splice(prevIndex, 1);
                                        } else if (
                                          !props.allFilters[typeClikedName]
                                        ) {
                                          newTypeFilters = [typs];
                                        }

                                        if (newTypeFilters) {
                                          props.allFilters[typeClikedName] =
                                            newTypeFilters;
                                          props.setAllFilters(props.allFilters);
                                        }
                                      }}
                                      className="search-filter-popup-footer-type-result-checkbox"
                                      src={checkboxIconClicked}
                                      alt="Checkbox Icon"
                                    />
                                  )}
                                <p className="search-filter-popup-footer-type-result-text">
                                  {typs}
                                </p>
                              </div>
                            )}
                            {typs["label"] && (
                              <div
                                id={`typ-${idx}`}
                                className="search-filter-popup-footer-type-result-header"
                              >
                                {props.allFilters &&
                                  !props.allFilters[typeClikedName]?.includes(
                                    typs["name"]
                                  ) && (
                                    <img
                                      id={`type-result-checkbox-${typeClikedName}`}
                                      onClick={(e) => {
                                        e.target.src.split("/")[
                                          e.target.src.split("/").length - 1
                                        ] ===
                                        checkboxIcon.split("/")[
                                          checkboxIcon.split("/").length - 1
                                        ]
                                          ? (e.target.src = checkboxIconClicked)
                                          : (e.target.src = checkboxIcon);
                                        let newTypeFilters;
                                        if (
                                          props.allFilters[typeClikedName] &&
                                          !props.allFilters[
                                            typeClikedName
                                          ].includes(typs["name"])
                                        ) {
                                          newTypeFilters = [
                                            typs["name"],
                                            ...props.allFilters[typeClikedName],
                                          ];
                                        } else if (
                                          props.allFilters[typeClikedName] &&
                                          props.allFilters[
                                            typeClikedName
                                          ].includes(typs["name"])
                                        ) {
                                          const prevIndex = props.allFilters[
                                            typeClikedName
                                          ].indexOf(typs["name"]);
                                          props.allFilters[
                                            typeClikedName
                                          ].splice(prevIndex, 1);
                                        } else if (
                                          !props.allFilters[typeClikedName]
                                        ) {
                                          newTypeFilters = [typs["name"]];
                                        }

                                        if (newTypeFilters) {
                                          props.allFilters[typeClikedName] =
                                            newTypeFilters;
                                          props.setAllFilters(props.allFilters);
                                        }
                                      }}
                                      className="search-filter-popup-footer-type-result-checkbox"
                                      src={checkboxIcon}
                                      alt="Checkbox Icon"
                                    />
                                  )}
                                {props.allFilters &&
                                  props.allFilters[typeClikedName]?.includes(
                                    typs["name"]
                                  ) && (
                                    <img
                                      id={`type-result-checkbox-${typeClikedName}`}
                                      onClick={(e) => {
                                        e.target.src.split("/")[
                                          e.target.src.split("/").length - 1
                                        ] ===
                                        checkboxIcon.split("/")[
                                          checkboxIcon.split("/").length - 1
                                        ]
                                          ? (e.target.src = checkboxIconClicked)
                                          : (e.target.src = checkboxIcon);
                                        let newTypeFilters;
                                        if (
                                          props.allFilters[typeClikedName] &&
                                          !props.allFilters[
                                            typeClikedName
                                          ].includes(typs["name"])
                                        ) {
                                          newTypeFilters = [
                                            typs["name"],
                                            ...props.allFilters[typeClikedName],
                                          ];
                                        } else if (
                                          props.allFilters[typeClikedName] &&
                                          props.allFilters[
                                            typeClikedName
                                          ].includes(typs["name"])
                                        ) {
                                          const prevIndex = props.allFilters[
                                            typeClikedName
                                          ].indexOf(typs["name"]);
                                          props.allFilters[
                                            typeClikedName
                                          ].splice(prevIndex, 1);
                                        } else if (
                                          !props.allFilters[typeClikedName]
                                        ) {
                                          newTypeFilters = [typs["name"]];
                                        }

                                        if (newTypeFilters) {
                                          props.allFilters[typeClikedName] =
                                            newTypeFilters;
                                          props.setAllFilters(props.allFilters);
                                        }
                                      }}
                                      className="search-filter-popup-footer-type-result-checkbox"
                                      src={checkboxIconClicked}
                                      alt="Checkbox Icon"
                                    />
                                  )}

                                <div className="search-filter-popup-footer-type-result-text-container">
                                  <p className="search-filter-popup-footer-type-result-text-header">
                                    {typs["name"]}
                                  </p>
                                  <p className="search-filter-popup-footer-type-result-text-footer">
                                    {typs["label"]}
                                  </p>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: "60px", marginBottom: "50px" }}
        className="whitelist-popup-buttons"
      >
        <button
          onClick={() => {
            props.setShowPopup(false);
            props.setPopupContentPosition("center");
            props.setPopupContent(null);
            props.setFavouriteClicked(false);
          }}
          className="whitelist-popup-button"
        >
          <p className="whitelist-popup-button-text">Close</p>
        </button>
        <button
          onClick={() => {
            if (Object.values(props.allFilters).length > 0) {
              props.setShowFilterDot(true);
            } else {
              props.setShowFilterDot(false);
            }
            props.setShowPopup(false);
            props.setPopupContentPosition("center");
            props.setPopupContent(null);
            props.setFavouriteClicked(false);
          }}
          className="whitelist-popup-button-active"
        >
          <p className="whitelist-popup-button-text">Apply</p>
        </button>
      </div>
    </div>
  );
};

export default SearchFilterPopup;
