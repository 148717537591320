import { createSlice } from "@reduxjs/toolkit";

const defaultUser = {
  userAddress: "",
  userFullName: "",
  userFirstName: "",
  userEmail: "",
  userCountry: "",
  userCountryCode: "",
  userBalance: "",
  userRole: "investor",
  userKycStatus: 0,
  userImageLink: "",
  userType: "",
  isConnected: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: defaultUser,
  reducers: {
    updateUser(state, action) {
      state[action.payload.name] = action.payload.value;
    },
    clearUser(state) {
      Object.keys(defaultUser).forEach((key) => {
        state[key] = defaultUser[key];
      });
    },
  },
});

export const { updateUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
