import dropdownIcon from "../../assests/sideBar/dropdown-icon.svg";
import collapseIcon from "../../assests/sideBar/collapse-icon.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SideBarMenu = (props) => {
  const [dropDownClicked, setDropDownClicked] = useState(true);

  const navigate = useNavigate();

  return (
    <>
      {/* handle 0 dropdown behaviour */}
      {props.dropdowns.length === 0 && (
        <>
          {/* handle dashboard for homepage */}
          {window.location.pathname === "/" &&
            props.name &&
            props.name.toLowerCase() === "dashboard" && (
              <div
                className="s-menu-active"
                onClick={(e) => {
                  navigate(`/${props.name?.replace(" ", "-").toLowerCase()}`);
                }}
              >
                <div className="s-menu-container">
                  <img
                    className="s-menu-icon"
                    src={props.iconActive || props.iconInActive}
                    alt={props.name + "Icon"}
                    style={
                      props.iconWidth ? { width: `${props.iconWidth}px` } : {}
                    }
                  />
                  {props.fullSidebar && (
                    <p className="s-menu-text-active">{props.name}</p>
                  )}
                </div>
              </div>
            )}
          {window.location.pathname === "/" &&
            props.name &&
            props.name.toLowerCase() !== "dashboard" && (
              <div
                className="s-menu"
                onClick={(e) => {
                  navigate(`/${props.name?.replace(" ", "-").toLowerCase()}`);
                }}
              >
                <div className="s-menu-container">
                  <img
                    className="s-menu-icon"
                    src={props.iconInActive}
                    alt={props.name + "Icon"}
                    style={
                      props.iconWidth ? { width: `${props.iconWidth}px` } : {}
                    }
                  />
                  {props.fullSidebar && (
                    <p className="s-menu-text">{props.name}</p>
                  )}
                </div>
              </div>
            )}

          {window.location.pathname ===
            `/${props.name?.replace(" ", "-").toLowerCase()}` && (
            <div
              className="s-menu-active"
              onClick={(e) => {
                navigate(`/${props.name?.replace(" ", "-").toLowerCase()}`);
              }}
            >
              <div className="s-menu-container">
                <img
                  className="s-menu-icon"
                  src={props.iconActive || props.iconInActive}
                  alt={props.name + "Icon"}
                />
                {props.fullSidebar && (
                  <p className="s-menu-text-active">{props.name}</p>
                )}
              </div>
            </div>
          )}
          {window.location.pathname !==
            `/${props.name?.replace(" ", "-").toLowerCase()}` &&
            window.location.pathname !== "/" && (
              <div
                className="s-menu"
                onClick={(e) => {
                  navigate(`/${props.name?.replace(" ", "-").toLowerCase()}`);
                }}
              >
                <div className="s-menu-container">
                  <img
                    className="s-menu-icon"
                    src={props.iconInActive}
                    alt={props.name + "Icon"}
                    style={
                      props.iconWidth ? { width: `${props.iconWidth}px` } : {}
                    }
                  />
                  {props.fullSidebar && (
                    <p className="s-menu-text">{props.name}</p>
                  )}
                </div>
              </div>
            )}
        </>
      )}

      {/* handle 1 or more dropdowns behaviour */}
      {props.dropdowns.length > 0 && (
        <div
          className="s-menu"
          onClick={(e) => {
            props.setFullSidebar(true);
            setDropDownClicked(!dropDownClicked);
          }}
        >
          {!dropDownClicked && (
            <>
              <div className="s-menu-container">
                <img
                  className="s-menu-icon"
                  src={props.iconInActive}
                  alt={props.name + "Icon"}
                  style={
                    props.iconWidth ? { width: `${props.iconWidth}px` } : {}
                  }
                />
                {props.fullSidebar && (
                  <p className="s-menu-text">{props.name}</p>
                )}
                {props.fullSidebar && (
                  <img src={dropdownIcon} alt="Dropdown Icon" />
                )}
              </div>
            </>
          )}
          {dropDownClicked && (
            <>
              <div className="s-menu-container">
                <img
                  className="s-menu-icon"
                  src={props.iconActive || props.iconInActive}
                  alt={props.name + "Icon"}
                  style={
                    props.iconWidth ? { width: `${props.iconWidth}px` } : {}
                  }
                />
                {props.fullSidebar && (
                  <p className="s-menu-text-active">{props.name}</p>
                )}
                {props.fullSidebar && (
                  <img src={collapseIcon} alt="Dropdown Icon" />
                )}
              </div>
            </>
          )}
        </div>
      )}

      {/* general dropdown menu */}
      {dropDownClicked &&
        props.fullSidebar &&
        props.dropdowns.length > 0 &&
        props.dropdowns.map((drp, idx) =>
          window.location.pathname === "" ? (
            <div
              id={props.name + drp.name}
              onClick={(e) => {
                navigate(
                  `/${props.name?.replace(" ", "-").toLowerCase()}/${drp.name
                    .replace(" ", "-")
                    .toLowerCase()}`
                );
                if (drp.onClickPopup) {
                  props.setFavouriteClicked(true);
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(drp.onClickPopup);
                  props.setShowPopup(true);
                }
              }}
              key={idx}
              className="s-menu-dropdown-container"
            >
              <div key={idx} className="s-menu-dropdown">
                <div key={idx} className="s-menu-dropdown-text-container">
                  <div key={idx} className="s-menu-dropdown-text-content">
                    <p key={idx} className="s-menu-dropdown-text">
                      {drp.name}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {window.location.pathname ===
                `/${props.name?.replace(" ", "-").toLowerCase()}/${drp.name
                  .replace(" ", "-")
                  .toLowerCase()}` && (
                <div
                  id={props.name + drp.name}
                  onClick={(e) => {
                    navigate(
                      `/${props.name
                        ?.replace(" ", "-")
                        .toLowerCase()}/${drp.name
                        .replace(" ", "-")
                        .toLowerCase()}`
                    );
                    if (drp.onClickPopup) {
                      props.setFavouriteClicked(true);
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(drp.onClickPopup);
                      props.setShowPopup(true);
                    }
                  }}
                  key={idx}
                  className="s-menu-dropdown-container-active"
                >
                  <div className="s-menu-dropdown-active">
                    <div className="s-menu-dropdown-text-container">
                      <div className="s-menu-dropdown-text-content">
                        <p className="s-menu-dropdown-text">{drp.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {window.location.pathname !==
                `/${props.name?.replace(" ", "-").toLowerCase()}/${drp.name
                  .replace(" ", "-")
                  .toLowerCase()}` && (
                <div
                  id={props.name + drp.name}
                  onClick={(e) => {
                    navigate(
                      `/${props.name
                        ?.replace(" ", "-")
                        .toLowerCase()}/${drp.name
                        .replace(" ", "-")
                        .toLowerCase()}`
                    );
                    if (drp.onClickPopup) {
                      props.setFavouriteClicked(true);
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(drp.onClickPopup);
                      props.setShowPopup(true);
                    }
                  }}
                  key={idx}
                  className="s-menu-dropdown-container"
                >
                  <div className="s-menu-dropdown">
                    <div className="s-menu-dropdown-text-container">
                      <div className="s-menu-dropdown-text-content">
                        <p className="s-menu-dropdown-text">{drp.name}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )
        )}
    </>
  );
};

export default SideBarMenu;
