import { useState } from "react";
import verificationImage from "../../assests/page/verify-kyc.svg";
import SumsubWebSdk from "@sumsub/websdk-react";
import { createAccessTokenForNewUser } from "../../utils/helpers";

const VerifyKYC = (props) => {
  const [showSumsubPortal, setShowSumsubPortal] = useState(false);
  const [kycCompleted, setKycCompleted] = useState(false);
  const [kycError, setKycError] = useState(false);

  const sumsubConfig = {
    lang: "en",
    i18n: {
      document: {
        subTitles: {
          IDENTITY: "Upload a document that proves your identity",
        },
      },
    },
  };

  return (
    <div className="verify-kyc-popup">
      {!showSumsubPortal && (
        <img
          alt="KYC Verification"
          src={verificationImage}
          className="verify-kyc-image"
        />
      )}

      {!showSumsubPortal && (
        <div className="verify-kyc-content">
          <p className="verify-kyc-header-text">KYC Incomplete</p>
          <p className="verify-kyc-footer-text">
            KYC not completed, Please complete your KYC to activate your wallet.
          </p>
        </div>
      )}

      {showSumsubPortal && (
        <>
          {kycCompleted && (
            <div className="verify-kyc-content">
              <p className="verify-kyc-header-text">KYC Completed</p>
              {/* <p className="verify-kyc-footer-text">
                KYC Portal will close in <span>15s</span>.
              </p> */}
            </div>
          )}
          {kycError && (
            <div className="verify-kyc-content">
              <p className="verify-kyc-header-text">Unexpected Error!</p>
              {/* <p className="verify-kyc-footer-text">
                KYC Portal will close in <span>15s</span>.
              </p> */}
            </div>
          )}
          <SumsubWebSdk
            accessToken={props.additionalProps.accessToken}
            expirationHandler={() =>
              Promise.resolve(
                createAccessTokenForNewUser(
                  props.additionalProps.userAddress,
                  props.additionalProps.chainId
                ).then((res) => res?.accessToken)
              )
            }
            config={sumsubConfig}
            options={{ addViewportTag: true, adaptIframeHeight: true }}
            onMessage={(msg, payload) => {
              if (
                msg === "idCheck.applicantStatus"
                // payload?.reviewResult?.reviewAnswer?.toLowerCase() === "red"
              ) {
                setKycCompleted(true);
              }
            }}
            onError={(data) => setKycError(true)}
          />
        </>
      )}

      <div style={{ marginBottom: "0" }} className="verify-kyc-buttons">
        {!showSumsubPortal && (
          <>
            <button
              onClick={() => {
                props.setShowPopup(false);
                props.setPopupContent(null);
                if (props.setPopupContentPosition) {
                  props.setPopupContentPosition("center");
                }
                if (props.setFavouriteClicked) {
                  props.setFavouriteClicked(false);
                }
              }}
              className="verify-kyc-button-back"
            >
              <p className="verify-kyc-button-back-text">Back</p>
            </button>
            <button
              onClick={() => {
                setShowSumsubPortal(true);
              }}
              className="verify-kyc-button"
            >
              <p className="verify-kyc-button-text">Complete KYC</p>
            </button>
          </>
        )}
        {showSumsubPortal && kycCompleted && !kycError && (
          <button
            style={{ marginBottom: "50px" }}
            onClick={() => {
              props.setShowPopup(false);
              props.setPopupContent(null);
              props.setPopupContentPosition("center");
              setShowSumsubPortal(false);
              setKycCompleted(false);
            }}
            className="verify-kyc-button"
          >
            <p className="verify-kyc-button-text">Close</p>
          </button>
        )}
        {showSumsubPortal && kycError && (
          <button
            style={{ marginBottom: "50px" }}
            onClick={() => {
              props.setShowPopup(false);
              props.setPopupContent(null);
              props.setPopupContentPosition("center");
              setShowSumsubPortal(false);
              setKycError(false);
            }}
            className="verify-kyc-button"
          >
            <p className="verify-kyc-button-text">Close</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default VerifyKYC;
