import dashboardIcon from "../../assests/sideBar/dashboard-icon.svg";
import dashboardActiveIcon from "../../assests/sideBar/dashboard-active-icon.svg";
import paymentsIcon from "../../assests/sideBar/payments-inactive.svg";
import paymentsActiveIcon from "../../assests/sideBar/payments-active-icon.svg";
import investmentsIcon from "../../assests/sideBar/investments-icon.svg";
import investmentsInactiveIcon from "../../assests/sideBar/investments-inactive-icon.svg";
import loansIcon from "../../assests/sideBar/loans-icon.svg";
import loansActiveIcon from "../../assests/sideBar/loans-active-icon.svg";
import toggleIcon from "../../assests/sideBar/toggle-icon.svg";
import toggleIcon2 from "../../assests/sideBar/toggle-icon-2.svg";
import SideBarMenu from "./menu";
import AddFunds from "../../components/popups/addFunds";
import ConvertFunds from "../../components/popups/convertFunds";
import WitdrawFunds from "../../components/popups/withdrawFunds";

const SiderBar = (props) => {
  return (
    <>
      {props.fullSidebar && (
        <aside className="side-bar">
          <SideBarMenu
            name="Dashboard"
            iconInActive={dashboardIcon}
            iconActive={dashboardActiveIcon}
            dropdowns={[]}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          />
          {/* <SideBarMenu
            name="Payments"
            iconInActive={paymentsIcon}
            iconActive={paymentsActiveIcon}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            dropdowns={[
              {
                name: "Add Funds",
                onClickPopup: (
                  <AddFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
              {
                name: "Send Funds",
                onClickPopup: (
                  <ConvertFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
              {
                name: "Convert Funds",
                onClickPopup: (
                  <ConvertFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
              {
                name: "Withdraw Funds",
                onClickPopup: (
                  <WitdrawFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
            ]}
            generalShowFullSideBar={true}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          /> */}
          <SideBarMenu
            name="Investments"
            iconInActive={investmentsInactiveIcon}
            iconActive={investmentsIcon}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            dropdowns={[
              { name: "Assets" },
              { name: "Portfolio" },
              { name: "Orders" },
              // { name: "Watchlists" },
            ]}
            generalShowFullSideBar={true}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          />
          {/* <SideBarMenu
            name="Loans"
            iconInActive={loansIcon}
            iconActive={loansActiveIcon}
            dropdowns={[]}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            generalShowFullSideBar={true}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          /> */}
          <div
            onClick={() => {
              props.setFullSidebar(false);
            }}
            className="menu-toggle"
          >
            <img
              alt="Menu Toggle Icon"
              src={toggleIcon}
              className="menu-toggle-icon"
            />
          </div>
        </aside>
      )}
      {!props.fullSidebar && (
        <aside className="side-bar">
          <SideBarMenu
            name="Dashboard"
            iconInActive={dashboardIcon}
            iconActive={dashboardActiveIcon}
            dropdowns={[]}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          />
          {/* <SideBarMenu
            name="Payments"
            iconInActive={paymentsIcon}
            iconActive={paymentsActiveIcon}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            dropdowns={[
              {
                name: "Add Funds",
                onClickPopup: (
                  <AddFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
              {
                name: "Send Funds",
              },
              {
                name: "Convert Funds",
                onClickPopup: (
                  <ConvertFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
              {
                name: "Withdraw Funds",
                onClickPopup: (
                  <WitdrawFunds
                    setPopupContent={props.setPopupContent}
                    setShowPopup={props.setShowPopup}
                    setPopupContentPosition={props.setPopupContentPosition}
                    setFavouriteClicked={props.setFavouriteClicked}
                  />
                ),
              },
            ]}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          /> */}
          <SideBarMenu
            name="Investments"
            iconInActive={investmentsInactiveIcon}
            iconActive={investmentsIcon}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            dropdowns={[
              { name: "Assets" },
              { name: "Portfolio" },
              { name: "Orders" },
              { name: "Watchlists" },
            ]}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          />
          {/* <SideBarMenu
            name="Loans"
            iconInActive={loansIcon}
            iconActive={loansActiveIcon}
            fullSidebar={props.fullSidebar}
            setFullSidebar={props.setFullSidebar}
            dropdowns={[]}
            setPopupContent={props.setPopupContent}
            setShowPopup={props.setShowPopup}
            setPopupContentPosition={props.setPopupContentPosition}
            setFavouriteClicked={props.setFavouriteClicked}
          /> */}
          <div
            onClick={() => {
              props.setFullSidebar(true);
            }}
            className="menu-toggle"
          >
            <img
              alt="Menu Toggle Icon"
              src={toggleIcon2}
              className="menu-toggle-icon"
            />
          </div>
        </aside>
      )}
    </>
  );
};

export default SiderBar;
