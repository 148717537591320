import { useEffect, useState } from "react";
import loadingIcon from "../../assests/page/payment-loading-icon.svg";
import { debounce } from "lodash";

const PaymentProcess = (props) => {
  const [showReceipt, setShowReceipt] = useState(false);
  setTimeout(() => {
    props.setPopupContentPosition("flex-end");

    if (props.nextPopup) {
      props.setPopupContent(props.nextPopup);
      if (props.setFavouriteClicked) {
        props.setFavouriteClicked(true);
      }

      props.setShowPopup(true);
    } else {
      props.setShowPopup(false);
      props.setPopupContentPosition("center");
      props.setPopupContent(null);
      if (props.setFavouriteClicked) {
        props.setFavouriteClicked(false);
      }
    }
  }, 5000);

  return (
    <div className="add-funds-popup">
      {showReceipt && (
        <div className="add-funds-content">
          <div className="add-funds-content-header"></div>
          <div className="payments-popup-content-footer"></div>
        </div>
      )}
      {!showReceipt && (
        <div className="paymment-processing-popup-content">
          <div className="paymment-processing-popup-content-container">
            <img src={loadingIcon} alt="Loading Icon" />
            <div className="paymment-processing-popup-content-header">
              <p className="paymment-processing-popup-content-header-text">
                Processing Payment...
              </p>
              <p className="paymment-processing-popup-content-footer-text">
                This won’t take more than{" "}
                <span className="paymment-processing-popup-content-footer-text-footer">
                  15s
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentProcess;
