export const supportedChainsIds =
  process.env.REACT_APP_NODE_ENV === "production" ? [1, 8453] : [11155111, 100];

export const ChainConfig = {
  1: {
    chainId: `0x${Number(1).toString(16)}`,
    chainName: "Ethereum",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      `https://solemn-summer-patina.quiknode.pro/${process.env.REACT_APP_QUIKNODE_KEY}/`,
    ],
    blockExplorerUrls: ["https://etherscan.io/"],
  },

  8453: {
    chainId: `0x${Number(8453).toString(16)}`,
    chainName: "Base Mainnet",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: [
      `https://red-quaint-pallet.base-mainnet.quiknode.pro/${process.env.REACT_APP_QUICKNODE_KEY}/`,
    ],
    blockExplorerUrls: ["https://basescan.org/"],
  },

  100: {
    chainId: `0x${Number(100).toString(16)}`,
    chainName: "Gnosis",
    nativeCurrency: {
      name: "XDAI",
      symbol: "XDAI",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.gnosischain.com/"],
    blockExplorerUrls: ["https://gnosisscan.io/"],
  },

  11155111: {
    chainId: `0x${Number(11155111).toString(16)}`,
    chainName: "Sepolia Test Network",
    nativeCurrency: {
      name: "Sepolia ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.sepolia.org"],
    blockExplorerUrls: ["https://sepolia.etherscan.io/"],
  },
};

export const chainDetails = {
  //default chain when users are not connected to fetch and display data
  //TODO: change default chain subgraph urls from sepolia to mainnet urls during production.
  0:
    process.env.REACT_APP_NODE_ENV === "production"
      ? {
          chainId: 1,
          name: "Ethereum",
          vaultSubgraphUrl:
            "https://api.studio.thegraph.com/query/77016/vault-mainnet/version/latest",
          walletSubgraphUrl: `https://api.studio.thegraph.com/query/77016/wallet-mainnet/version/latest`,
          rpcUrls: [
            `https://solemn-summer-patina.quiknode.pro/${process.env.REACT_APP_QUIKNODE_KEY}`,
          ],
        }
      : {
          chainId: 11155111,
          name: "Sepolia Test Network",
          vaultSubgraphUrl: `https://gateway.thegraph.com/api/${process.env.REACT_APP_SUBGRAPH_KEY}/subgraphs/id/BZYwDU6CtLq1GBwCaGUZBU4KcQk9CAtPDkEY4LAKfoJN`,
          walletSubgraphUrl: `https://gateway.thegraph.com/api/${process.env.REACT_APP_SUBGRAPH_KEY}/subgraphs/id/6Qxzqb6J12vxKqgaGmCopuQH6bzGhAzYz45bRzPs2EiG`,
          rpcUrls: ["https://rpc.sepolia.org"],
        },

  1: {
    chainId: 1,
    name: "Ethereum",
    vaultSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/vault-mainnet/version/latest",
    walletSubgraphUrl: `https://api.studio.thegraph.com/query/77016/wallet-mainnet/version/latest`,
  },
  100: {
    chainId: 100,
    name: "Gnosis",
    vaultSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/vault-gnosis/version/latest",
    walletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/wallet-gnosis/version/latest",
  },
  8453: {
    chainId: 8453,
    name: "Base Mainnet",
    vaultSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/vault-base/version/latest",
    walletSubgraphUrl:
      "https://api.studio.thegraph.com/query/77016/wallet-base/version/latest",
  },
  11155111: {
    chainId: 11155111,
    name: "Sepolia Test Network",
    vaultSubgraphUrl: `https://gateway.thegraph.com/api/${process.env.REACT_APP_SUBGRAPH_KEY}/subgraphs/id/BZYwDU6CtLq1GBwCaGUZBU4KcQk9CAtPDkEY4LAKfoJN`,
    walletSubgraphUrl: `https://gateway.thegraph.com/api/${process.env.REACT_APP_SUBGRAPH_KEY}/subgraphs/id/6Qxzqb6J12vxKqgaGmCopuQH6bzGhAzYz45bRzPs2EiG`,
    lidoAddress: "0x3e3FE7dBc6B4C189E7128855dD526361c49b40Af",
    verifiedLidoRewardAddress: "0x19836182a3786CD592523cAB7445325be26c3334",
  },
};
