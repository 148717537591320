export const PrivateStockLabel = (props) => {
  return (
    <div className="gp-third-content-bottom-third-section-private">
      {props.customName && (
        <p className="gp-third-content-bottom-third-section-text">
          Private {props.customName}
        </p>
      )}
      {!props.customName && props.customText && (
        <p className="gp-third-content-bottom-third-section-text">
          {props.customText}
        </p>
      )}
      {!props.customName && !props.customText && (
        <p className="gp-third-content-bottom-third-section-text">
          Private Stocks
        </p>
      )}
    </div>
  );
};

export const VCFundsLabel = (props) => {
  return (
    <div className="gp-third-content-bottom-third-section-custom">
      {props.customText && (
        <p className="gp-third-content-bottom-third-section-text">
          {props.customText}
        </p>
      )}
      {!props.customText && (
        <p className="gp-third-content-bottom-third-section-text">Funds</p>
      )}
    </div>
  );
};
