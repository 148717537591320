import { memo } from "react";
import GeneralOverlay from "../components/overlays/general";
import PageSeperator from "../components/overlays/pageSeperator";

const PageSetup = memo((props) => {
  return (
    <div style={{ overflowY: "auto" }} className="general-page">
      <div className="general-page-content">
        {props.showPageOverlay && (
          <div className="gp-first-section">
            <GeneralOverlay
              generalOverlayBackground={props.generalOverlayBackground}
            >
              {props.children[0]}
            </GeneralOverlay>
            <div className="gp-first-section-body">{props.children[1]}</div>
          </div>
        )}
        {props.showPageSeparator && (
          <PageSeperator
            separatorText={props.separatorText}
            separatorUrl={props.separatorUrl}
            backUrl={props.backUrl}
          />
        )}
        <div
          id="general-page-main-content"
          className="general-page-main-content"
        >
          {props.children[2]}
        </div>
        <div className="general-page-third-content">{props.children[3]}</div>
        <div className="general-page-fourth-content">{props.children[4]}</div>
        <div className="general-page-fourth-content">{props.children[5]}</div>
      </div>
      {/* <div className="general-page-footer">{props.children[5]}</div> */}
    </div>
  );
});

export default PageSetup;
