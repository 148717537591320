import { contractAddress, Liquidity } from "@verified-network/verified-sdk";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ERC20ABI from "../utils/abis/ERC20.json";
import { currenciesToSymbol } from "../utils/constants";
import { ChainConfig } from "../utils/config";

export const useGetSupportedCurrencies = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { chainId, provider, web3 } = useSelector((state) => state.network);
  const { userAddress } = useSelector((state) => state.user);

  useEffect(() => {
    const fetchAndFormatSupportedCurrencies = async () => {
      setLoading(true);
      setError(null);
      const liquidityContract = new Liquidity(
        provider.getSigner(userAddress),
        contractAddress[chainId].Liquidity
      );
      if (liquidityContract) {
        try {
          const response = await liquidityContract.getSupportedTokens();
          const json = response?.response?.result;
          if (json) {
            const jsonResult = await Promise.all(
              json.map(async (token) => {
                const tokenName = token.tokenName.replace(/\0/g, "");
                const isFiat =
                  tokenName.substring(0, 2) === "VC" ? true : false;
                let currencyBalance, currencyName, tokenDecimals, tokenBalance;
                if (
                  token?.tokenAddress?.toLowerCase() !==
                  ethers.constants.AddressZero.toLowerCase()
                ) {
                  const currencyContract = new web3.eth.Contract(
                    ERC20ABI,
                    token.tokenAddress
                  );
                  const currencyNameRaw = await currencyContract.methods
                    .name()
                    .call();
                  currencyName = currencyNameRaw.replace(/\0/g, "");
                  const currencyDecimals = await currencyContract.methods
                    .decimals()
                    .call();
                  tokenDecimals = Number(currencyDecimals);
                  const currencyBalanceRaw = await currencyContract.methods
                    .balanceOf(userAddress)
                    .call();
                  tokenBalance = Number(currencyBalanceRaw);
                  currencyBalance = ethers.utils.formatUnits(
                    currencyBalanceRaw.toString(),
                    currencyDecimals
                  );
                } else {
                  const balance = await web3.eth.getBalance(userAddress);
                  tokenBalance = Number(balance);
                  currencyBalance = web3.utils.fromWei(balance, "ether");
                  currencyName = tokenName;
                  tokenDecimals = Number(
                    ChainConfig[chainId]?.nativeCurrency?.decimals
                  );
                }
                return {
                  id: token.tokenAddress,
                  tokenAddress: token.tokenAddress,
                  tokenDecimals,
                  tokenName,
                  isCryptoFiat: isFiat,
                  balance: currencyBalance,
                  tokenBalance,
                  isCrypto: true,
                  currencySymbol: tokenName,
                  officialCurrencySymbol: isFiat
                    ? currenciesToSymbol[tokenName.replace("VC", "")]
                    : token?.tokenAddress?.toLowerCase() ===
                      ethers.constants.AddressZero.toLowerCase()
                    ? currenciesToSymbol[tokenName.toLowerCase()]
                    : "$",
                  currencyName,
                };
              })
            );
            setData(jsonResult);
          }
        } catch (err) {
          console.error("Error while fetching all pools: ", err);
          setError(
            err.message || "Error while fetching all pools. Try again later."
          );
        } finally {
          setLoading(false);
        }
      }
    };

    if (chainId && provider && web3 && userAddress) {
      fetchAndFormatSupportedCurrencies();
    }
  }, [chainId, provider, web3, userAddress]);

  return {
    getSupportedCurrenciesData: data,
    getSupportedCurrenciesLoading: loading,
    getSupportedCurrenciesError: error,
  };
};
