const StartupSlider = (props) => {
  return (
    <div className="startup-slider">
      {props.sliderCount?.length > 0 &&
        props.sliderCount.map((_, idx) => (
          <>
            {idx <= Number(props.currentSlider) && (
              <div
                key={idx}
                style={{ width: `${100 / props.sliderCount.length}%` }}
                className="startup-slider-line-active"
              ></div>
            )}
            {idx > Number(props.currentSlider) && (
              <div
                key={idx}
                style={{ width: `${100 / props.sliderCount.length}%` }}
                className="startup-slider-line"
              ></div>
            )}
          </>
        ))}
    </div>
  );
};

export default StartupSlider;
