import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import stripeIcon from "../../assests/page/Stripe_icon-square.svg";
import creditCardIcon from "../../assests/page/credit-card-icon.svg";
import debitCardIcon from "../../assests/page/debit-card.svg";
import sepaCardIcon from "../../assests/page/sepa-card.svg";
import gpayIcon from "../../assests/page/gpay-icon.svg";
import AddFunds from "./addFunds";
import { useState } from "react";
import PaymentProcess from "./paymentProcess";
import { StripePaymentForm } from "./stripePayment";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const PaymentMethods = (props) => {
  const [showPaymentPortal, setShowPaymentPortal] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const [paymentMethodClicked, setPaymentMethodClicked] = useState(false);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

  const options = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <div className="add-funds-popup">
      <div className="add-funds-content">
        <div className="add-funds-content-header">
          <div className="add-funds-content-header-text-container">
            {!showPaymentPortal && (
              <img
                onClick={() => {
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <AddFunds
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                    />
                  );
                }}
                className="add-funds-popup-back-icon"
                alt="Back Icon"
                src={backIcon}
              />
            )}
            {showPaymentPortal && (
              <img
                onClick={() => {
                  setShowPaymentPortal(false);
                  setPaymentType("");
                  setPaymentMethodClicked(false);
                }}
                className="add-funds-popup-back-icon"
                alt="Back Icon"
                src={backIcon}
              />
            )}
            {!showPaymentPortal && (
              <p className="add-funds-content-header-text">
                Select Payment Method
              </p>
            )}
            {showPaymentPortal && paymentType === "stripe" && (
              <p className="add-funds-content-header-text">Pay with Stripe</p>
            )}
            {showPaymentPortal && paymentType === "debit-credit-card" && (
              <p className="add-funds-content-header-text">
                Pay with Debit/Credit card
              </p>
            )}
          </div>
          <img
            onClick={() => {
              props.setShowPopup(false);
              props.setPopupContent(null);
              if (props.setPopupContentPosition) {
                props.setPopupContentPosition("center");
              }
              if (props.setFavouriteClicked) {
                props.setFavouriteClicked(false);
              }
            }}
            className="add-funds-popup-close-icon"
            alt="Close Icon"
            src={popupCloseIcon}
          />
        </div>
        <div className="payments-popup-content-footer">
          {!showPaymentPortal && (
            <div className="payments-popup-content-footer-container">
              <div
                onClick={() => {
                  setPaymentType("stripe");
                  setShowPaymentPortal(true);
                }}
                className="payments-popup-content-footer-main"
              >
                <div className="payments-popup-content">
                  <div className="payments-popup-content-icon">
                    <img src={stripeIcon} alt="Payment Method Icon" />
                  </div>
                  <p className="payments-popup-content-text">Pay with Stripe</p>
                </div>
              </div>

              <div
                onClick={() => {
                  setPaymentType("debit-credit-card");
                  setShowPaymentPortal(true);
                }}
                className="payments-popup-content-footer-main"
              >
                <div className="payments-popup-content">
                  <div className="payments-popup-content-icon">
                    <img src={creditCardIcon} alt="Payment Method Icon" />
                  </div>
                  <p className="payments-popup-content-text">
                    Debit/Credit Cards
                  </p>
                </div>
              </div>
            </div>
          )}
          {showPaymentPortal && paymentType === "debit-credit-card" && (
            <>
              <div className="p-p-payment-portal-footer">
                <div className="p-p-payment-portal-amount-info">
                  <div className="p-p-payment-portal-amount-info-header">
                    <div className="p-p-payment-portal-header-text-container">
                      <p className="p-p-payment-portal-header-text">
                        Adding funds to your Wallet
                      </p>
                      <p className="p-p-payment-portal-header-text-footer">
                        {props.selectedCurrency?.symbol}{" "}
                        {props.selectedCurrency?.amount}
                      </p>
                    </div>
                    {/* <button className="p-p-payment-portal-header-button">
                    <p className="p-p-payment-portal-header-button-text">
                      Apple Pay
                    </p>
                  </button> */}
                  </div>
                  <div className="p-p-payment-portal-amount-info-separator">
                    <div className="p-p-payment-portal-line"></div>
                    <p className="p-payment-portal-separator-text">
                      OR Pay With
                    </p>
                    <div className="p-p-payment-portal-line"></div>
                  </div>
                  <div className="p-p-payment-portal-amount-info-footer">
                    <div
                      id="p-p-payment-portal-debit-card"
                      onClick={(e) => {
                        setPaymentMethodClicked(true);
                        const previouslyActiveTag =
                          document.getElementsByClassName(
                            "p-p-payment-portal-amount-footer-main-active"
                          );
                        if (previouslyActiveTag.length > 0) {
                          previouslyActiveTag[0].className =
                            "p-p-payment-portal-amount-footer-main";
                        }
                        const currentTag = document.getElementById(
                          "p-p-payment-portal-debit-card"
                        );
                        currentTag.className =
                          "p-p-payment-portal-amount-footer-main-active";
                      }}
                      className="p-p-payment-portal-amount-footer-main"
                    >
                      <img src={debitCardIcon} alt="Other Payments Icon" />
                      <p className="p-p-payment-portal-amount-footer-text">
                        Debit Card
                      </p>
                    </div>
                    <div
                      id="p-p-payment-portal-credit-card"
                      onClick={(e) => {
                        setPaymentMethodClicked(true);
                        const previouslyActiveTag =
                          document.getElementsByClassName(
                            "p-p-payment-portal-amount-footer-main-active"
                          );
                        if (previouslyActiveTag.length > 0) {
                          previouslyActiveTag[0].className =
                            "p-p-payment-portal-amount-footer-main";
                        }
                        const currentTag = document.getElementById(
                          "p-p-payment-portal-credit-card"
                        );
                        currentTag.className =
                          "p-p-payment-portal-amount-footer-main-active";
                      }}
                      className="p-p-payment-portal-amount-footer-main"
                    >
                      <img src={debitCardIcon} alt="Other Payments Icon" />
                      <p className="p-p-payment-portal-amount-footer-text">
                        Credit Card
                      </p>
                    </div>
                    <div
                      id="p-p-payment-portal-sepa-card"
                      onClick={(e) => {
                        setPaymentMethodClicked(true);
                        const previouslyActiveTag =
                          document.getElementsByClassName(
                            "p-p-payment-portal-amount-footer-main-active"
                          );
                        if (previouslyActiveTag.length > 0) {
                          previouslyActiveTag[0].className =
                            "p-p-payment-portal-amount-footer-main";
                        }
                        const currentTag = document.getElementById(
                          "p-p-payment-portal-sepa-card"
                        );
                        currentTag.className =
                          "p-p-payment-portal-amount-footer-main-active";
                      }}
                      className="p-p-payment-portal-amount-footer-main"
                    >
                      <img src={sepaCardIcon} alt="Other Payments Icon" />
                      <p className="p-p-payment-portal-amount-footer-text">
                        SEPA Card
                      </p>
                    </div>
                    <div
                      id="p-p-payment-portal-gpay"
                      onClick={(e) => {
                        setPaymentMethodClicked(true);
                        const previouslyActiveTag =
                          document.getElementsByClassName(
                            "p-p-payment-portal-amount-footer-main-active"
                          );
                        if (previouslyActiveTag.length > 0) {
                          previouslyActiveTag[0].className =
                            "p-p-payment-portal-amount-footer-main";
                        }
                        const currentTag = document.getElementById(
                          "p-p-payment-portal-gpay"
                        );
                        currentTag.className =
                          "p-p-payment-portal-amount-footer-main-active";
                      }}
                      className="p-p-payment-portal-amount-footer-main"
                    >
                      <img src={gpayIcon} alt="Other Payments Icon" />
                      <p className="p-p-payment-portal-amount-footer-text">
                        G Pay
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-p-payment-portal-contact-info">
                  <div className="p-p-payment-portal-contact-info-container">
                    <div className="p-p-payment-portal-email-info">
                      <p className="p-p-payment-portal-email-text">Email</p>
                      <input
                        type="email"
                        name="card-holder-email"
                        id="input-card-holder-email"
                        placeholder="Add Email ID"
                        className="p-p-payment-portal-input"
                      />
                    </div>
                    <div className="p-p-payment-portal-card-info">
                      <input
                        type="text"
                        id="input-card-number"
                        maxLength={16}
                        placeholder="1234 1234 1234 1234"
                        className="p-p-payment-portal-card-info-input"
                      />
                      <div className="p-p-payment-portal-card-info-container">
                        <input
                          type="text"
                          maxLength={4}
                          id="input-card-date"
                          placeholder="MM / YY"
                          className="p-p-payment-portal-card-info-input-1"
                        />
                        <input
                          type="text"
                          maxLength={3}
                          id="input-card-cvv"
                          placeholder="CVV"
                          className="p-p-payment-portal-card-info-input-2"
                        />
                      </div>
                    </div>
                    <div className="p-p-payment-portal-card-holder-info">
                      <p className="p-p-payment-portal-email-text">
                        Cardholder name
                      </p>
                      <input
                        type="text"
                        id="input-card-holder-name"
                        placeholder="Add Full Name"
                        className="p-p-payment-portal-input"
                      />
                    </div>
                    {!paymentMethodClicked && (
                      <div className="p-p-payment-portal-card-holder-info">
                        <p className="p-p-payment-portal-email-text">
                          Country or Region
                        </p>
                        <input
                          type="text"
                          id="input-card-holder-region"
                          placeholder="Add Country/Region"
                          className="p-p-payment-portal-input"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <button
                onClick={() => {
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <PaymentProcess
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                    />
                  );
                }}
                style={{ marginBottom: "50px" }}
                className="paymment-popup-content-button"
              >
                <p className="payment-popup-content-button-text">Pay</p>
              </button>
            </>
          )}
          {showPaymentPortal && paymentType === "stripe" && (
            <Elements stripe={stripePromise} options={options}>
              <StripePaymentForm />
            </Elements>
          )}
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
