import { ethers, providers } from "ethers";
import Web3 from "web3";
import { chainDetails } from "../../utils/config";

/**
 * Gets provider's network from wagmi client chain
 * @param {Object} chain
 * @returns {Object} connected network details
 */
export const getProviderNetwork = (chain) => {
  return {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  };
};

/**
 * Gets provider from wagmi client transport and network
 * @param {Object} transport
 * @param {Object} network
 * @returns {JsonRpcProvider} provider that can be used with Verified Sdk/ethers
 */
export const getProvider = (transport, network) => {
  return new providers.Web3Provider(transport, network);
};

/**
 * Initialize and return Web3 from wagmi transport
 * @param {Object} transport
 * @returns {Web3} Web3 class
 */
export const getWeb3 = (transport) => {
  if (transport.type === "fallback") {
    return new Web3(transport.transports[0].value?.url);
  }
  return new Web3(transport);
};

/**
 * Gets address's ETH balance in ethers.
 * @param {Web3} web3
 * @param {string} address
 * @returns {Promise<string>} address ETH/Native token balance in ether(18 decimals)
 */
export const getAddressETHBalance = async (web3, address) => {
  const balanceRaw = await web3.eth.getBalance(address).catch((err) => {
    console.error("Error while getting address ETH balance: ", err);
    return 0n;
  });
  return ethers.utils.formatEther(balanceRaw);
};

/**
 *
 * @returns web3 using rpc of default chain to make read only calls on default chain
 */
export const getDefaultChainWeb3 = () => {
  return new Web3(chainDetails[0].rpcUrls[0]);
};
