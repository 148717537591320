import { Client, contractAddress } from "@verified-network/verified-sdk";
import CLIENTABI from "../../utils/abis/Client.json";
import { ethers } from "ethers";
import { fetchUsersByAddresses } from "../subraphs/walletSubraph";

/**
 * Note: This is not recommended because contract abi may not be up to date
 * @param {number} chainId
 * @param {Web3} web3
 * @returns web3's version of client contract to make any function call that is not available on Verified Sdk e.t.c
 */
export const getClientContractWeb3 = (chainId, web3) => {
  const clientAddress = contractAddress[chainId].Client;
  if (clientAddress) {
    return new web3.eth.Contract(CLIENTABI, clientAddress);
  } else {
    console.error("Client contract does not exist for chainId: ", chainId);
    return null;
  }
};

/**
 * Note: This is recommended because contract abi is always up to date with sdk version.
 * @param {number} chainId
 * @param {JsonRpcSigner} signer
 * @returns Verified Sdk version of client contract
 */
export const getClientContract = (chainId, signer) => {
  const clientAddress = contractAddress[chainId].Client;
  if (clientAddress) {
    return new Client(signer, clientAddress);
  } else {
    console.error("Client contract does not exist for chainId: ", chainId);
    return null;
  }
};

/**
 * Note: users with empty string role("") are investors
 * @param {number} chainId
 * @param {string} address
 * @param {JsonRpcSigner} signer
 * @returns {Promise<string> | undefined} user/address role
 */
export const getUserRole = async (chainId, address, signer) => {
  const clientContract = getClientContract(chainId, signer);
  if (clientContract) {
    return await clientContract.getRole(address).then((res) => {
      return ethers.utils.parseBytes32String(res?.response?.result[0]);
    });
  }
};

/**
 * gets user KYC details
 * @param {number} chainId
 * @param {string} address
 * @param {JsonRpcSigner} signer
 * @returns
 */
export const getUserKYCDetails = async (chainId, address, signer) => {
  const clientContract = getClientContract(chainId, signer);
  if (clientContract) {
    const clientKycResult = await clientContract
      .getClientKYC(address)
      .then((res) => {
        return res?.response?.result;
      });
    const fullClientKycResult = await clientContract
      .getFullClientKYC(address)
      .then((res) => {
        return res?.response?.result;
      });

    return {
      userFullName: ethers.utils.parseBytes32String(clientKycResult[0]),
      userFirstName: ethers.utils
        .parseBytes32String(clientKycResult[0])
        .split(" ")[0],
      userKycStatus: Number(clientKycResult[3]),
      userCountry: ethers.utils.parseBytes32String(clientKycResult[2]),
      userEmail: ethers.utils.parseBytes32String(clientKycResult[4]),
      userType: String(fullClientKycResult[1]),
    };
  }
};

/**
 * gets managers for the role passed in a particular country
 * @param {number} chainId
 * @param {JsonRpcSigner} signer
 * @param {string} country
 * @param {string} role
 * @returns {Promise<any []>}
 */
export const getRoleManagersDetails = async (
  chainId,
  signer,
  country,
  role
) => {
  const clientContract = getClientContract(chainId, signer);
  if (clientContract) {
    const roleManagers = await clientContract
      .getManagers(country, role)
      .then((res) => {
        if (res?.response?.result) {
          return res?.response?.result.map((add) => add?.toLowerCase());
        }
      });
    if (roleManagers) {
      const managersDetails = await fetchUsersByAddresses(
        chainId,
        roleManagers
      ).then((res) => res);
      if (managersDetails.length) {
        return managersDetails.map((manager) => {
          return {
            address: manager.id,
            name: ethers.utils.parseBytes32String(manager.name),
          };
        });
      }
    }
    return [];
  }
  return [];
};
