import { useNavigate } from "react-router-dom";
import addIcon from "../../assests/page/add-icon.svg";
import rightIcon from "../../assests/page/chevron-right-icon.svg";

const PageSeperator = (props) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        if (props.separatorUrl) {
          navigate(props.separatorUrl);
        }
      }}
      style={props.separatorWidth ? { width: props.separatorWidth } : {}}
      className="gp-separator"
    >
      <div className="gp-separator-text-container">
        <img className="gp-separator-icon" alt="Add Icon" src={addIcon} />
        <p className="gp-separator-text">{props.separatorText}</p>
      </div>
      <img className="gp-dropdown-icon" alt="Dropdown Icon" src={rightIcon} />
    </div>
  );
};

export default PageSeperator;
