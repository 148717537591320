import { useState } from "react";
import successIcon from "../../assests/page/succes-icon.svg";
import AssetInfoPage from "./assetInfo";

const SuccessPopup = (props) => {
  setTimeout(() => {
    props.setPopupContentPosition("flex-end");

    if (props.nextPopup) {
      props.setPopupContent(props.nextPopup);
      if (props.setFavouriteClicked) {
        props.setFavouriteClicked(true);
      }

      props.setShowPopup(true);
    } else {
      props.setShowPopup(false);
      props.setPopupContentPosition("center");
      props.setPopupContent(null);
      if (props.setFavouriteClicked) {
        props.setFavouriteClicked(false);
      }
    }
  }, 5000);

  return (
    <>
      {!props.isCustom && (
        <div className="success-popup-content-container">
          <div className="success-popup-content">
            <img src={successIcon} alt="Success Icon" />
            <div className="success-popup-content-text">
              <p className="success-popup-content-text-header">
                Successfully saved
              </p>
              <p className="success-popup-content-text-footer">
                {props.productClicked.name} has been added to “
                {props.whitelistName}”
              </p>
            </div>
          </div>
        </div>
      )}
      {props.isCustom && (
        <div className="success-popup-content-container">
          <div className="success-popup-content">
            <img src={successIcon} alt="Success Icon" />
            <div className="success-popup-content-text">
              <p className="success-popup-content-text-header">
                {props.customTextHeader}
              </p>
              <p className="success-popup-content-text-footer">
                {props.customTextFooter}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SuccessPopup;
