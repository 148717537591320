import { useNavigate } from "react-router-dom";

const BorrowWarningPopup = (props) => {
  const navigate = useNavigate();

  return (
    <div className="borrow-warning-popup">
      <div className="borrow-warning-popup-container">
        <p className="borrow-warning-popup-header-text">
          You do not have any investments to put up as collateral for a loan.
        </p>
        <p className="borrow-warning-popup-footer-text">
          On our platform we are only able to provide loans against issued bonds
          in form of collaterals.{" "}
          <span style={{ color: "#4E2BD7", cursor: "pointer" }}>Know More</span>
        </p>
      </div>
      <button
        onClick={() => {
          props.setShowCustomPopup(false);
          props.setShowPopup(false);
          props.setPopupContent(null);
          if (props.setFavouriteClicked) {
            props.setFavouriteClicked(false);
          }
          props.setPopupContentPosition("center");
          navigate("/dashboard");
        }}
        id="add-funds-popup-button"
        className="startup-button-active"
      >
        <p className="add-funds-popup-a-f-button-text">Back to Dashboard</p>
      </button>
    </div>
  );
};

export default BorrowWarningPopup;
