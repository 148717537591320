import MobileLayout from "../mobile";
import verificationImage from "../../assests/page/verify-kyc.svg";
import { useState } from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import { createAccessTokenForNewUser } from "../../utils/helpers";
import { useSelector } from "react-redux";

const MobileKYCPage = (props) => {
  const [showSumsubPortal, setShowSumsubPortal] = useState(false);
  const [kycCompleted, setKycCompleted] = useState(false);
  const [kycError, setKycError] = useState(false);

  const { userAddress } = useSelector((state) => state.user);
  const { chainId } = useSelector((state) => state.network);

  const { accessToken } = props.generalDataProps;

  const sumsubConfig = {
    lang: "en",
    i18n: {
      document: {
        subTitles: {
          IDENTITY: "Upload a document that proves your identity",
        },
      },
    },
  };
  return (
    <MobileLayout>
      <div className="gp-mobile-kyc">
        <div className="gp-mobile-kyc-container">
          {!showSumsubPortal && (
            <>
              <img
                alt="KYC Verification"
                src={verificationImage}
                className="verify-kyc-image"
              />
              <div className="gp-mobile-kyc-content">
                <p className="gp-mobile-kyc-content-title">KYC Incomplete</p>
                <p className="gp-mobile-kyc-content-label">
                  KYC not completed, Please complete your KYC to activate your
                  wallet.
                </p>
              </div>
            </>
          )}

          {showSumsubPortal && (
            <>
              {kycCompleted && (
                <div className="gp-mobile-kyc-content">
                  <p className="gp-mobile-kyc-content-title">KYC Completed</p>
                </div>
              )}
              {kycError && (
                <div className="gp-mobile-kyc-content">
                  <p className="gp-mobile-kyc-content-title">
                    Unexpected Error!
                  </p>
                </div>
              )}
              <SumsubWebSdk
                accessToken={accessToken}
                expirationHandler={() =>
                  Promise.resolve(
                    createAccessTokenForNewUser(userAddress, chainId).then(
                      (res) => res?.accessToken
                    )
                  )
                }
                config={sumsubConfig}
                options={{ addViewportTag: true, adaptIframeHeight: true }}
                onMessage={(msg, payload) => {
                  if (
                    msg === "idCheck.applicantStatus"
                    // payload?.reviewResult?.reviewAnswer?.toLowerCase() === "red"
                  ) {
                    setKycCompleted(true);
                  }
                }}
                onError={(data) => setKycError(true)}
              />
            </>
          )}
        </div>

        {!showSumsubPortal && (
          <button
            style={{ marginBottom: "85px" }}
            onClick={(e) => {
              if (
                e.target.className.includes("startup-button") &&
                e.target.className !== "startup-button"
              ) {
                e.target.className = "startup-button-click";
                setShowSumsubPortal(true);
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                  setShowSumsubPortal(true);
                }
              }
            }}
            className="startup-button-active"
          >
            <p className="st-button-text">Complete KYC</p>
          </button>
        )}
        {showSumsubPortal && kycError && (
          <button
            style={{ marginBottom: "85px" }}
            onClick={(e) => {
              if (
                e.target.className.includes("startup-button") &&
                e.target.className !== "startup-button"
              ) {
                e.target.className = "startup-button-click";
                setShowSumsubPortal(false);
                setKycError(false);
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                  setShowSumsubPortal(false);
                  setKycError(false);
                }
              }
            }}
            className="startup-button-active"
          >
            <p className="st-button-text">Close</p>
          </button>
        )}
        {showSumsubPortal && !kycError && kycCompleted && (
          <button
            style={{ marginBottom: "85px" }}
            onClick={(e) => {
              if (
                e.target.className.includes("startup-button") &&
                e.target.className !== "startup-button"
              ) {
                e.target.className = "startup-button-click";
                setShowSumsubPortal(false);
                setKycCompleted(false);
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                  setShowSumsubPortal(false);
                  setKycCompleted(false);
                }
              }
            }}
            className="startup-button-active"
          >
            <p className="st-button-text">Close</p>
          </button>
        )}
      </div>
    </MobileLayout>
  );
};

export default MobileKYCPage;
