import axios from "axios";
import { toast } from "react-toastify";

export const pinFileToIpfs = async (fileInFormData, setUploadPercentage) => {
  try {
    setUploadPercentage(0); //reset %
    return await axios({
      method: "POST",
      url: "https://api.pinata.cloud/pinning/pinFileToIPFS",
      headers: {
        "Content-Type": "multipart/form-data",
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${process.env.REACT_APP_PINATA_API_JWT}`,
      },
      data: fileInFormData,
      onUploadProgress: (e) => {
        const uploadPerc = Math.round((e.loaded / e.total) * 100);
        if (uploadPerc !== 100) {
          setUploadPercentage(uploadPerc.toFixed(2));
        }
      },
    }).then((res) => {
      //update % to 100 only when file is pinned
      setUploadPercentage(100);
      //   toast.success("fille uplloaded to ipfs successfully");
      return res.data.IpfsHash;
    });
  } catch (err) {
    toast.error("Error while uploading file to ipfs");
    console.error("Error while uploading file to ipfs: ");
    return;
  }
};

export const getIpfsUrlFromHash = (ipfsHash) => {
  return `${process.env.REACT_APP_PINATA_IPFS_GATEWAY}/${ipfsHash}`;
};

export const readIpfsDocumentFromHash = async (ipfsHash) => {
  try {
    return await axios({
      method: "GET",
      url: getIpfsUrlFromHash(ipfsHash),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((res) => {
      return res.data;
    });
  } catch (err) {
    // toast.error("Error while reading ipfs file");
    console.error("Error while reading ipfs file");
    return {};
  }
};
