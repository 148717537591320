import privacyIcon from "../../assests/page/privacy_tip.svg";

export const TermAndConditionOverlay = () => {
  return (
    <p className="terms-and-condition">
      By proceeding, you agree with Verified Wallet{" "}
      <span style={{ color: "#603FF4", cursor: "pointer" }}>T&C</span> and{" "}
      <span style={{ color: "#603FF4", cursor: "pointer" }}>
        Privacy Policy.
      </span>
    </p>
  );
};

export const PrivacyOverlay = () => {
  return (
    <div className="privacy">
      <img alt="Privacy Icon" src={privacyIcon} className="privacy-icon" />
      <p className="privacy-text">Trusted by 10L+ Investors globally</p>
    </div>
  );
};
