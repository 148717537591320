import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import hidePin from "../../assests/page/hide-pin.svg";
import showPin from "../../assests/page/show-pin.svg";
import { useNavigate } from "react-router-dom";
import ProfilePopup from "./profile";
import { useState } from "react";

const ProfilePin = (props) => {
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const [showPassword, setShowPassword] = useState(true);
  const navigate = useNavigate();

  const handlleInputFocus = (currentInput) => {
    if (currentInput.value.length > 0 && Number(currentInput.value) >= 0) {
      const nextInput = currentInput.nextElementSibling;
      if (nextInput) {
        nextInput.focus();
      }
    } else if (currentInput.value.length === 0) {
      const previousInpput = currentInput.previousElementSibling;
      if (previousInpput) {
        previousInpput.focus();
      }
    }
  };

  const handleButtonDisplay = () => {
    const button = document.getElementById("profile-pin-popup-button");
    if (
      document.getElementById("card-pin-input1").value.length > 0 &&
      document.getElementById("card-pin-input2").value.length > 0 &&
      document.getElementById("card-pin-input3").value.length > 0 &&
      document.getElementById("card-pin-input4").value.length > 0
    ) {
      button.className = "startup-button-active";
    } else {
      button.className = "startup-button";
    }
  };

  return (
    <div className="search-filter-popup">
      <div className="search-filter-popup-content">
        <div className="asset-info-popup-header">
          <div className="asset-info-popup-header-main">
            <div className="asset-info-popup-header-main-text">
              <img
                onClick={() => {
                  props.setPopupContentPosition("flex-end");
                  props.setPopupContent(
                    <ProfilePopup
                      setShowPopup={props.setShowPopup}
                      setPopupContent={props.setPopupContent}
                      setPopupContentPosition={props.setPopupContentPosition}
                      setFavouriteClicked={props.setFavouriteClicked}
                      showProfileDetails={props.showProfileDetails || false}
                      customPortal={props.customPortal || ""}
                    />
                  );
                }}
                alt="Back Icon"
                src={backIcon}
              />
              <p className="search-filter-popup-header-text">
                Verify Current PIN
              </p>
            </div>
            <div className="asset-info-popup-icons">
              <img
                onClick={() => {
                  props.setPopupContentPosition("center");
                  props.setPopupContent(null);
                  props.setFavouriteClicked(false);
                  props.setShowPopup(false);
                  navigate(
                    window.location.href
                      .replace(window.location.search, "")
                      .replace(window.location.origin, "")
                  );
                }}
                alt="Close Icon"
                src={popupCloseIcon}
              />
            </div>
          </div>
        </div>

        <div className="profile-popup-content">
          <div className="profile-pin-popup-header">
            <p className="profile-pin-popup-header-text">Enter PIN</p>
            <div className="profile-pin-popup-header-body">
              <p className="profile-pin-popup-header-body-text">
                Confirm your existing PIN to proceed with changing your current
                PIN.
              </p>
            </div>
          </div>

          <div className="profile-pin-popup-body">
            <p className="profile-pin-popup-body-text">Enter PIN</p>

            <div className="otp-input-group">
              <input
                id="card-pin-input1"
                onInput={(e) => {
                  handlleInputFocus(e.target);
                  handleButtonDisplay();
                }}
                className="otp-input"
                type={!showPassword ? "password" : "text"}
                maxLength={1}
              />
              <input
                id="card-pin-input2"
                onInput={(e) => {
                  handlleInputFocus(e.target);
                  handleButtonDisplay();
                }}
                className="otp-input"
                type={!showPassword ? "password" : "text"}
                maxLength={1}
              />
              <input
                id="card-pin-input3"
                onInput={(e) => {
                  handlleInputFocus(e.target);
                  handleButtonDisplay();
                }}
                className="otp-input"
                type={!showPassword ? "password" : "text"}
                maxLength={1}
              />
              <input
                id="card-pin-input4"
                onInput={(e) => {
                  handlleInputFocus(e.target);
                  handleButtonDisplay();
                }}
                className="otp-input"
                type={!showPassword ? "password" : "text"}
                maxLength={1}
              />
            </div>

            <div className="profile-pin-popup-input-label">
              {passwordIncorrect && (
                <p className="profile-pin-popup-input-label-text">
                  Please enter the correct password
                </p>
              )}
              {showPassword && (
                <img
                  onClick={() => {
                    setShowPassword(false);
                  }}
                  className="profile-pin-popup-input-icon"
                  src={hidePin}
                  alt="Hide Pin Icon"
                />
              )}
              {!showPassword && (
                <img
                  onClick={() => {
                    setShowPassword(true);
                  }}
                  className="profile-pin-popup-input-icon"
                  src={showPin}
                  alt="Hide Pin Icon"
                />
              )}
            </div>
          </div>
        </div>

        <button
          id="profile-pin-popup-button"
          onClick={(e) => {
            if (
              e.target.className.includes("startup-button") &&
              e.target.className !== "startup-button"
            ) {
              e.target.className = "startup-button-click";
              props.setPopupContentPosition("flex-end");
              props.setPopupContent(props.nextPopup);
            } else {
              const buttonElement = document.getElementsByClassName(
                "startup-button-active"
              );
              if (buttonElement.length > 0) {
                buttonElement[0].className = "startup-button-click";
                props.setPopupContentPosition("flex-end");
                props.setPopupContent(props.nextPopup);
              }
            }
          }}
          className="startup-button"
        >
          <p className="add-funds-popup-a-f-button-text">Confirm</p>
        </button>
      </div>
    </div>
  );
};

export default ProfilePin;
