import checkbox from "../../assests/page/checkbox-icon.svg";
import checkboxClicked from "../../assests/page/checkbox-icon-clicked.svg";
import { useState } from "react";
import SuccessPopup from "./success";
import BorrowPopupPage from "./borrow";

const BorrowConfirmation = (props) => {
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);

  return (
    <div className="borrow-warning-popup">
      <div className="borrow-warning-popup-container">
        <p className="borrow-warning-popup-header-text">Please confirm</p>
        <p className="borrow-confirmation-popup-header-text">
          Confirm to issue a bond for collateral of the selected investment and
          its quantity?
        </p>
      </div>
      <div className="borrow-popup-content-body-footer">
        <div className="borrow-popup-content-body-footer-container">
          {!isCheckboxClicked && (
            <img
              onClick={(e) => {
                setIsCheckboxClicked(true);

                const button = document.getElementById(
                  "borrow-popup-next-button"
                );
                if (
                  button &&
                  document.getElementById("add-funds-popup-input").value
                    .length > 0 &&
                  Number(
                    document.getElementById("add-funds-popup-input").value
                  ) > 0
                ) {
                  button.className = "startup-button-active";
                }
              }}
              className="checkbox-icon"
              src={checkbox}
              alt="Checkbox Icon"
            />
          )}

          {isCheckboxClicked && (
            <img
              id="borrow-checkbox-clicked"
              onClick={(e) => {
                setIsCheckboxClicked(false);
                const button = document.getElementById(
                  "borrow-popup-next-button"
                );
                if (button) {
                  button.className = "startup-button";
                }
              }}
              className="checkbox-icon"
              src={checkboxClicked}
              alt="Checkbox Icon"
            />
          )}
        </div>
        <p className="borrow-confirmation-footer-text">
          I understand that once issued into a bond, the selected quantities of
          investments will not be available to sell.{" "}
          <span className="borrow-confirmation-footer-text-label">
            Learn More
          </span>
        </p>
      </div>
      <div
        style={{ width: "100%", marginBottom: "0" }}
        className="verify-kyc-buttons"
      >
        <button
          onClick={() => {
            props.setShowCustomPopup(false);
            props.setCustomPopupContent(null);
          }}
          className="verify-kyc-button-back"
        >
          <p className="verify-kyc-button-back-text">Cancel</p>
        </button>
        <button
          onClick={() => {
            props.setShowCustomPopup(false);
            props.setCustomPopupContent(null);
            props.setPopupContentPosition("flex-end");
            props.setPopupContent(
              <SuccessPopup
                setShowPopup={props.setShowPopup}
                setPopupContent={props.setPopupContent}
                setPopupContentPosition={props.setPopupContentPosition}
                isCustom={true}
                customTextHeader="Congratulations!"
                customTextFooter="A bond has been issued against your selected investments for collateral"
                // fotterContent={<div>
                //     <p></p>
                //     <div>
                //         <p></p>
                //         <img src={} alt="" />
                //     </div>
                // </div>}
                nextPopup={
                  <BorrowPopupPage
                    setShowPopup={props.setShowPopup}
                    setPopupContent={props.setPopupContent}
                    setPopupContentPosition={props.setPopupContentPosition}
                    currentStep="4"
                  />
                }
              />
            );
            props.setShowPopup(true);
          }}
          className="verify-kyc-button"
        >
          <p className="verify-kyc-button-text">Confirm</p>
        </button>
      </div>
    </div>
  );
};

export default BorrowConfirmation;
