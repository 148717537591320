import { useEffect, useState } from "react";
import popupCloseIcon from "../../assests/page/popup-close-icon.svg";
import indiaFlag from "../../assests/page/Flag_of_India 1.svg";
import expandIcon from "../../assests/page/chevron-right.svg";
import sliderEclipse from "../../assests/page/slider-eclipse.svg";
import checkbox from "../../assests/page/checkbox-icon.svg";
import checkboxClicked from "../../assests/page/checkbox-icon-clicked.svg";
import { allCountriesApi, popularCountries } from "../../utils/constants";
import infoIcon from "../../assests/page/info-icon.svg";
import backIcon from "../../assests/page/chevron-left.svg";
import searchIcon from "../../assests/sideBar/search-icon.svg";
import dropdownIcon from "../../assests/page/input-dropdown.svg";
import axios from "axios";
import GeneralPopupBackground from ".";
import BorrowWarningPopup from "./borrowWarning";
import BorrowConfirmation from "./borrowConfirmation";
import PaymentProcess from "./paymentProcess";

const BorrowPopupPage = (props) => {
  const [amount, setAmount] = useState("");
  const [showAllCurrencies, setShowAllCurrencies] = useState(false);
  const [actionType, setActionType] = useState(props.actionTye || "borrow");
  const [allCurrencies, setAllCurrencies] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({
    countryName: "India",
    officialName: "India",
    flag: indiaFlag,
    currencySymbol: "INR",
    currencyName: "Indian Rupee",
    officialCurrencySymbol: "₹",
    isClicked: false,
  });
  const [tenureSelected, setTenureSelected] = useState("");
  const [sliderLength, setSliderLength] = useState(20);
  const [isCheckboxClicked, setIsCheckboxClicked] = useState(false);

  const [newSearchContent, setNewSearchContent] = useState(null);
  const [showCustomPopup, setShowCustomPopup] = useState(false);
  const [customPopupContent, setCustomPopupContent] = useState(null);
  const [hasInvestment, setHasInvestments] = useState(true);
  const [step, setStep] = useState(props.currentStep ? props.currentStep : "0");
  const [assetClickedId, setAssetClickedId] = useState("0");

  useEffect(
    () => {
      (async () => {
        const getAllCurrenciesAndCountries = async () => {
          await axios({ method: "GET", url: allCountriesApi })
            .then((res) => {
              const currencies = res.data.map((cnt) => {
                if (cnt.currencies && Object.keys(cnt.currencies).length > 0) {
                  return {
                    countryName: cnt.name.common,
                    officialName: cnt.name.official,
                    flag: cnt.flags.svg,
                    currencySymbol: Object.keys(cnt.currencies || {})[0],
                    currencyName: Object.values(cnt.currencies || {})[0].name,
                    officialCurrencySymbol: Object.values(
                      cnt.currencies || {}
                    )[0].symbol,
                  };
                } else {
                  return null;
                }
              });
              setAllCurrencies(
                currencies.filter((currency) => currency !== null)
              );
            })
            .catch((err) => {
              console.error("get all countries failled with error: ", err);
              //Todo: toast here
            });
        };

        // if (showAllCurrencies) {
        //   await getAllCurrenciesAndCountries();
        // }
        await getAllCurrenciesAndCountries();
      })();
    },
    []
    // [showAllCurrencies]
  );

  const handleBorrowButtonDisplay = () => {
    const button = document.getElementById("borrow-popup-next-button");
    if (
      button &&
      document.getElementById("add-funds-popup-input").value.length > 0 &&
      Number(document.getElementById("add-funds-popup-input").value) > 0 &&
      isCheckboxClicked
    ) {
      button.className = "startup-button-active";
    } else if (button) {
      button.className = "startup-button";
    }
  };

  const handleChooseCurrencyButtonDisplay = () => {
    const button = document.getElementById(
      "borrow-choose-currency-popup-button"
    );
    if (button && selectedCurrency.isClicked) {
      button.className = "startup-button-active";
    } else if (button) {
      button.className = "startup-button";
    }
  };

  const handleBorrowFirstStep = () => {
    if (hasInvestment) {
      setStep("2");
    } else {
      setCustomPopupContent(
        <BorrowWarningPopup
          setShowCustomPopup={setShowCustomPopup}
          setShowPopup={props.setShowPopup}
          setPopupContent={props.setPopupContent}
          setPopupContentPosition={props.setPopupContentPosition}
          setCustomPopupContent={setCustomPopupContent}
        />
      );
      setShowCustomPopup(true);
    }
  };

  const handleBorrowSecondStep = () => {
    setStep("3");
    setAssetClickedId("0");
  };

  const handleBorrowThirdStep = () => {
    setCustomPopupContent(
      <BorrowConfirmation
        setShowCustomPopup={setShowCustomPopup}
        setShowPopup={props.setShowPopup}
        setPopupContent={props.setPopupContent}
        setPopupContentPosition={props.setPopupContentPosition}
        setCustomPopupContent={setCustomPopupContent}
      />
    );
    setShowCustomPopup(true);
  };

  return (
    <>
      {showCustomPopup && (
        <GeneralPopupBackground
          popupContent={customPopupContent}
          popupContentPosition={"center"}
        />
      )}
      <div className="search-filter-popup">
        <div className="search-filter-popup-content">
          {!showAllCurrencies && (
            <>
              {actionType === "borrow" && (
                <div className="borrow-popup-content">
                  <div className="borrow-popup-content-header">
                    <div className="add-funds-content-header">
                      <div className="add-funds-content-header-text-container">
                        {Number(step) > 1 && (
                          <img
                            onClick={() => {
                              setStep(`${Number(step) - 1}`);
                              setAssetClickedId("0");
                            }}
                            className="borrow-popup-back-icon"
                            alt="Back Icon"
                            src={backIcon}
                          />
                        )}
                        <p className="add-funds-search-header-text">
                          Borrow Money
                        </p>
                      </div>
                      <img
                        onClick={() => {
                          props.setShowPopup(false);
                          props.setPopupContent(null);
                          props.setPopupContentPosition("center");
                          if (props.setFavouriteClicked) {
                            props.setFavouriteClicked(false);
                          }
                          setShowCustomPopup(false);
                        }}
                        className="add-funds-popup-close-icon"
                        alt="Close Icon"
                        src={popupCloseIcon}
                      />
                    </div>
                    {step === "1" && (
                      <div className="borrow-popup-content-header-second">
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider"></div>
                        <div className="borrow-popup-content-slider"></div>
                        <div className="borrow-popup-content-slider"></div>
                      </div>
                    )}
                    {step === "2" && (
                      <div className="borrow-popup-content-header-second">
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider"></div>
                        <div className="borrow-popup-content-slider"></div>
                      </div>
                    )}
                    {step === "3" && (
                      <div className="borrow-popup-content-header-second">
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider"></div>
                      </div>
                    )}
                    {step === "4" && (
                      <div className="borrow-popup-content-header-second">
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider-active"></div>
                        <div className="borrow-popup-content-slider-active"></div>
                      </div>
                    )}
                  </div>
                  <div className="borrow-popup-content-body">
                    <div className="borrow-popup-content-body-main">
                      <div className="borrow-popup-content-body-main-first">
                        {step === "1" && (
                          <p className="borrow-popup-content-body-main-first-header-text">
                            Fill in your loan requirements
                          </p>
                        )}
                        {step === "2" && (
                          <p className="borrow-popup-content-body-main-first-header-text">
                            Select an investment to put as collateral.
                          </p>
                        )}
                        {step === "3" && (
                          <p className="borrow-popup-content-body-main-first-header-text">
                            Fill in your loan requirements
                          </p>
                        )}
                        {step === "4" && (
                          <p className="borrow-popup-content-body-main-first-header-text">
                            Preview issued bond and loan details to apply for
                            loan.
                          </p>
                        )}
                        {Number(step) < 3 && (
                          <div className="borrow-popup-content-body-main-first-container">
                            <div className="borrow-popup-content-body-main-first-content">
                              <div className="borrow-popup-content-body-main-first-header-container">
                                <div className="borrow-popup-content-body-main-first-header">
                                  {step === "1" && (
                                    <p className="borrow-popup-content-body-main-first-text">
                                      Select loan amount
                                    </p>
                                  )}
                                  {step === "2" && (
                                    <p className="borrow-popup-content-body-main-first-text">
                                      My Investments
                                    </p>
                                  )}

                                  {step === "1" && (
                                    <div className="a-f-content-footer-input-container">
                                      <div className="a-f-content-footer-input-group">
                                        <p className="a-f-content-footer-input-text">
                                          {
                                            selectedCurrency.officialCurrencySymbol
                                          }
                                        </p>
                                        <input
                                          id="add-funds-popup-input"
                                          onInput={(e) => {
                                            setAmount(Number(e.target.value));
                                            handleBorrowButtonDisplay();
                                          }}
                                          type="number"
                                          className="a-f-content-footer-input"
                                          placeholder="00.00"
                                        />
                                      </div>
                                      <div
                                        onClick={() => {
                                          setShowAllCurrencies(true);
                                        }}
                                        className="a-f-content-footer-currencies"
                                      >
                                        <div className="a-f-content-footer-currencies-container">
                                          <p className="a-f-content-footer-currency">
                                            {selectedCurrency.currencySymbol}
                                          </p>
                                          <img
                                            alt="Exand Icon"
                                            src={expandIcon}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="borrow-popup-content-body-main-first-footer">
                                  {step === "1" && (
                                    <>
                                      <div className="borrow-popup-content-body-main-first-footer-content">
                                        <p className="borrow-popup-content-body-main-first-footer-text">
                                          ₹5K
                                        </p>
                                      </div>
                                      <div className="borrow-popup-content-body-main-first-footer-content">
                                        <p className="borrow-popup-content-body-main-first-footer-text">
                                          ₹10k
                                        </p>
                                      </div>
                                      <div className="borrow-popup-content-body-main-first-footer-content">
                                        <p className="borrow-popup-content-body-main-first-footer-text">
                                          ₹50K
                                        </p>
                                      </div>
                                      <div className="borrow-popup-content-body-main-first-footer-content">
                                        <p className="borrow-popup-content-body-main-first-footer-text">
                                          ₹1L
                                        </p>
                                      </div>
                                    </>
                                  )}

                                  {step === "2" && (
                                    <div className="gp-main-content-footer-buttons-group">
                                      <button className="gp-main-content-footer-button-active">
                                        <p className="gp-main-content-footer-button-text-active">
                                          Private Stocks
                                        </p>
                                      </button>
                                      <button className="gp-main-content-footer-button">
                                        <p className="gp-main-content-footer-button-text">
                                          Private Bonds
                                        </p>
                                      </button>
                                      <button className="gp-main-content-footer-button">
                                        <p className="gp-main-content-footer-button-text">
                                          Funds
                                        </p>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {step === "1" && (
                        <div className="borrow-popup-content-body-main-second">
                          <p className="borrow-popup-content-body-main-first-text">
                            Select Tenure
                          </p>
                          <div className="borrow-popup-content-body-main-second-content">
                            <div className="borrow-popup-content-body-main-second-content-header">
                              <div className="borrow-popup-content-body-main-second-content-header-slider">
                                <div
                                  style={{ width: `${sliderLength}%` }}
                                  className="borrow-popup-content-body-main-second-content-header-slider-line"
                                ></div>
                                <img
                                  style={{ left: `${sliderLength - 0.5}%` }}
                                  className="borrow-popup-content-body-main-second-content-header-slider-eclipse"
                                  src={sliderEclipse}
                                  alt="Slider Eclipse"
                                />
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-content-footer">
                              <p
                                onClick={() => {
                                  setSliderLength(20);
                                }}
                                className="borrow-popup-content-body-main-second-content-footer-text"
                              >
                                3M
                              </p>
                              <p
                                onClick={() => {
                                  setSliderLength(40);
                                }}
                                className="borrow-popup-content-body-main-second-content-footer-text"
                              >
                                6M
                              </p>
                              <p
                                onClick={() => {
                                  setSliderLength(60);
                                }}
                                className="borrow-popup-content-body-main-second-content-footer-text"
                              >
                                1Y
                              </p>
                              <p
                                onClick={() => {
                                  setSliderLength(80);
                                }}
                                className="borrow-popup-content-body-main-second-content-footer-text"
                              >
                                2Y
                              </p>
                              <p
                                onClick={() => {
                                  setSliderLength(100);
                                }}
                                className="borrow-popup-content-body-main-second-content-footer-text"
                              >
                                4Y
                              </p>
                            </div>
                          </div>
                          <div className="borrow-popup-content-body-main-third-content">
                            <div className="borrow-popup-content-body-main-third-content-main">
                              <p className="borrow-popup-content-body-main-third-content-main-header">
                                Collaterals Required
                              </p>
                              <p className="borrow-popup-content-body-main-third-content-main-footer">
                                ₹12,000
                              </p>
                            </div>
                            <div className="borrow-popup-content-body-main-third-content-main">
                              <p className="borrow-popup-content-body-main-third-content-main-header">
                                Interest Amount
                              </p>
                              <p className="borrow-popup-content-body-main-third-content-main-footer">
                                ₹2,000
                              </p>
                            </div>
                          </div>
                          <div className="borrow-popup-content-body-main-last-content">
                            <div className="borrow-popup-content-body-main-last-content-container">
                              <p className="borrow-popup-content-body-main-last-content-text">
                                Your interest in 6 Month
                              </p>
                              <img src={infoIcon} alt="Info Icon" />
                            </div>
                          </div>
                        </div>
                      )}

                      {step === "2" && (
                        <div className="borrow-popup-content-body-main-second-custom">
                          <div
                            onClick={() => {
                              setAssetClickedId("1");
                              const button = document.getElementById(
                                "borrow-popup-next-button"
                              );
                              if (button) {
                                button.className = "startup-button-active";
                              }
                            }}
                            className={
                              assetClickedId === "1"
                                ? "borrow-popup-content-body-main-second-custom-content-active"
                                : "borrow-popup-content-body-main-second-custom-content"
                            }
                          >
                            <div className="borrow-popup-content-body-main-second-custom-content-header">
                              <div className="borrow-popup-content-body-main-second-custom-content-header-first">
                                <div className="borrow-popup-content-body-main-second-custom-content-header-first-container">
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-title">
                                    Spacex Ltd{" "}
                                    <span className="borrow-popup-content-body-main-second-custom-content-header-title-label">
                                      (Qty: 10)
                                    </span>
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-label">
                                    Spacex Ltd
                                  </p>
                                </div>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-header-second-container">
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-title">
                                  $ 1520.01
                                </p>
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-label">
                                  Current Value
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-content-footer">
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-first">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-first-text">
                                  ATP:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-first-text-label">
                                    $130.76
                                  </span>
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-first">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-first-text">
                                  LTP:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-first-text-label">
                                    $153
                                  </span>
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-last">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-last-text">
                                  P&L:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-last-text-label">
                                    $222.04
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setAssetClickedId("2");
                              const button = document.getElementById(
                                "borrow-popup-next-button"
                              );
                              if (button) {
                                button.className = "startup-button-active";
                              }
                            }}
                            className={
                              assetClickedId === "2"
                                ? "borrow-popup-content-body-main-second-custom-content-active"
                                : "borrow-popup-content-body-main-second-custom-content"
                            }
                          >
                            <div className="borrow-popup-content-body-main-second-custom-content-header">
                              <div className="borrow-popup-content-body-main-second-custom-content-header-first">
                                <div className="borrow-popup-content-body-main-second-custom-content-header-first-container">
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-title">
                                    Spacex Ltd{" "}
                                    <span className="borrow-popup-content-body-main-second-custom-content-header-title-label">
                                      (Qty: 10)
                                    </span>
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-label">
                                    Spacex Ltd
                                  </p>
                                </div>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-header-second-container">
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-title">
                                  $ 1520.01
                                </p>
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-label">
                                  Current Value
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-content-footer">
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-first">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-first-text">
                                  ATP:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-first-text-label">
                                    $130.76
                                  </span>
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-first">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-first-text">
                                  LTP:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-first-text-label">
                                    $153
                                  </span>
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-last">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-last-text">
                                  P&L:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-last-text-label-loss">
                                    $222.04
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            onClick={() => {
                              setAssetClickedId("3");
                              const button = document.getElementById(
                                "borrow-popup-next-button"
                              );
                              if (button) {
                                button.className = "startup-button-active";
                              }
                            }}
                            className={
                              assetClickedId === "3"
                                ? "borrow-popup-content-body-main-second-custom-content-active"
                                : "borrow-popup-content-body-main-second-custom-content"
                            }
                          >
                            <div className="borrow-popup-content-body-main-second-custom-content-header">
                              <div className="borrow-popup-content-body-main-second-custom-content-header-first">
                                <div className="borrow-popup-content-body-main-second-custom-content-header-first-container">
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-title">
                                    Spacex Ltd{" "}
                                    <span className="borrow-popup-content-body-main-second-custom-content-header-title-label">
                                      (Qty: 10)
                                    </span>
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-label">
                                    Spacex Ltd
                                  </p>
                                </div>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-header-second-container">
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-title">
                                  $ 1520.01
                                </p>
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-label">
                                  Current Value
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-content-footer">
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-first">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-first-text">
                                  ATP:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-first-text-label">
                                    $130.76
                                  </span>
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-first">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-first-text">
                                  LTP:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-first-text-label">
                                    $153
                                  </span>
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-footer-last">
                                <p className="borrow-popup-content-body-main-second-custom-content-footer-last-text">
                                  P&L:{" "}
                                  <span className="borrow-popup-content-body-main-second-custom-content-footer-last-text-label">
                                    $222.04
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {step === "3" && (
                        <div className="borrow-popup-content-body-main-second-custom-third">
                          <div className="borrow-popup-content-body-main-second-custom-content-frameless">
                            <div className="borrow-popup-content-body-main-second-custom-content-header">
                              <div className="borrow-popup-content-body-main-second-custom-content-header-first">
                                <div className="borrow-popup-content-body-main-second-custom-content-header-first-container">
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-title">
                                    Spacex Ltd{" "}
                                    <span className="borrow-popup-content-body-main-second-custom-content-header-title-label">
                                      (Qty: 10)
                                    </span>
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-content-header-label">
                                    Spacex Ltd
                                  </p>
                                </div>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-content-header-second-container">
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-label">
                                  Current Value
                                </p>
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-title">
                                  $ 1520.01
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="borrow-popup-content-body-main-second-custom-third-main">
                            <div className="borrow-popup-content-body-main-second-custom-third-header">
                              <div className="borrow-popup-content-body-main-second-custom-third-header-first">
                                <div className="borrow-popup-content-body-main-second-custom-third-header-first-container">
                                  <div className="borrow-popup-content-body-main-second-custom-third-header-first-header">
                                    <p className="borrow-popup-content-body-main-second-custom-content-header-second-title">
                                      Select Quantity:
                                    </p>
                                    <p className="borrow-popup-content-body-main-second-custom-content-header-label">
                                      Quantity you wish to put forward as
                                      collateral
                                    </p>
                                  </div>

                                  <div className="a-f-content-footer-input-container">
                                    <div className="a-f-content-footer-input-group">
                                      <input
                                        id="add-funds-popup-input"
                                        onInput={(e) => {
                                          setAmount(Number(e.target.value));
                                          handleBorrowButtonDisplay();
                                        }}
                                        type="number"
                                        className="a-f-content-footer-input"
                                        placeholder="00.00"
                                        value="87"
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        setShowAllCurrencies(true);
                                      }}
                                      className="a-f-content-footer-currencies"
                                    >
                                      <div className="a-f-content-footer-currencies-container">
                                        <img
                                          alt="Exand Icon"
                                          src={dropdownIcon}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-third-header-second">
                                <div className="borrow-popup-content-body-main-second-content-header-slider">
                                  <div
                                    style={{ width: `${sliderLength}%` }}
                                    className="borrow-popup-content-body-main-second-content-header-slider-line"
                                  ></div>
                                  <img
                                    style={{ left: `${sliderLength - 0.5}%` }}
                                    className="borrow-popup-content-body-main-second-content-header-slider-eclipse"
                                    src={sliderEclipse}
                                    alt="Slider Eclipse"
                                  />
                                </div>
                                <div className="borrow-popup-content-body-main-second-custom-third-header-second-body">
                                  <p className="borrow-popup-content-body-main-second-custom-third-header-second-body-text">
                                    0
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-third-header-second-body-text">
                                    250
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-third-footer">
                              <div className="borrow-popup-content-body-main-second-custom-third-footer-body">
                                <p className="borrow-popup-content-body-main-second-custom-content-header-second-title">
                                  Total Value
                                </p>
                                <p
                                  style={{ color: "#5B5D6E" }}
                                  className="borrow-popup-content-body-main-second-custom-content-header-label"
                                >
                                  Total value of selected quanity
                                </p>
                              </div>
                              <p className="borrow-popup-content-body-main-second-custom-third-footer-text">
                                ₹12,227.85
                              </p>
                            </div>
                          </div>

                          <div className="borrow-popup-content-body-main-second-custom-last-main">
                            <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-container">
                                <p
                                  style={{ color: "#5B5D6E" }}
                                  className="borrow-popup-content-body-main-second-custom-content-header-label"
                                >
                                  Added Collateral Value:
                                </p>
                              </div>
                              <p className="borrow-popup-content-body-main-second-custom-last-main-text">
                                ₹12,227.85
                              </p>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-container">
                                <p
                                  style={{ color: "#5B5D6E" }}
                                  className="borrow-popup-content-body-main-second-custom-content-header-label"
                                >
                                  Required Collateral Value:
                                </p>
                              </div>
                              <p className="borrow-popup-content-body-main-second-custom-last-main-text">
                                ₹12,000.00
                              </p>
                            </div>
                          </div>
                        </div>
                      )}

                      {step === "4" && (
                        <div className="borrow-popup-content-body-main-second-custom-last">
                          <div className="borrow-popup-content-body-main-second-custom-last-container">
                            <div className="borrow-popup-content-body-main-second-custom-last-header">
                              <div className="borrow-popup-content-body-main-second-custom-last-header-container">
                                <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                  Bond ID:
                                </p>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  11DNV- C3SW
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-last-main">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Bond Issuer Name:
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  Sugato Paul
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Total Loan Amount :
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  ₹10,000
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-last-main">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Total Loan Tenure :
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  6 Months
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Interest Amount :
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  ₹2,000
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-last-main">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Required Value of Collateral Bond:
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  ₹12,000
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Value of Issued Collateral Bond:
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  ₹ 12,227.85
                                </p>
                              </div>
                            </div>

                            <div className="borrow-popup-content-body-main-second-custom-last-footer">
                              <div className="borrow-popup-content-body-main-second-custom-last-footer-container">
                                <div className="borrow-popup-content-body-main-second-custom-last-footer-first">
                                  <div className="borrow-popup-content-body-main-second-custom-last-footer-first-container">
                                    <p className="borrow-popup-content-body-main-second-custom-last-footer-first-text">
                                      KGB Ltd{" "}
                                      <span
                                        style={{ color: "#5B5D6E" }}
                                        className="borrow-popup-content-body-main-second-custom-last-label-text"
                                      >
                                        (Qty: 87)
                                      </span>
                                    </p>
                                    <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                      Kerala Gramin Bank
                                    </p>
                                  </div>
                                </div>
                                <div className="borrow-popup-content-body-main-second-custom-last-footer-second">
                                  <p
                                    style={{ color: "#2E3032" }}
                                    className="borrow-popup-content-body-main-second-custom-last-label-text"
                                  >
                                    Current Value
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                    ₹ 12,527.85
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {step === "1" && (
                      <div className="borrow-popup-content-body-footer">
                        <div className="borrow-popup-content-body-footer-container">
                          {!isCheckboxClicked && (
                            <img
                              onClick={(e) => {
                                setIsCheckboxClicked(true);

                                const button = document.getElementById(
                                  "borrow-popup-next-button"
                                );
                                if (
                                  button &&
                                  document.getElementById(
                                    "add-funds-popup-input"
                                  ).value.length > 0 &&
                                  Number(
                                    document.getElementById(
                                      "add-funds-popup-input"
                                    ).value
                                  ) > 0
                                ) {
                                  button.className = "startup-button-active";
                                }
                              }}
                              className="checkbox-icon"
                              src={checkbox}
                              alt="Checkbox Icon"
                            />
                          )}

                          {isCheckboxClicked && (
                            <img
                              id="borrow-checkbox-clicked"
                              onClick={(e) => {
                                setIsCheckboxClicked(false);
                                const button = document.getElementById(
                                  "borrow-popup-next-button"
                                );
                                if (button) {
                                  button.className = "startup-button";
                                }
                              }}
                              className="checkbox-icon"
                              src={checkboxClicked}
                              alt="Checkbox Icon"
                            />
                          )}
                        </div>
                        <p className="borrow-popup-content-body-footer-text">
                          I have read all the terms and conditions and I
                          understand the loan terms and clause.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {actionType === "repay" && (
                <div className="borrow-popup-content">
                  <div className="borrow-popup-content-header">
                    <div className="add-funds-content-header">
                      <div className="add-funds-content-header-text-container">
                        {Number(step) > 1 && (
                          <img
                            onClick={() => {
                              setStep(`${Number(step) - 1}`);
                              setAssetClickedId("0");
                            }}
                            className="borrow-popup-back-icon"
                            alt="Back Icon"
                            src={backIcon}
                          />
                        )}
                        {step === "1" && (
                          <p className="add-funds-search-header-text">
                            Loan Details
                          </p>
                        )}
                        {step === "2" && (
                          <p className="add-funds-search-header-text">
                            Loan Repayment
                          </p>
                        )}
                      </div>
                      <img
                        onClick={() => {
                          props.setShowPopup(false);
                          props.setPopupContent(null);
                          props.setPopupContentPosition("center");
                          if (props.setFavouriteClicked) {
                            props.setFavouriteClicked(false);
                          }
                          setShowCustomPopup(false);
                          props.setShowNotifications(true);
                        }}
                        className="add-funds-popup-close-icon"
                        alt="Close Icon"
                        src={popupCloseIcon}
                      />
                    </div>
                  </div>

                  <div className="borrow-popup-repay-content-body-container">
                    {step === "2" && (
                      <p className="borrow-popup-repay-content-body-text">
                        Select a mode of payment to repay your loan
                      </p>
                    )}
                    <div
                      style={{ position: "relative" }}
                      className="borrow-popup-content-body"
                    >
                      {step === "1" && (
                        <div className="borrow-popup-content-body-main-label">
                          <p className="borrow-popup-content-body-main-label-text">
                            Ongoing
                          </p>
                        </div>
                      )}

                      <div className="borrow-popup-content-body-main">
                        <div className="borrow-popup-content-body-main-second-custom-last">
                          <div className="borrow-popup-content-body-main-second-custom-last-container">
                            <div className="borrow-popup-content-body-main-second-custom-last-header">
                              <div className="borrow-popup-content-body-main-second-custom-last-header-container">
                                <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                  Bond ID:
                                </p>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  11DNV- C3SW
                                </p>
                              </div>
                            </div>
                            <div className="borrow-popup-content-body-main-second-custom-last-main">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Loan Given By:
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  Alok Anand
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Total Loan Amount :
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  $148.80
                                </p>
                              </div>
                            </div>

                            <div className="borrow-popup-content-body-main-second-custom-last-main">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Total Loan Tenure :
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  6 Months
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Interest Amount :
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  $21.58 (14.5%)
                                </p>
                              </div>
                            </div>

                            <div className="borrow-popup-content-body-main-second-custom-last-main">
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Required Value of Collateral Bond:
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  $148.80
                                </p>
                              </div>
                              <div className="borrow-popup-content-body-main-second-custom-last-main-content">
                                <div className="borrow-popup-content-body-main-second-custom-last-main-content-container">
                                  <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                    Value of Issued Collateral Bond:
                                  </p>
                                </div>
                                <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                  $ 193.44
                                </p>
                              </div>
                            </div>

                            <div className="borrow-popup-content-body-main-second-custom-last-footer">
                              <div className="borrow-popup-content-body-main-second-custom-last-footer-container">
                                <div className="borrow-popup-content-body-main-second-custom-last-footer-first">
                                  <div className="borrow-popup-content-body-main-second-custom-last-footer-first-container">
                                    <p className="borrow-popup-content-body-main-second-custom-last-footer-first-text">
                                      Nucleus Ltd{" "}
                                      <span
                                        style={{ color: "#5B5D6E" }}
                                        className="borrow-popup-content-body-main-second-custom-last-label-text"
                                      >
                                        (Qty: 10)
                                      </span>
                                    </p>
                                    <p className="borrow-popup-content-body-main-second-custom-last-label-text">
                                      Nucleus Ltd.
                                    </p>
                                  </div>
                                </div>
                                <div className="borrow-popup-content-body-main-second-custom-last-footer-second">
                                  <p
                                    style={{ color: "#2E3032" }}
                                    className="borrow-popup-content-body-main-second-custom-last-label-text"
                                  >
                                    Current Value
                                  </p>
                                  <p className="borrow-popup-content-body-main-second-custom-last-header-text">
                                    $14.88
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="borrow-popup-repay-footer">
                    <div className="borrow-popup-repay-footer-content">
                      <div className="borrow-popup-repay-footer-content-header">
                        <p className="borrow-popup-repay-footer-content-header-text">
                          Date of Loan Issued:
                        </p>
                      </div>
                      <p className="borrow-popup-repay-footer-content-text">
                        22nd Sep, 2024
                      </p>
                    </div>

                    <div className="borrow-popup-repay-footer-content">
                      <div className="borrow-popup-repay-footer-content-header">
                        <p className="borrow-popup-repay-footer-content-header-text">
                          Date of Repayment:
                        </p>
                      </div>
                      <p className="borrow-popup-repay-footer-content-text">
                        21st Mar, 2025
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {showAllCurrencies && (
            <div
              style={{ overflow: "auto", marginBottom: "30px" }}
              className="add-funds-content"
            >
              <div className="add-funds-content-header">
                <div className="add-funds-content-header-text-container">
                  <img
                    onClick={() => {
                      setShowAllCurrencies(false);
                      setNewSearchContent(null);
                    }}
                    className="add-funds-popup-back-icon"
                    alt="Back Icon"
                    src={backIcon}
                  />
                  <p className="add-funds-search-header-text">
                    Choose a Currency
                  </p>
                </div>
                <img
                  onClick={() => {
                    props.setShowPopup(false);
                    props.setPopupContent(null);
                    setNewSearchContent(null);
                    if (props.setPopupContentPosition) {
                      props.setPopupContentPosition("center");
                    }
                  }}
                  className="add-funds-popup-close-icon"
                  alt="Close Icon"
                  src={popupCloseIcon}
                />
              </div>
              <div className="add-funds-content-search">
                <img alt="Search Icon" src={searchIcon} />
                <input
                  onInputCapture={() => {
                    selectedCurrency.isClicked = false;
                  }}
                  onInput={(e) => {
                    if (e.target.value === "") {
                      setNewSearchContent(null);
                    } else {
                      setNewSearchContent(
                        // eslint-disable-next-line array-callback-return
                        allCurrencies.filter((curr) => {
                          if (
                            curr.currencySymbol
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          ) {
                            curr.searched = e.target.value.toLowerCase();
                            curr.nameArray = curr.currencySymbol.split("");
                            return curr;
                          }
                        })
                      );
                    }
                    const previouslyActiveTag = document.getElementsByClassName(
                      "a-f-content-search-header-main-active"
                    );
                    if (previouslyActiveTag.length > 0) {
                      previouslyActiveTag[0].className =
                        "a-f-content-search-header-main";
                    }
                    handleChooseCurrencyButtonDisplay();
                  }}
                  className="add-funds-content-search-input"
                  type="text"
                  placeholder="Search for a currency / country"
                />
              </div>
            </div>
          )}

          {showAllCurrencies && (
            <button
              id="borrow-choose-currency-popup-button"
              onClick={(e) => {
                if (
                  e.target.className.includes("startup-button") &&
                  e.target.className !== "startup-button"
                ) {
                  e.target.className = "startup-button-click";
                  setShowAllCurrencies(false);
                  setNewSearchContent(null);
                } else {
                  const buttonElement = document.getElementsByClassName(
                    "startup-button-active"
                  );
                  if (buttonElement.length > 0) {
                    buttonElement[0].className = "startup-button-click";
                    setShowAllCurrencies(false);
                    setNewSearchContent(null);
                  }
                }
              }}
              className="startup-button"
            >
              <p className="st-button-text">Proceed</p>
            </button>
          )}

          {!showAllCurrencies && actionType === "borrow" && (
            <>
              {step === "1" && (
                <button
                  id="borrow-popup-next-button"
                  onClick={(e) => {
                    if (
                      e.target.className.includes("startup-button") &&
                      e.target.className !== "startup-button"
                    ) {
                      e.target.className = "startup-button-click";
                      handleBorrowFirstStep();
                    } else {
                      const buttonElement = document.getElementsByClassName(
                        "startup-button-active"
                      );
                      if (buttonElement.length > 0) {
                        buttonElement[0].className = "startup-button-click";
                        handleBorrowFirstStep();
                      }
                    }
                  }}
                  className="startup-button"
                >
                  <p className="add-funds-popup-a-f-button-text">Next</p>
                </button>
              )}
              {step === "2" && (
                <button
                  id="borrow-popup-next-button"
                  onClick={(e) => {
                    if (
                      e.target.className.includes("startup-button") &&
                      e.target.className !== "startup-button"
                    ) {
                      e.target.className = "startup-button-click";
                      handleBorrowSecondStep();
                    } else {
                      const buttonElement = document.getElementsByClassName(
                        "startup-button-active"
                      );
                      if (buttonElement.length > 0) {
                        buttonElement[0].className = "startup-button-click";
                        handleBorrowSecondStep();
                      }
                    }
                  }}
                  className="startup-button"
                >
                  <p className="add-funds-popup-a-f-button-text">Next</p>
                </button>
              )}
              {step === "3" && (
                <button
                  id="borrow-popup-next-button"
                  onClick={(e) => {
                    if (
                      e.target.className.includes("startup-button") &&
                      e.target.className !== "startup-button"
                    ) {
                      e.target.className = "startup-button-click";
                      handleBorrowThirdStep();
                    } else {
                      const buttonElement = document.getElementsByClassName(
                        "startup-button-active"
                      );
                      if (buttonElement.length > 0) {
                        buttonElement[0].className = "startup-button-click";
                        handleBorrowThirdStep();
                      }
                    }
                  }}
                  className="startup-button-active"
                >
                  <p className="add-funds-popup-a-f-button-text">Next</p>
                </button>
              )}
              {step === "4" && (
                <div style={{ width: "100%" }} className="verify-kyc-buttons">
                  <button
                    onClick={() => {
                      props.setShowPopup(false);
                      props.setPopupContent(null);
                      if (props.setFavouriteClicked) {
                        props.setFavouriteClicked(false);
                      }
                      props.setPopupContentPosition("center");
                      setShowCustomPopup(false);
                    }}
                    className="verify-kyc-button-back"
                  >
                    <p className="verify-kyc-button-back-text">Cancel</p>
                  </button>
                  <button
                    onClick={() => {
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(
                        <PaymentProcess
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                        />
                      );
                    }}
                    className="verify-kyc-button"
                  >
                    <p className="verify-kyc-button-text">Apply for Loan</p>
                  </button>
                </div>
              )}
            </>
          )}

          {!showAllCurrencies && actionType === "repay" && (
            <>
              {step === "1" && (
                <button
                  id="borrow-popup-next-button"
                  onClick={(e) => {
                    if (
                      e.target.className.includes("startup-button") &&
                      e.target.className !== "startup-button"
                    ) {
                      e.target.className = "startup-button-click";
                      setStep("2");
                    } else {
                      const buttonElement = document.getElementsByClassName(
                        "startup-button-active"
                      );
                      if (buttonElement.length > 0) {
                        buttonElement[0].className = "startup-button-click";
                        setStep("2");
                      }
                    }
                  }}
                  className="startup-button-active"
                >
                  <p className="add-funds-popup-a-f-button-text">Repay Loan</p>
                </button>
              )}
              {step === "2" && (
                <button
                  id="borrow-popup-next-button"
                  onClick={(e) => {
                    if (
                      e.target.className.includes("startup-button") &&
                      e.target.className !== "startup-button"
                    ) {
                      e.target.className = "startup-button-click";
                      props.setPopupContentPosition("flex-end");
                      props.setPopupContent(
                        <PaymentProcess
                          setShowPopup={props.setShowPopup}
                          setPopupContent={props.setPopupContent}
                          setPopupContentPosition={
                            props.setPopupContentPosition
                          }
                        />
                      );
                    } else {
                      const buttonElement = document.getElementsByClassName(
                        "startup-button-active"
                      );
                      if (buttonElement.length > 0) {
                        buttonElement[0].className = "startup-button-click";
                        props.setPopupContentPosition("flex-end");
                        props.setPopupContent(
                          <PaymentProcess
                            setShowPopup={props.setShowPopup}
                            setPopupContent={props.setPopupContent}
                            setPopupContentPosition={
                              props.setPopupContentPosition
                            }
                          />
                        );
                      }
                    }
                  }}
                  className="startup-button-active"
                >
                  <p className="add-funds-popup-a-f-button-text">Pay Now</p>
                </button>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BorrowPopupPage;
