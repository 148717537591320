import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CommonHeader from "./layouts/header";
import AssetsPage from "./pages/investments/assets";
import LayoutBody from "./layouts/content";
import React, { lazy, Suspense, useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  allCountriesApi,
  countriesDetails,
  maximumScreenForMobile,
  paraswapTokensBaseUrl,
} from "./utils/constants";
import CommonFooter from "./layouts/footer";
import StartUpPage from "./pages/ftu/startup";
import StartupLanguagePage from "./pages/ftu/language";
import StartupContactPage from "./pages/ftu/contact";
import StartupOtpPage from "./pages/ftu/otp";
import GeneralPopupBackground from "./components/popups";
import { useConnectWallet } from "./hooks/connectWallet";
import { useGetAllPools } from "./hooks/pools";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import MobileKYCPage from "./pages/dashboard/mobileKyc.jsx";
import { createAccessTokenForNewUser } from "./utils/helpers.js";
import { useGetSupportedCurrencies } from "./hooks/currencies.js";

const DashboardPage = lazy(() => import("./pages/dashboard"));
const OrdersPage = lazy(() => import("./pages/investments/orders"));
const PortfolioPage = lazy(() => import("./pages/investments/portfolio"));
const CreateSecondary = lazy(() =>
  import("./pages/investments/createSecondary")
);
const LoansPage = lazy(() => import("./pages/loans"));
const NewIssue = lazy(() => import("./pages/investments/newIssue"));
const CoporateActions = lazy(() =>
  import("./pages/investments/corporateActions")
);
const WatchlistPage = lazy(() => import("./pages/investments/watchlist"));
const MobileDashboardPage = lazy(() => import("./pages/dashboard/mobile"));
const MobileAssetPage = lazy(() => import("./pages/investments/mobile.jsx"));
const MobileProfilePage = lazy(() => import("./pages/profile/mobile.jsx"));
const MobileProfileDetailsPage = lazy(() =>
  import("./pages/profile/mobileProfileDetails.jsx")
);
const MobileProfileSettingsPage = lazy(() =>
  import("./pages/profile/mobileProfileSettings.jsx")
);
const MobileAddFundsPage = lazy(() =>
  import("./pages/payments/mobileAddFunds.jsx")
);
const MobileSelectCurrencies = lazy(() =>
  import("./pages/payments/mobileCurrency.jsx")
);
const MobileAssetInfoPage = lazy(() =>
  import("./pages/investments/mobileAssetInfo.jsx")
);

function App(props) {
  const [mobileScreen, setMobileScreen] = useState(
    window.innerWidth <= maximumScreenForMobile
  );
  const [isFTU, setIsFTU] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [customLoading, setCustomLoading] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [popupContentPosition, setPopupContentPosition] = useState("center");
  const [favouriteClicked, setFavouriteClicked] = useState(false);
  const [generalRefresh, setGeneralRefresh] = useState(false);
  const [allCountries, setAllCountries] = useState({});
  const [productsToSearch, setProductsToSearch] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState(
    props.currencySelected || {
      countryName: "",
      officialName: "",
      flag: null,
      currencySymbol: "",
      currencyName: "",
      officialCurrencySymbol: "",
      isClicked: false,
    }
  );
  const [productClicked, setProductClicked] = useState({});
  const [showPandL, setShowPandL] = useState(false);
  const [paraswapSupportedTokens, setParaswapSupportedTokens] = useState([]);
  const [verifiedSupportedTokens, setVerifiedSupportedTokens] = useState([]);

  const { connectWalletLoading, connectWalletError } =
    useConnectWallet(mobileScreen);

  const {
    getSupportedCurrenciesData,
    getSupportedCurrenciesLoading,
    getSupportedCurrenciesError,
  } = useGetSupportedCurrencies();

  const { chainId } = useSelector((state) => state.network);
  const { userAddress, userKycStatus, isConnected } = useSelector(
    (state) => state.user
  );

  if (connectWalletError) {
    console.log("error: ", connectWalletError);
  }

  const {
    getAllPoolsData,
    getAllPoolsChart,
    getAllPoolsLoading,
    getAllPoolsError,
  } = useGetAllPools(generalRefresh, userAddress, true);

  // console.log("data: ", getAllPoolsData);

  useEffect(() => {
    const resizeScreen = debounce(() => {
      setMobileScreen(window.innerWidth <= maximumScreenForMobile);
    }, 400); //4milliseconds

    //subcribe to resize
    window.addEventListener("resize", resizeScreen);

    //unsubscribe
    return () => {
      window.removeEventListener("resize", resizeScreen);
    };
  }, []);

  useEffect(() => {
    const createAccessToken = async () => {
      const accessToken = await createAccessTokenForNewUser(
        userAddress,
        chainId
      ).then((res) => res?.accessToken);
      setAccessToken(accessToken);
    };

    if (
      isConnected &&
      chainId &&
      userAddress.length > 0 &&
      Number(userKycStatus) !== 3
    ) {
      createAccessToken();
    }
  }, [userAddress, userKycStatus, isConnected, chainId]);

  useEffect(() => {
    const getAndFormatAllCountries = async () => {
      await axios({ method: "GET", url: allCountriesApi })
        .then((res) => {
          let allCountriesObj = {};
          res?.data
            // eslint-disable-next-line array-callback-return
            .map((cnt) => {
              if (cnt.currencies && Object.keys(cnt.currencies).length > 0) {
                const details = {
                  countryName: cnt?.name?.common,
                  officialName: cnt?.name?.official,
                  flag: cnt?.flags?.svg,
                  currencySymbol: Object.keys(cnt?.currencies)[0],
                  currencyName: Object.values(cnt?.currencies)[0].name,
                  officialCurrencySymbol: Object.values(cnt?.currencies)[0]
                    .symbol,
                  countryCode: cnt?.cca2,
                };
                allCountriesObj[cnt?.cca2] = details;
              }
            });

          setAllCountries(allCountriesObj);
        })
        .catch((err) => {
          console.error("get all countries failled will default countries...");
          let allCountriesObj = {};
          // eslint-disable-next-line array-callback-return
          countriesDetails.map((cnt) => {
            const details = {
              countryName: cnt?.name,
              officialName: cnt?.name,
              currencySymbol: cnt?.currency,
              currencyName: cnt?.currency,
              officialCurrencySymbol: cnt?.currency,
              countryCode: cnt?.code,
            };
            allCountriesObj[cnt?.code] = details;
          });
          setAllCountries(allCountriesObj);
        });
    };
    getAndFormatAllCountries();
  }, []);

  // useEffect(() => {
  //   const getParaswapSupportedTokens = async () => {
  //     await axios({ method: "GET", url: `${paraswapTokensBaseUrl}/${chainId}` })
  //       .then((res) => {
  //         const supportedParaswapTokens = res?.data?.tokens?.filter((tk) =>
  //           getSupportedCurrenciesData.find(
  //             (stk) =>
  //               stk?.tokenAddress?.toLowerCase() === tk?.address?.toLowerCase()
  //           )
  //         );
  //         setParaswapSupportedTokens(supportedParaswapTokens);
  //         // if (supportedParaswapTokens.length > 0) {
  //         //   const tokensNotOnParaswap = getSupportedCurrenciesData.filter(
  //         //     (tk) =>
  //         //       !supportedParaswapTokens.find(
  //         //         (spk) =>
  //         //           spk?.tokenAddress?.toLowerCase() ===
  //         //           tk?.address?.toLowerCase()
  //         //       )
  //         //   );
  //         //   console.log("nt: ", tokensNotOnParaswap);
  //         //   const tokensOnParaswap = supportedParaswapTokens.filter((tk) =>
  //         //     getSupportedCurrenciesData.find(
  //         //       (stk) =>
  //         //         stk?.tokenAddress?.toLowerCase() ===
  //         //         tk?.address?.toLowerCase()
  //         //     )
  //         //   );
  //         //   console.log("op: ", tokensOnParaswap);
  //         //   const updatedTokens = tokensOnParaswap.map((tp) => {
  //         //     console.log("tppppp: ", tp);
  //         //     return {
  //         //       tokenImage: tp.img,
  //         //       ...getSupportedCurrenciesData.find(
  //         //         (st) =>
  //         //           st?.tokenAddress?.toLowerCase() ===
  //         //           tp?.address?.toLowerCase()
  //         //       ),
  //         //     };
  //         //   });
  //         //   setVerifiedSupportedTokens(
  //         //     ...tokensNotOnParaswap,
  //         //     ...updatedTokens
  //         //   );
  //         // }
  //       })
  //       .catch((err) => {
  //         console.error(
  //           "getting paraswap tokens for chainId: ",
  //           chainId,
  //           "failed with error: ",
  //           err?.message
  //         );
  //       });
  //   };

  //   if (chainId > 0 && userAddress && getSupportedCurrenciesData?.length > 0) {
  //     getParaswapSupportedTokens();
  //   }
  // }, [chainId, getSupportedCurrenciesData, userAddress]);

  const generalDataProps = {
    connectWalletLoading,
    getAllPoolsData,
    getAllPoolsChart,
    getAllPoolsLoading,
    getAllPoolsError,
    generalRefresh,
    allCountries,
    paraswapSupportedTokens,
    setGeneralRefresh,
    setProductsToSearch,
    accessToken,
    setAccessToken,
    selectedCurrency,
    setSelectedCurrency,
    productClicked,
    setProductClicked,
    showPandL,
    setShowPandL,
  };

  return (
    <div className="App">
      <Router>
        {!mobileScreen && (
          <>
            {/* <div style={{ display: "none" }} id="chart-renderer"></div> */}
            <CommonHeader
              setShowPopup={setShowPopup}
              setPopupContent={setPopupContent}
              setPopupContentPosition={setPopupContentPosition}
              favouriteClicked={favouriteClicked}
              setFavouriteClicked={setFavouriteClicked}
            />
            {showPopup && (
              <GeneralPopupBackground
                popupContent={popupContent}
                popupContentPosition={popupContentPosition}
                customLoading={customLoading}
              />
            )}
            <LayoutBody
              setShowPopup={setShowPopup}
              setPopupContent={setPopupContent}
              setPopupContentPosition={setPopupContentPosition}
              favouriteClicked={favouriteClicked}
              setFavouriteClicked={setFavouriteClicked}
              productsToSearch={productsToSearch}
              generalDataProps={generalDataProps}
            >
              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route
                    exact
                    path="/"
                    element={
                      <DashboardPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        showPopup={showPopup}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/assets"
                    element={
                      <AssetsPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/dashboard"
                    element={
                      <DashboardPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        showPopup={showPopup}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/payments/add-funds"
                    element={
                      <DashboardPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        showPopup={showPopup}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/payments/send-funds"
                    element={
                      <DashboardPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        showPopup={showPopup}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/payments/withdraw-funds"
                    element={
                      <DashboardPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        showPopup={showPopup}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/payments/convert-funds"
                    element={
                      <DashboardPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        showPopup={showPopup}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/investments/orders"
                    element={
                      <OrdersPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/portfolio"
                    element={
                      <PortfolioPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        generalDataProps={generalDataProps}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/investments/watchlists"
                    element={
                      <WatchlistPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />

                  <Route
                    exact
                    path="/investments/assets/create-secondary"
                    element={
                      <CreateSecondary
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/portfolio/create-secondary"
                    element={
                      <CreateSecondary
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/assets/create-secondary"
                    element={
                      <CreateSecondary
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        customLoading={customLoading}
                        setCustomLoading={setCustomLoading}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />
                  {/* <Route
                  exact
                  path="/new-issue"
                  element={
                    <NewIssue
                      setPopupContent={setPopupContent}
                      setShowPopup={setShowPopup}
                      setPopupContentPosition={setPopupContentPosition}
                      setFavouriteClicked={setFavouriteClicked}
                    />
                  }
                /> */}
                  <Route
                    exact
                    path="/dashboard/new-issue"
                    element={
                      <NewIssue
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        generalDataProps={generalDataProps}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/portfolio/new-issue"
                    element={
                      <NewIssue
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        generalDataProps={generalDataProps}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/portfolio/corporate-actions"
                    element={
                      <CoporateActions
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        setCustomLoading={setCustomLoading}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/investments/assets/new-issue"
                    element={
                      <NewIssue
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        generalDataProps={generalDataProps}
                        setCustomLoading={setCustomLoading}
                      />
                    }
                  />
                  <Route
                    exact
                    path="/loans"
                    element={
                      <LoansPage
                        setPopupContent={setPopupContent}
                        setShowPopup={setShowPopup}
                        setPopupContentPosition={setPopupContentPosition}
                        setFavouriteClicked={setFavouriteClicked}
                        setCustomLoading={setCustomLoading}
                        generalDataProps={generalDataProps}
                      />
                    }
                  />
                </Routes>
              </Suspense>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                pauseOnHover
                draggable
                closeOnClick
                closeButton={false}
              />
            </LayoutBody>
          </>
        )}
        {mobileScreen && (
          <>
            {!isFTU && <CommonHeader mobileScreen={mobileScreen} />}
            {showPopup && (
              <GeneralPopupBackground
                popupContent={popupContent}
                popupContentPosition={popupContentPosition}
                customLoading={customLoading}
              />
            )}
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <MobileDashboardPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <MobileDashboardPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route exact path="/ftu" element={<StartUpPage />} />
                <Route
                  exact
                  path="/ftu/language"
                  element={<StartupLanguagePage />}
                />
                <Route
                  exact
                  path="/ftu/contact"
                  element={<StartupContactPage />}
                />
                <Route exact path="/ftu/otp" element={<StartupOtpPage />} />

                <Route
                  exact
                  path="/dashboard/verify-kyc"
                  element={
                    <MobileKYCPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/verify-kyc"
                  element={
                    <MobileKYCPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route exact path="/payments" />
                <Route
                  exact
                  path="/investments"
                  element={
                    <MobileAssetPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/profile"
                  element={
                    <MobileProfilePage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/profile-details"
                  element={
                    <MobileProfileDetailsPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/profile-settings"
                  element={
                    <MobileProfileSettingsPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/add-funds"
                  element={
                    <MobileAddFundsPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/add-funds/currencies"
                  element={
                    <MobileSelectCurrencies
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/asset-info"
                  element={
                    <MobileAssetInfoPage
                      mobileScreen={mobileScreen}
                      generalDataProps={generalDataProps}
                      showPopup={showPopup}
                      setShowPopup={setShowPopup}
                      customLoading={customLoading}
                      setCustomLoading={setCustomLoading}
                    />
                  }
                />
                <Route
                  exact
                  path="/loans"
                  element={<MobileDashboardPage mobileScreen={mobileScreen} />}
                />
              </Routes>
            </Suspense>
            <div style={{ height: "50px" }}></div>
            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar
              pauseOnHover
              draggable
              closeOnClick
              closeButton={false}
            />

            {!isFTU && <CommonFooter />}
          </>
        )}
      </Router>
    </div>
  );
}

export default App;
