import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileSearchBox from "../../components/overlays/mobileSearchBox";
import { allCountriesApi } from "../../utils/constants";
import StartupSlider from "../../components/overlays/startup-slider";
import unitedStatesFlag from "../../assests/page/Flag-United-States-of-America 1.svg";
import dropdownIcon from "../../assests/page/contact-dropdown.svg";
import arrowUp from "../../assests/page/contact-arrow-up.svg";
import {
  PrivacyOverlay,
  TermAndConditionOverlay,
} from "../../components/overlays/utils";
import axios from "axios";

const StartupContactPage = () => {
  const navigate = useNavigate();
  const [selectedCountry, setSellectedCountry] = useState({
    area: "+1",
    flag: unitedStatesFlag,
  });
  const [userNumber, setUserNumber] = useState("");
  const [userInput, setUserInput] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const [allCountries, setAllCountries] = useState([]);

  useEffect(() => {
    (async () => {
      const getAllcountries = async () => {
        await axios({ method: "GET", url: allCountriesApi })
          .then((res) => {
            setAllCountries(res.data);
          })
          .catch((err) => {
            console.error("get all countries failled with error: ", err);
            //Todo: toast here
          });
      };

      await getAllcountries();
    })();
  }, []);

  const handleButtonDisplay = () => {
    const button = document.getElementById("language-button");
    if (
      selectedCountry.area &&
      userInput.length === 10 &&
      userNumber.length === 10
    ) {
      button.className = "startup-button-active";
    } else {
      button.className = "startup-button";
    }
  };

  return (
    <div className="startup-language">
      <div className="startup-contact">
        <StartupSlider sliderCount={[1, 2, 3]} currentSlider="0" />
        <div className="startup-contact-content">
          <p className="startup-contact-text">Enter your phone number</p>
          <div className="st-contact-input">
            <div className="st-contact-content-container">
              <div
                onClick={() => {
                  setSearchActive(!searchActive);
                }}
                className="st-contact-first-section"
              >
                <img
                  onClick={() => {
                    setSearchActive(!searchActive);
                  }}
                  alt="Country Flag"
                  className="st-contact-selection-icon"
                  src={selectedCountry.flag}
                />
                <p
                  onClick={() => {
                    setSearchActive(!searchActive);
                  }}
                  className="st-contact-selection-text"
                >
                  {selectedCountry.area}
                </p>
                {searchActive && (
                  <img
                    onClick={() => {
                      setSearchActive(!searchActive);
                    }}
                    alt="Dropdown Icon"
                    className="st-contact-dropdown"
                    src={arrowUp}
                  />
                )}
                {!searchActive && (
                  <img
                    onClick={() => {
                      setSearchActive(!searchActive);
                    }}
                    alt="Dropdown Icon"
                    className="st-contact-dropdown"
                    src={dropdownIcon}
                  />
                )}
              </div>

              <input
                onInputCapture={(e) => {
                  if (e.target.value === "") {
                    setUserInput(userInput + " ");
                  } else {
                    setUserInput(e.target.value);
                  }
                  const withoutDot = e.target.value.replace(".", "");
                  const withoutDotAndDash = withoutDot.replace("-", "");
                  setUserNumber(withoutDotAndDash);
                }}
                onInput={(e) => {
                  // if (e.target.value === "") {
                  //   setUserInput(userInput + " ");
                  // } else {
                  //   setUserInput(e.target.value);
                  // }
                  // const withoutDot = e.target.value.replace(".", "");
                  // const withoutDotAndDash = withoutDot.replace("-", "");
                  // setUserNumber(withoutDotAndDash);
                  handleButtonDisplay();
                }}
                type="number"
                className="st-contact-second-section"
                placeholder="Enter your phone number"
              />
            </div>
            {searchActive && (
              <MobileSearchBox
                pageName="ftu/contact"
                searchContent={allCountries}
                type="area-code"
                handleButtonDisplay={handleButtonDisplay}
                setSelected={setSellectedCountry}
                selected={selectedCountry}
                searchActive={searchActive}
                setSearchActive={setSearchActive}
                contentMaxHeight="300"
                contentbottomMargin="300"
              />
            )}
            {userNumber.length !== 10 && userInput.length > 10 && (
              <div className="st-contact-input-error">
                <p className="input-error-text">
                  Invalid input, please check you phone number
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {!searchActive && <div className="custom-blank-page"></div>}

      <div className="startup-contact-footer">
        <div className="st-contact-footer-first-section">
          <TermAndConditionOverlay />
          <button
            id="language-button"
            onClick={(e) => {
              if (
                e.target.className.includes("startup-button") &&
                e.target.className !== "startup-button"
              ) {
                e.target.className = "startup-button-click";
                navigate("/ftu/otp");
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                  navigate("/ftu/otp");
                }
              }
            }}
            className="startup-button"
          >
            <p className="st-button-text">Get OTP</p>
          </button>
          <div className="st-contact-footer-second-section">
            <PrivacyOverlay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupContactPage;
