import { sendSubgraphQuery } from ".";
import { chainDetails } from "../../utils/config";

const generalPoolsField = `id
            address
            poolType
            isPaused
            swapFee
            tokensList
            totalLiquidity
            totalSwapVolume
            totalSwapFee
            totalShares
            security
            securityType
            currency
            collateral
            cficode
            margin
            securityOffered
            balancerManager
            minOrderSize
            owner
            factory
            cutoffTime
            offeringDocs
            amp
            createTime
            minimumOrderSize
            minimumPrice
            swapEnabled
            orderBook
            secondaryTrades{
                amount
                price
                executionDate
                orderType
                orderReference
                counterparty {
                    id
                }
                party {
                    id
                }
            }
            secondaryPreTrades {
                id
                pool {
                    id
                }
                party {
                    id
                }
                counterparty {
                    id
                }
                executionDate
            }
            tokens {
                assetManager
                symbol
                name
                decimals
                index
                address
                oldPriceRate
                priceRate
                balance
                paidProtocolFees
                cashBalance
                managedBalance
            }
            orders {
                id
                pool {
                  id
                  address
                  security
                  currency
                  tokens {
                    symbol
                    name
                    decimals
                    index
                    address
                  }
                  tokensList
                }
                tokenIn {
                    address
                }
                tokenOut {
                    address
                }
                amountOffered
                priceOffered
                orderReference
                creator
                timestamp
            } 
            primarySubscriptions{
                subscription
                price
                executionDate
                investor {
                    id
                }
                assetIn{
                  address
                }
                assetOut{
                  address
                }
              executionDate
            }
            marginOrders {
                id
                pool {
                  id
                  address
                  security
                  currency
                  margin
                  tokensList
                  tokens {
                    symbol
                    name
                    decimals
                    index
                    address
                  }
                }
                creator
                tokenIn {
                    id
                    symbol
                    name
                    decimals
                    address
                }
                tokenOut {
                    id
                    symbol
                    name
                    decimals
                    address
                }
                amountOffered
                priceOffered
                stoplossPrice
                timestamp
                orderReference
}`;

/**
 *
 * @param {number} chainId
 * @returns {string} latest vault subgraph url
 */
export const getVaultSubgraphUrl = (chainId) => {
  return chainDetails[chainId].vaultSubgraphUrl;
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} all pools
 */
export const fetchAllPools = async (chainId) => {
  const query = `query {
    pools: pools( 
      orderBy: createTime
      orderDirection: desc
      where: {
        poolType_in: ["PrimaryIssue", "SecondaryIssue", "MarginIssue"]
      }
    ) {
       ${generalPoolsField} 
    }
  }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} primary pools
 */
export const fetchPrimaryPools = async (chainId) => {
  const query = `query {
        pools: pools( 
          orderBy: createTime
          orderDirection: desc
          where: {
            poolType: "PrimaryIssue"
          }
        ) {
           ${generalPoolsField} 
        }
      }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} poolAddress
 * @returns {promise<any[]>} primary pools with the address
 */
export const fetchPrimaryPoolByAddress = async (chainId, poolAddress) => {
  const query = `query {
          pools: pools( 
            where: {
              poolType: "PrimaryIssue"
              address: "${poolAddress.toLowerCase()}"
            }
          ) {
             ${generalPoolsField} 
          }
        }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} secondary pools
 */
export const fetchSecondaryPools = async (chainId) => {
  const query = `query {
          pools: pools( 
            orderBy: createTime
            orderDirection: desc
            where: {
              poolType: "SecondaryIssue"
            }
          ) {
             ${generalPoolsField} 
          }
        }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} poolAddress
 * @returns {promise<any[]>} secondary pools with the address
 */
export const fetchSecondaryPoolByAddress = async (chainId, poolAddress) => {
  const query = `query {
            pools: pools( 
              where: {
                poolType: "SecondaryIssue"
                address: "${poolAddress.toLowerCase()}"
              }
            ) {
               ${generalPoolsField} 
            }
          }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} margin pools
 */
export const fetchMarginPools = async (chainId) => {
  const query = `query {
            pools: pools( 
              orderBy: createTime
              orderDirection: desc
              where: {
                poolType: "MarginIssue"
              }
            ) {
               ${generalPoolsField} 
            }
          }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} poolAddress
 * @returns {promise<any[]>} margin pools with the address
 */
export const fetchMarginPoolByAddress = async (chainId, poolAddress) => {
  const query = `query {
              pools: pools( 
                where: {
                  poolType: "MarginIssue"
                  address: "${poolAddress.toLowerCase()}"
                }
              ) {
                 ${generalPoolsField} 
              }
            }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string[]} acceptedPoolTypesArray string formatted array of poolType e.g ["PrimaryIssue", "SecondaryIssue"]
 * @returns {promise<any[]>} all pools
 */
export const fetchCustomPools = async (chainId, acceptedPoolTypesArray) => {
  const query = `query {
    pools: pools( 
      orderBy: createTime
      orderDirection: desc
      where: {
        poolType_in: ${acceptedPoolTypesArray}
      }
    ) {
       ${generalPoolsField} 
    }
  }`;
  return await sendSubgraphQuery(
    getVaultSubgraphUrl(chainId),
    query,
    "pools",
    []
  );
};
