import { useEffect, useState } from "react";
import verifiedLogo from "../../assests/page/startup-logo.svg";
import startupIcon from "../../assests/page/startup-coin-icon.svg";
import startupIconShadow from "../../assests/page/startup-icon-shadow.svg";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";

const StartUpPage = () => {
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    const handleLogoDisplay = debounce(() => {
      setShowLogo(false);
    }, 2000); //2 seconds??
    handleLogoDisplay();
  }, []);

  const navigate = useNavigate();

  return (
    <div className="startup">
      {showLogo && (
        <img className="startup-logo" alt="Verified Logo" src={verifiedLogo} />
      )}
      {!showLogo && (
        <>
          <div className="startup-icon-container">
            <img
              className="startup-icon"
              alt="Startup Icon"
              src={startupIcon}
            />
            <img
              className="startup-icon-shadow"
              alt="Startup Icon Shadow"
              src={startupIconShadow}
            />
          </div>
          <div className="startup-text-container">
            <p className="startup-text-header">
              Invest Smarter, Start Trading Today
            </p>
            <p className="startup-text-footer">
              Build your portfolio with confidence. Learn, invest, and track
              your progress.
            </p>
          </div>
          <button
            onClick={(e) => {
              if (e.target.className.includes("startup-button")) {
                e.target.className = "startup-button-click";
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                }
              }
              navigate("/ftu/language");
            }}
            style={{ width: "auto" }}
            className="startup-button-active"
          >
            <p className="st-button-text">Get Started</p>
          </button>
        </>
      )}
    </div>
  );
};

export default StartUpPage;
