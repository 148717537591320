import axios from "axios";

export const sendSubgraphQuery = async (
  subgraphUrl,
  query,
  queryEntity,
  defaultReturn
) => {
  return await axios({
    method: "POST",
    url: subgraphUrl,
    data: {
      query: query,
    },
  })
    .then((res) => {
      return res?.data?.data[queryEntity] || defaultReturn;
    })
    .catch(async (err) => {
      if (err.response && err.response.status === 429) {
        console.log(
          `sendSubgraphQuery for entity: "${queryEntity}" on subgraph url: ${subgraphUrl} hit rate limit: ${err}`
        );
      } else {
        console.error(
          `sendSubgraphQuery for entity: "${queryEntity}" on subgraph url: ${subgraphUrl} failed with error: ${err}`
        );
      }
      return defaultReturn;
    });
};
