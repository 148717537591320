import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StartupSlider from "../../components/overlays/startup-slider";
import unitedStatesFlag from "../../assests/page/Flag-United-States-of-America 1.svg";
import editIcon from "../../assests/page/edit.svg";
import {
  PrivacyOverlay,
  TermAndConditionOverlay,
} from "../../components/overlays/utils";

const StartupOtpPage = () => {
  const navigate = useNavigate();

  const [searchActive, setSearchActive] = useState(false);
  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    if (countdown === 0) return;

    const reduceCountdown = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(reduceCountdown);
  }, [countdown]);

  return (
    <div className="startup-language">
      <div className="startup-contact">
        <StartupSlider sliderCount={[1, 2, 3]} currentSlider="1" />
        <div className="startup-contact-content">
          <p className="startup-contact-text">Enter OTP</p>
          <div className="startup-otp-text">
            <p className="startup-otp-header-text">
              Enter <span style={{ color: "#0C0C0E" }}>4-Digit OTP</span> (One
              Time Password) sent to your phone number
            </p>
            <div className="startup-otp-footer">
              <p className="startup-otp-footer-text">
                <span>(+91)</span> <span> 96543</span> <span> 20985</span>
              </p>
              <img src={editIcon} alt="edit-icon" className="st-otp-icon" />
            </div>
          </div>
        </div>
        <div className="otp-input-container">
          <div className="otp-input-group">
            <input className="otp-input" type="text" maxLength={1} />
            <input className="otp-input" type="number" maxLength={1} />
            <input className="otp-input" type="number" maxLength={1} />
            <input className="otp-input" type="number" maxLength={1} />
          </div>
          <div className="otp-timer">
            {countdown > 5 && (
              <p className="otp-timer-text">
                <span style={{ color: "#717286", fontWeight: 400 }}>00:</span>
                {countdown}
              </p>
            )}
            {countdown <= 5 && (
              <p className="otp-timer-text-red">
                <span style={{ fontWeight: 400 }}>00:</span>
                {countdown}
              </p>
            )}
            {countdown > 0 && <p className="otp-resend-text">Resend OTP</p>}
            {countdown === 0 && (
              <p
                onClick={() => {
                  //clear input content
                  const inputs = document.getElementsByClassName("otp-input");
                  for (let i = 0; i < inputs.length; i++) {
                    inputs[i].value = "";
                  }
                  setCountdown(30); //reset countdown
                }}
                className="otp-resend-text-active"
              >
                Resend OTP
              </p>
            )}
          </div>
        </div>
      </div>

      {!searchActive && (
        <div
          style={{ marginBottom: "80px" }}
          className="custom-blank-page"
        ></div>
      )}

      <div className="startup-contact-footer">
        <div className="st-contact-footer-first-section">
          <button
            id="otp-button"
            onClick={(e) => {
              if (
                e.target.className.includes("startup-button") &&
                e.target.className !== "startup-button"
              ) {
                e.target.className = "startup-button-click";
                navigate("/ftu/otp");
              } else {
                const buttonElement = document.getElementsByClassName(
                  "startup-button-active"
                );
                if (buttonElement.length > 0) {
                  buttonElement[0].className = "startup-button-click";
                  navigate("/ftu/otp");
                }
              }
            }}
            className="startup-button"
          >
            <p className="st-button-text">Proceed</p>
          </button>
          <div className="st-contact-footer-second-section">
            <PrivacyOverlay />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupOtpPage;
