import { sendSubgraphQuery } from ".";
import { chainDetails } from "../../utils/config";

const generalSecurityField = `security
  isin
  id
  issuer {
    id
    name
  }
  currency
  country
  restricted
  restrictions
  issueManager
  productCategory
  liquidityOffered {
    offered
    offeredBy {
      id
    }
    tomatch {
      id
    }
    isin
    amount
    desired
    minimum
    orderSize
    offeringDocs
  }
  liquidityProviders {
    owner {
      id
    }
    tokenOffered
    security {
      id
    }
    currency
    underwritten
    earned
  }
  primarySubscribers {
    id
    pool
    currency
    security {
      id
    }
    cashSwapped
    investor {
      id
    }
    securitySwapped
    timestamp
    bought
  }
  secondaryInvestors {
    id
    currency
    security {
      id
    }
    amount
    investor {
      id
    }
    issuer {
      id
    }
    price
    timestamp
    tradeRef
    DPID
  }
  trades {
    poolid
    transferor {
      id
    }
    transferee {
      id
    }
    timestamp
    unitsToTransfer
    amountPaid
    price
    settlementStatus
    tradingCommission
    tradeRef
  }
  subscriptionsClosed {
    timestamp
  }
  primarySettlements {
    liquidityProvider {
      id
    }
    underwritingFee
    issuer {
      id
    }
    subscription
    currency
  }
  marginTraders{
    id
    security {
      id
      security
    }
    securityTraded
    currency
    cashTraded
    orderRef
    timestamp
  }
  primaryAllotments{
    investor {
      id
    }
    currency
    security {
      id
    }
    allotedAmount
    securitySubscribed
    timestamp
  }
  subscriptionsClosed {
    timestamp
  }
  primaryRefunds {
    investor {
      id
    }
    securitySubscribed
    refundAmount
    currency
    timestamp
  }
  resolutions{
    security{
      id
    }
    recordDate
    resolution
    voting
  }
  snapshots{
    security {
      id
    }
    oldTime
    newTime
}
`;

const generalCurrencyField = `token
    name
    liquidityTokenRequests {
    investor {
    id
    name
    country
    }
    token {
    id
    name
    }
    tokenAmount
    }
    liquidityTokenIssues {
    investor {
    id
    name
    country
    }
    token {
    id
    name
    }
    tokenAmount
    LPToIssue
    }
    liquidityOnPlatform {
    platform {
    id
    name
    }
    manager {  
    id
    name
    country
    }
    LPToken
    token {
    id
    name
    }
    tokenAmount
    }
    liquidityEarningsByManager {
    platform {
    id
    }
    manager {
    id
    }
    token {
    id
    }
    distribution
}
`;

const generalUserField = `id
    client
    name
    accountid
    country
    status
    shareholder{
        shareholder {
        id
        }
        shareholderType
        token
        amount
        timestamp
    }
    earnedByInvestor {
        vitta
        platform {
        id
        name
        }
        prorataStake
    }
    tradeTransferor {
        orderRef
        security{
        id
        security
        }
        securityTraded
        currency
        cashTraded
    }
    tradeTransferee {
        orderRef
        security{
        id
        security
        }
        securityTraded
        currency
        cashTraded
    }
    securitySeller {
        id
        poolid
        unitsToTransfer
        security{
        id
        security
        }
        price
        currency
        settlementStatus
        amountPaid
        tradeRef
        orderRef
        tradingCommission
        timestamp
    }
    securityBuyer {
        id
        poolid
        unitsToTransfer
        security{
        id
        security
        }
        price
        currency
        settlementStatus
        amountPaid
        tradeRef
        orderRef
        tradingCommission
        timestamp
    }
    primarySubscribers {
    id
    pool
    currency
    security {
      id
    }
    cashSwapped
    investor {
      id
    }
    securitySwapped
    timestamp
    bought
  }
  allotedTo{
    investor {
      id
    }
    currency
    security {
      id
    }
    allotedAmount
    securitySubscribed
    timestamp
  }
  refundedTo {
    investor {
      id
    }
    currency
    security {
      id
    }
    securitySubscribed
    refundAmount
    timestamp
  }
  issuerSettled{
   liquidityProvider {
      id
    }
    underwritingFee
    issuer {
      id
    }
    subscription
    currency
  }
`;

const basicUserField = `id
    client
    name
    accountid
    country
    status`;

/**
 *
 * @param {number} chainId
 * @returns {string} latest wallet subgraph url
 */
export const getWalletSubgraphUrl = (chainId) => {
  return chainDetails[chainId].walletSubgraphUrl;
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} all securities
 */
export const fetchAllSecurities = async (chainId) => {
  const query = `query{
  securities{
   ${generalSecurityField}
  }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "securities",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} securityAddress
 * @returns {promise<any[]>} fetch security by address
 */
export const fetchSecurityByAddress = async (chainId, securityAddress) => {
  const query = `query{
    securities: securities(
        where: {
        security: "${securityAddress.toLowerCase()}"
        }
    ) {
        ${generalSecurityField}
    }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "securities",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} all currencies
 */
export const fetchAllCurrencies = async (chainId) => {
  const query = `query{
    currencies{
        ${generalCurrencyField}
    }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "currencies",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} securityAddress
 * @returns {promise<any[]>} fetch currency by address
 */
export const fetchCurrencyByAddress = async (chainId, securityAddress) => {
  const query = `query{
    currencies: currencies(where: {
    token: "${securityAddress.toLowerCase()}"
    }) {
        ${generalCurrencyField}
    }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "currencies",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} all margin collaterals
 */
export const fetchMarginCollaterals = async (chainId) => {
  const query = `query{
      marginCollaterals: marginCollaterals(
        orderBy: time
        orderDirection: desc
      ) {
        id
        action
        user {
        id
        name
        accountid
        country
        status
        }
        currency
        balance
        time
      }
    }`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "marginCollaterals",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} userAddress
 * @returns {promise<any[]>} user's margin collaterals
 */
export const fetchUserMarginCollaterals = async (chainId, userAddress) => {
  const query = `query{
    marginCollaterals: marginCollaterals(
      orderBy: time
      orderDirection: desc
      where: {
        user_: {
          id: "${userAddress.toLowerCase()}"
        }
      }
    ) {
      id
      action
      user {
      id
      name
      accountid
      country
      status
      }
      currency
      balance
      time
    }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "marginCollaterals",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} all margin P&L
 */
export const fetchMarginProfitAndLoss = async (chainId) => {
  const query = `query{
      marginTradePnLs: marginTradePnLs(
        orderBy: settlementTime
        orderDirection: desc
      ) {
      id
      user {
        id
        name
        accountid
        country
        status
      }
      security {
        id
      }
      currency
      settlementTime
      commission
      financing
      dividend
    }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "marginTradePnLs",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} userAddress
 * @returns {promise<any[]>} user's margin P&L
 */
export const fetchUserMarginProfitAndLoss = async (chainId, userAddress) => {
  const query = `query{
      marginTradePnLs: marginTradePnLs(
        orderBy: settlementTime
        orderDirection: desc
        where: {
          user_: {
            id: "${userAddress.toLocaleLowerCase()}"
          }
        }
      ) {
      id
      user {
        id
        name
        accountid
        country
        status
      }
      security {
        id
      }
      currency
      settlementTime
      commission
      financing
      dividend
    }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "marginTradePnLs",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} all users
 */
export const fetchAllUsers = async (chainId) => {
  const query = `query{
    users{
        ${generalUserField}
    }
 }`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "users",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string} userAddress
 * @returns {promise<any[]>} fetch user by address
 */
export const fetchUserByAddress = async (chainId, userAddress) => {
  const query = `query{
   users: users(
    where: {
      id: "${userAddress.toLowerCase()}"
    }
  ) {
	${generalUserField}
  }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "users",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @param {string[]} userAddresses (all userAddress in the array must be in lowercase)
 * @returns {promise<any[]>} fetch users by their addresses
 */
export const fetchUsersByAddresses = async (chainId, userAddresses) => {
  const query = `query{
   users: users(
    where: {
      id_in: "${userAddresses}"
    }
  ) {
	${basicUserField}
  }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "users",
    []
  );
};

/**
 *
 * @param {number} chainId
 * @returns {promise<any[]>} fetch all managers with DP and Custodian roles
 */
export const fetchAllDpAndCustodianManagers = async (chainId) => {
  const query = `query{
   managers: managers(
   where: {
      role_in: ["0x4450000000000000000000000000000000000000000000000000000000000000", "0x437573746f6469616e0000000000000000000000000000000000000000000000"]
    }
  ) {
	 id
    manager{
      id 
      name
    }
    submanager{
      id
      name
      status
    }
    country
    role
  }
}`;
  return await sendSubgraphQuery(
    getWalletSubgraphUrl(chainId),
    query,
    "managers",
    []
  );
};
