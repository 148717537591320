const GeneralOverlay = (props) => {
  return (
    <div
      style={
        props.generalOverlayBackground
          ? { background: props.generalOverlayBackground }
          : {}
      }
      className="general-overlay"
    >
      {props.children}
    </div>
  );
};

export default GeneralOverlay;
