import { useState } from "react";
import { useNavigate } from "react-router-dom";
import MobileSearchBox from "../../components/overlays/mobileSearchBox";
import { Languages } from "../../utils/constants";

const StartupLanguagePage = () => {
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const handleButtonDisplay = () => {
    const button = document.getElementById("language-button");
    if (selectedLanguage.length > 0) {
      button.className = "startup-button-active";
    } else {
      button.className = "startup-button";
    }
  };
  return (
    <div className="startup-language">
      <MobileSearchBox
        pageName="ftu/language"
        searchContent={Languages}
        type="language"
        headerText="Please choose the language"
        handleButtonDisplay={handleButtonDisplay}
        setSelected={setSelectedLanguage}
        selected={selectedLanguage}
      />

      <button
        id="language-button"
        onClick={(e) => {
          if (
            e.target.className.includes("startup-button") &&
            e.target.className !== "startup-button"
          ) {
            e.target.className = "startup-button-click";
            navigate("/ftu/contact");
          } else {
            const buttonElement = document.getElementsByClassName(
              "startup-button-active"
            );
            if (buttonElement.length > 0) {
              buttonElement[0].className = "startup-button-click";
              navigate("/ftu/contact");
            }
          }
        }}
        className="startup-button"
        style={{ marginBottom: "20px" }}
      >
        <p className="st-button-text">Next</p>
      </button>
    </div>
  );
};

export default StartupLanguagePage;
