import { useAccount, useConnectorClient } from "wagmi";
import {
  getAddressETHBalance,
  getProvider,
  getProviderNetwork,
  getWeb3,
} from "../services/contracts";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import {
  getUserKYCDetails,
  getUserRole,
} from "../services/contracts/clientContract";
import { clearUser, updateUser } from "../store/userSlice";
import { clearNetwork, updateNetwork } from "../store/networkSlice";

export const useConnectWallet = (isMobileScreen) => {
  const { address, chainId, isConnected } = useAccount();
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const connectAndFetchUserDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        //step 1: clear user and network slice if wallet is disconnected. to track wallet disconnection
        if (!isConnected) {
          dispatch(clearUser());
          dispatch(clearNetwork());
        }

        //step 2: get required network and user details from wagmi;
        let network, provider;
        if (wagmiClient.chain) {
          network = getProviderNetwork(wagmiClient.chain);
        }
        if (wagmiClient.transport && network) {
          provider = getProvider(wagmiClient.transport, network);
        }
        let signer, web3;
        if (provider && provider.getSigner && chainId && address) {
          signer = provider.getSigner(address);
          web3 = getWeb3(wagmiClient.transport);
        }

        //step 3: update network and user slice on store and get more userDetails
        if (chainId && address && provider && signer && web3) {
          dispatch(updateUser({ name: "userAddress", value: address }));
          dispatch(updateUser({ name: "isConnected", value: isConnected }));
          dispatch(updateNetwork({ name: "chainId", value: chainId }));
          dispatch(
            updateNetwork({ name: "name", value: wagmiClient.chain.name })
          );
          dispatch(updateNetwork({ name: "provider", value: provider }));
          //TODO: Update Verified Sdk to get rid of web3?? or move web3 to context or props?? to fix redux non serialized warning
          dispatch(updateNetwork({ name: "web3", value: web3 }));
          const userRole = await getUserRole(chainId, address, signer);
          if (userRole.length > 0) {
            dispatch(updateUser({ name: "userRole", value: userRole }));
          }
          const nativeTokenBalance = await getAddressETHBalance(web3, address);
          dispatch(
            updateUser({
              name: "userBalance",
              value: nativeTokenBalance,
            })
          );
          const userKycDetails = await getUserKYCDetails(
            chainId,
            address,
            signer
          );
          if (userKycDetails) {
            Object.keys(userKycDetails).forEach((key) => {
              dispatch(
                updateUser({
                  name: key,
                  value: userKycDetails[key],
                })
              );
            });
          }
        }
      } catch (err) {
        // console.error(
        //   "Error while connecting and fetching user details: ",
        //   err
        // );
        setError(
          err.message || "Error while fetching user deatils. Try again later."
        );
      } finally {
        setLoading(false);
      }
    };

    connectAndFetchUserDetails();
  }, [isMobileScreen, address, isConnected, chainId, wagmiClient, dispatch]);

  return { connectWalletLoading: loading, connectWalletError: error };
};
