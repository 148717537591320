import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./userSlice";
import networkReducer from "./networkSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    network: networkReducer,
  },
});

export default store;
