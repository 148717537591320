import { useState } from "react";
import searchIcon from "../../assests/sideBar/search-icon.svg";
import checkCircleIcon from "../../assests/page/check-circle-icon.svg";
import { useNavigate } from "react-router-dom";

const MobileSearchBox = (props) => {
  const [newSearchContent, setNewSearchContent] = useState(null);
  const navigate = useNavigate();
  return (
    <div className="startup-language-content-container">
      <div className="st-language-content">
        {props.headerText && (
          <p className="st-language-text">{props.headerText}</p>
        )}
        <div className="mobille-startup-search-box-container">
          <div className="mobile-startup-search-box">
            <img
              src={searchIcon}
              alt="Search Icon"
              className="mobile-search-icon"
            />
            <input
              onInputCapture={() => {
                if (props.type === "language") {
                  props.setSelected("");
                }
              }}
              onInput={(e) => {
                if (e.target.value === "") {
                  setNewSearchContent(null);
                } else {
                  if (props.type === "language") {
                    setNewSearchContent(
                      // eslint-disable-next-line array-callback-return
                      props.searchContent.filter((src) => {
                        if (
                          src.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        ) {
                          src.searched = e.target.value.toLowerCase();
                          src.nameArray = src.name.split("");
                          return src;
                        }
                      })
                    );
                  } else if (props.type === "area-code") {
                    setNewSearchContent(
                      // eslint-disable-next-line array-callback-return
                      props.searchContent.filter((src) => {
                        if (
                          src.name.official
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase())
                        ) {
                          src.searched = e.target.value.toLowerCase();
                          src.nameArray = src.name.official.split("");
                          return src;
                        }
                      })
                    );
                  }
                }

                props.handleButtonDisplay();
              }}
              type="text"
              className="ms-search-box-content"
              placeholder="Search"
            />
          </div>
          <div className="mobile-startup-search-content">
            <div
              style={
                props.contentMaxHeight
                  ? { maxHeight: `${props.contentMaxHeight}px` }
                  : {}
              }
              className="ms-search-content-container"
            >
              {!newSearchContent &&
                props?.searchContent?.length > 0 &&
                props.searchContent.map((src, idx) => (
                  <>
                    {props.type === "area-code" && (
                      <div
                        key={idx}
                        onClickCapture={() => {
                          props.setSelected({
                            area:
                              src.idd.suffixes.length > 1
                                ? src.idd.root
                                : src.idd.root + src.idd.suffixes[0],
                            flag: src.flags.svg,
                            name: src.name.official,
                          });
                        }}
                        onClick={() => {
                          navigate(
                            `/${props.pageName
                              ?.toLowerCase()
                              .replace("-", "")}?${idx}`
                          );
                          props.setSelected({
                            area:
                              src.idd.suffixes.length > 1
                                ? src.idd.root
                                : src.idd.root + src.idd.suffixes[0],
                            flag: src.flags.svg,
                            name: src.name.official,
                          });
                          if (props.setSearchActive) {
                            props.setSearchActive(!props.setSearchActive);
                          }
                          props.handleButtonDisplay();
                        }}
                        className="ms-search-content"
                      >
                        <img
                          src={src.flags.svg}
                          alt="Search Result Icon"
                          className="ms-search-content-icon"
                        />
                        <p className="ms-search-content-text">
                          {src.name.official}
                        </p>
                      </div>
                    )}
                    {props.type === "language" && (
                      <>
                        {window.location.search !== `?${idx}` && (
                          <div
                            key={idx}
                            onClickCapture={() => {
                              props.setSelected(src.name);
                            }}
                            onClick={() => {
                              navigate(
                                `/${props.pageName
                                  ?.toLowerCase()
                                  .replace("-", "")}?${idx}`
                              );
                              props.setSelected(src.name);
                              if (props.setSearchActive) {
                                props.setSearchActive(!props.setSearchActive);
                              }
                              props.handleButtonDisplay();
                            }}
                            className="ms-search-content"
                          >
                            <img
                              src={src.icon}
                              alt="Search Result Icon"
                              className="ms-search-content-icon"
                            />
                            {src.label && (
                              <p className="ms-search-content-text">
                                {src.name} {`(${src.label})`}
                              </p>
                            )}
                            {!src.label && (
                              <p className="ms-search-content-text">
                                {src.name}
                              </p>
                            )}
                          </div>
                        )}
                        {window.location.search === `?${idx}` && (
                          <div
                            key={idx}
                            onClickCapture={() => {
                              props.setSelected(src.name);
                            }}
                            onClick={() => {
                              navigate(
                                `/${props.pageName
                                  ?.toLowerCase()
                                  .replace("-", "")}?${idx}`
                              );
                              props.setSelected(src.name);
                              if (props.setSearchActive) {
                                props.setSearchActive(!props.setSearchActive);
                              }
                              props.handleButtonDisplay();
                            }}
                            className="ms-search-content-active"
                          >
                            <div className="ms-search-content-text-container">
                              <img
                                src={src.icon}
                                alt="Search Result Icon"
                                className="ms-search-content-icon"
                              />
                              {src.label && (
                                <p className="ms-search-content-text">
                                  {src.name} {`(${src.label})`}
                                </p>
                              )}
                              {!src.label && (
                                <p className="ms-search-content-text">
                                  {src.name}
                                </p>
                              )}
                            </div>
                            {props.selected && props.selected.length > 0 && (
                              <img src={checkCircleIcon} alt="Clicked Icon" />
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}

              {newSearchContent &&
                newSearchContent.length > 0 &&
                newSearchContent.map((src, idx) => (
                  <>
                    {props.type === "area-code" && (
                      <div
                        key={idx}
                        onClickCapture={() => {
                          props.setSelected({
                            area:
                              src.idd.suffixes.length > 1
                                ? src.idd.root
                                : src.idd.root + src.idd.suffixes[0],
                            flag: src.flags.svg,
                            name: src.name.official,
                          });
                        }}
                        onClick={() => {
                          navigate(
                            `/${props.pageName
                              ?.toLowerCase()
                              .replace("-", "")}?${idx}`
                          );
                          props.setSelected({
                            area:
                              src.idd.suffixes.length > 1
                                ? src.idd.root
                                : src.idd.root + src.idd.suffixes[0],
                            flag: src.flags.svg,
                            name: src.name.official,
                          });
                          if (props.setSearchActive) {
                            props.setSearchActive(!props.setSearchActive);
                          }
                          props.handleButtonDisplay();
                        }}
                        className="ms-search-content"
                      >
                        <img
                          src={src.flags.svg}
                          alt="Search Result Icon"
                          className="ms-search-content-icon"
                        />
                        <p className="ms-search-content-text">
                          {src.nameArray?.length > 0 &&
                            src.nameArray.map((nm, idx) => (
                              <>
                                {!src.searched.includes(nm.toLowerCase()) && (
                                  <span
                                    key={idx}
                                    style={{
                                      color: "#B6B7C3",
                                    }}
                                  >
                                    {nm}
                                  </span>
                                )}
                                {src.searched.includes(nm.toLowerCase()) && (
                                  <span key={idx}>{nm}</span>
                                )}
                              </>
                            ))}
                        </p>
                      </div>
                    )}
                    {props.type === "language" && (
                      <>
                        {window.location.search !== `?${idx}` && (
                          <div
                            onClickCapture={() => {
                              props.setSelected(src.name);
                            }}
                            onClick={() => {
                              navigate(
                                `/${props.pageName
                                  ?.toLowerCase()
                                  .replace("-", "")}?${idx}`
                              );
                              props.setSelected(src.name);
                              if (props.setSearchActive) {
                                props.setSearchActive(!props.setSearchActive);
                              }
                              props.handleButtonDisplay();
                            }}
                            className="ms-search-content"
                          >
                            <img
                              src={src.icon}
                              alt="Search Result Icon"
                              className="ms-search-content-icon"
                            />
                            {!src.label && (
                              <p className="ms-search-content-text">
                                {src.nameArray?.length > 0 &&
                                  src.nameArray.map((nm, idx) => (
                                    <>
                                      {!src.searched.includes(
                                        nm.toLowerCase()
                                      ) && (
                                        <span
                                          key={idx}
                                          style={{
                                            color: "#B6B7C3",
                                          }}
                                        >
                                          {nm}
                                        </span>
                                      )}
                                      {src.searched.includes(
                                        nm.toLowerCase()
                                      ) && <span key={idx}>{nm}</span>}
                                    </>
                                  ))}
                              </p>
                            )}
                            {src.label && (
                              <p className="ms-search-content-text">
                                {src.nameArray?.length > 0 &&
                                  src.nameArray.map((nm, idx) => (
                                    <>
                                      {!src.searched.includes(
                                        nm.toLowerCase()
                                      ) && (
                                        <span
                                          key={idx}
                                          style={{
                                            color: "#B6B7C3",
                                          }}
                                        >
                                          {nm}
                                        </span>
                                      )}
                                      {src.searched.includes(
                                        nm.toLowerCase()
                                      ) && <span key={idx}>{nm}</span>}
                                    </>
                                  ))}
                                {!src.searched.includes(
                                  src.label.toLowerCase()
                                ) && (
                                  <span
                                    style={{
                                      color: "#B6B7C3",
                                    }}
                                  >{` (${src.label})`}</span>
                                )}
                                {src.searched.includes(
                                  src.label.toLowerCase()
                                ) && <span>{` (${src.label})`}</span>}
                              </p>
                            )}
                          </div>
                        )}
                        {window.location.search === `?${idx}` && (
                          <div
                            key={idx}
                            onClickCapture={() => {
                              props.setSelected(src.name);
                            }}
                            onClick={() => {
                              navigate(
                                `/${props.pageName
                                  ?.toLowerCase()
                                  .replace("-", "")}?${idx}`
                              );
                              props.setSelected(src.name);
                              if (props.setSearchActive) {
                                props.setSearchActive(!props.setSearchActive);
                              }
                              props.handleButtonDisplay();
                            }}
                            className="ms-search-content-active"
                          >
                            <div className="ms-search-content-text-container">
                              <img
                                src={src.icon}
                                alt="Search Result Icon"
                                className="ms-search-content-icon"
                              />
                              {src.label && (
                                <p className="ms-search-content-text">
                                  {src.name} {`(${src.label})`}
                                </p>
                              )}
                              {!src.label && (
                                <p className="ms-search-content-text">
                                  {src.name}
                                </p>
                              )}
                            </div>

                            {props.selected && props.selected.length > 0 && (
                              <img src={checkCircleIcon} alt="Clicked Icon" />
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileSearchBox;
