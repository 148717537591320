import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllPools } from "../services/subraphs/vaultSubgraph";
import {
  currenciesToFiat,
  currenciesToSymbol,
  PoolType,
  securityCategoriesAlias,
} from "../utils/constants";
import {
  fetchSecurityByAddress,
  fetchUserByAddress,
} from "../services/subraphs/walletSubraph";
import { ethers } from "ethers";
import {
  capitalizeFirstLetter,
  convertFromBlockTimestamp,
  fetchMarginPoolCurrentPrice,
  getYesterdayTimeFromNow,
} from "../utils/helpers";
import { getDefaultChainWeb3, getWeb3 } from "../services/contracts";
import SECURITYABI from "../utils/abis/Security.json";
import { readIpfsDocumentFromHash } from "../lib/pinataIpfs";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartExporting from "highcharts/modules/exporting";

export const useGetAllPools = (
  updateList,
  investorAddress = null,
  includeResolutions = false
) => {
  const [data, setData] = useState([]);
  const [poolChartImgs, setPoolChartImgs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const chartRef = useRef(null);
  const { chainId, provider, web3 } = useSelector((state) => state.network);
  HighchartExporting(Highcharts);

  useEffect(() => {
    const fetchAndFormatAllPools = async () => {
      setLoading(true);
      setError(null);
      try {
        let _web3;
        if (_web3) {
          _web3 = web3;
        } else {
          _web3 = getDefaultChainWeb3();
        }
        //step1: fetch all pools
        const allPoolsRaw = await fetchAllPools(chainId).then((res) => res);
        let lazyRender = true;
        //step 2 format fetched pools for UI rendering
        const formatedPoolsPromises = [];
        let allPoolChartImgs = [];

        async function formatPoolsSequentially() {
          for (let i = 0; i < allPoolsRaw.length; i++) {
            const pool = allPoolsRaw[i];
            let volume = [];
            let pricesRaw = [];
            let securityType,
              prices,
              currentPrice,
              margin,
              minOrderSize,
              collateral,
              poolBuyPrice,
              poolSellPrice,
              poolInvestorsRaw,
              poolInvestors,
              totalSellers,
              totalBuyers,
              totalSold,
              totalBought,
              poolAveragePrice,
              resolutions,
              pricesBeforeTodayFmt,
              offeringDocData;

            // Get additional security and owner details
            const fetchedSecurityDetails = await fetchSecurityByAddress(
              chainId,
              pool.security
            );
            const owner =
              fetchedSecurityDetails.length > 0
                ? fetchedSecurityDetails[0].issueManager
                : "";
            const securityCategory =
              fetchedSecurityDetails.length > 0
                ? ethers.utils.parseBytes32String(
                    fetchedSecurityDetails[0]?.productCategory
                  )
                : "";

            const securityDeatils = pool.tokens.find(
              (tkn) => tkn.address === pool.security
            );
            const currencyDetails = pool.tokens.find(
              (tkn) => tkn.address === pool.currency
            );
            const vptDetails = pool.tokens.find(
              (tkn) => tkn.address === pool.address
            );

            // Format primary issue pools
            if (pool.poolType === PoolType.primaryPool) {
              const buyOrders = pool.primarySubscriptions.filter(
                (ord) => ord.assetIn.address === pool.currency
              );
              const sellOrders = pool.primarySubscriptions.filter(
                (ord) => ord.assetIn.address === pool.security
              );

              const buyOrdersFmt = buyOrders.reduce(
                (acc, curr) => {
                  acc.totalAmount += Number(curr.subscription);

                  if (!acc.investors.includes(curr.investor.id)) {
                    acc.investors.push(curr.investor.id);
                  }

                  if (Number(acc.date) < Number(curr.executionDate)) {
                    acc.date = Number(curr.executionDate);
                    acc.price = Number(curr.price);
                  }

                  return acc;
                },
                { totalAmount: 0, investors: [], price: 0, date: 0 }
              );

              const sellOrdersFmt = sellOrders.reduce(
                (acc, curr) => {
                  acc.totalAmount += Number(curr.subscription);

                  if (
                    !acc.investors.includes(curr.investor.id?.toLowerCase())
                  ) {
                    acc.investors.push(curr.investor.id?.toLowerCase());
                  }

                  if (Number(acc.date) < Number(curr.executionDate)) {
                    acc.date = Number(curr.executionDate);
                    acc.price = Number(curr.price);
                  }

                  return acc;
                },
                { totalAmount: 0, investors: [], price: 0, date: 0 }
              );

              prices =
                fetchedSecurityDetails.length > 0
                  ? fetchedSecurityDetails[0]?.primarySubscribers
                      ?.sort(
                        (a, b) => Number(b.timestamp) - Number(a.timestamp)
                      )
                      .map((sub) => {
                        const timeFmt = convertFromBlockTimestamp(
                          sub.timestamp
                        );
                        const timeFmtArray = timeFmt.split("/");
                        volume.push([
                          Date.UTC(
                            Number(timeFmtArray[2]),
                            Number(timeFmtArray[0]) - 1,
                            Number(timeFmtArray[1])
                          ),
                          Number(
                            ethers.utils.formatUnits(
                              sub.securitySwapped,
                              securityDeatils.decimals
                            )
                          ).toFixed(6),
                        ]);
                        pricesRaw.push(
                          Number(
                            ethers.utils.formatUnits(
                              sub.securitySwapped,
                              securityDeatils.decimals
                            )
                          ).toFixed(6)
                        );
                        return [
                          Date.UTC(
                            Number(timeFmtArray[2]),
                            Number(timeFmtArray[0]) - 1,
                            Number(timeFmtArray[1])
                          ),
                          Number(
                            (
                              Number(
                                ethers.utils.formatUnits(
                                  sub.cashSwapped,
                                  currencyDetails.decimals
                                )
                              ) /
                              Number(
                                ethers.utils.formatUnits(
                                  sub.securitySwapped,
                                  securityDeatils.decimals
                                )
                              )
                            ).toFixed(6)
                          ),
                        ];
                      })
                  : [];
              currentPrice = prices.length > 0 ? Number(prices[0][1]) : "0.00";
              minOrderSize = pool.minimumOrderSize;
              poolInvestorsRaw = buyOrdersFmt.investors;
              poolInvestors = buyOrdersFmt.investors.length;
              totalBuyers = buyOrdersFmt.investors.length;
              totalSellers = sellOrdersFmt.investors.length;
              totalBought = buyOrdersFmt.totalAmount.toFixed(6);
              poolAveragePrice = (
                buyOrdersFmt.totalAmount / buyOrders.length
              ).toFixed(6);
              totalSold = sellOrdersFmt.totalAmount.toFixed(6);
              poolBuyPrice = Number(buyOrdersFmt?.price).toFixed(4);
              poolSellPrice = sellOrdersFmt?.price?.toFixed(4);
              const yesterdayDateRaw = getYesterdayTimeFromNow();
              const pricesBeforeToday = prices.filter(
                (pr) => new Date().getTime() > Number(pr[0])
              );
              offeringDocData = await readIpfsDocumentFromHash(
                pool?.offeringDocs
              );
              pricesBeforeTodayFmt = pricesBeforeToday.reduce(
                (acc, curr) => {
                  if (
                    Number(curr[0]) >= Number(yesterdayDateRaw) &&
                    acc.priceBeforeToday < Number(curr[1])
                  ) {
                    acc.yesterdayPrice = Number(curr[1]);
                  }

                  if (
                    Number(curr[0]) < Number(yesterdayDateRaw) &&
                    acc.priceBeforeToday < Number(curr[1])
                  ) {
                    acc.priceBeforeToday = Number(curr[1]);
                  }
                  return acc;
                },
                { yesterdayPrice: 0, priceBeforeToday: 0 }
              );

              if (
                includeResolutions &&
                investorAddress?.length > 0 &&
                fetchedSecurityDetails?.length > 0
              ) {
                const isPrimarySubscriber =
                  fetchedSecurityDetails[0]?.primarySubscribers?.find(
                    (data) =>
                      data?.investor?.id.toLowerCase() ===
                      investorAddress.toLowerCase()
                  );
                if (isPrimarySubscriber && web3) {
                  const resolutionPromises =
                    fetchedSecurityDetails[0]?.resolutions?.map(
                      async (resolution) => {
                        const securityTokenContract = new web3.eth.Contract(
                          SECURITYABI,
                          resolution.security.id
                        );
                        const securityTokenSymbol = securityDeatils.symbol;
                        const voteCounts = await securityTokenContract.methods
                          .countVotes(resolution.recordDate)
                          .call()
                          .then((res) => res);
                        let votingDisabled = false;
                        const currentDate = new Date();
                        const fileData = await readIpfsDocumentFromHash(
                          resolution.resolution
                        );
                        if (
                          new Date(resolution.recordDate * 1000) < currentDate
                        )
                          votingDisabled = true;
                        return {
                          id: resolution.security.id + resolution.recordDate,
                          securityAddress: resolution.security.id,
                          securityTokenSymbol: securityTokenSymbol,
                          offeringDocs: resolution.resolution,
                          fileData,
                          dateRaw: resolution.recordDate,
                          date: convertFromBlockTimestamp(
                            resolution.recordDate
                          ),
                          isVotable: resolution.voting ? "Yes" : "No",
                          poolType: pool.poolType,
                          votingDisabled,
                          totalVotes: voteCounts?.total,
                          totalPositives: voteCounts?.yes,
                          resolutionType: votingDisabled
                            ? "closed"
                            : resolution.voting
                            ? "upcoming-votable"
                            : "upcoming",
                        };
                      }
                    );
                  resolutions = await Promise.all(resolutionPromises).then(
                    (res) => res
                  );
                }
              }
            }

            //format secondary issue pools
            if (pool.poolType === PoolType.secondaryPool) {
              const buyOrders = pool.orders.filter(
                (ord) => ord.tokenIn.address === pool.currency
              );
              const sellOrders = pool.orders.filter(
                (ord) => ord.tokenIn.address === pool.security
              );
              const buyOrdersFmt = buyOrders.reduce(
                (acc, curr) => {
                  acc.totalAmount += Number(curr.amountOffered);

                  if (!acc.investors.includes(curr.creator?.toLowerCase())) {
                    acc.investors.push(curr.creator?.toLowerCase());
                  }
                  return acc;
                },
                { totalAmount: 0, investors: [] }
              );
              const sellOrdersFmt = sellOrders.reduce(
                (acc, curr) => {
                  acc.totalAmount += Number(curr.amountOffered);

                  if (!acc.investors.includes(curr.creator)) {
                    acc.investors.push(curr.creator);
                  }
                  return acc;
                },
                { totalAmount: 0, investors: [] }
              );
              prices =
                fetchedSecurityDetails.length > 0
                  ? fetchedSecurityDetails[0].secondaryInvestors
                      ?.sort(
                        (a, b) => Number(b.timestamp) - Number(a.timestamp)
                      )
                      .map((inv) => {
                        const timeFmt = convertFromBlockTimestamp(
                          inv.timestamp
                        );
                        const timeFmtArray = timeFmt.split("/");
                        volume.push([
                          Date.UTC(
                            Number(timeFmtArray[2]),
                            Number(timeFmtArray[0]) - 1,
                            Number(timeFmtArray[1])
                          ),
                          Number(
                            Number(
                              ethers.utils.formatUnits(
                                inv.amount,
                                securityDeatils.decimals
                              )
                            ).toFixed(6)
                          ),
                        ]);
                        pricesRaw.push(
                          Number(
                            Number(ethers.utils.formatEther(inv.price)).toFixed(
                              6
                            )
                          )
                        );
                        return [
                          Date.UTC(
                            Number(timeFmtArray[2]),
                            Number(timeFmtArray[0]) - 1,
                            Number(timeFmtArray[1])
                          ),
                          Number(
                            Number(ethers.utils.formatEther(inv.price)).toFixed(
                              6
                            )
                          ),
                        ];
                      })
                  : [];
              currentPrice = prices.length > 0 ? Number(prices[0][1]) : "0.00";
              poolInvestorsRaw = buyOrdersFmt.investors;
              poolInvestors = buyOrdersFmt.investors.length;
              totalBuyers = buyOrdersFmt.investors.length;
              totalSellers = sellOrdersFmt.investors.length;
              totalBought = buyOrdersFmt.totalAmount.toFixed(6);
              poolAveragePrice = (
                buyOrdersFmt.totalAmount / buyOrders.length
              ).toFixed(6);
              totalSold = sellOrdersFmt.totalAmount.toFixed(6);
              minOrderSize = ethers.utils.formatEther(pool.minOrderSize);
              const yesterdayDateRaw = getYesterdayTimeFromNow();
              const pricesBeforeToday = prices.filter(
                (pr) => new Date().getTime() > Number(pr[0])
              );
              pricesBeforeTodayFmt = pricesBeforeToday.reduce(
                (acc, curr) => {
                  if (
                    Number(curr[0]) >= Number(yesterdayDateRaw) &&
                    acc.priceBeforeToday < Number(curr[1])
                  ) {
                    acc.yesterdayPrice = Number(curr[1]);
                  }

                  if (
                    Number(curr[0]) < Number(yesterdayDateRaw) &&
                    acc.priceBeforeToday < Number(curr[1])
                  ) {
                    acc.priceBeforeToday = Number(curr[1]);
                  }
                  return acc;
                },
                { yesterdayPrice: 0, priceBeforeToday: 0 }
              );
              if (
                includeResolutions &&
                investorAddress?.length > 0 &&
                fetchedSecurityDetails?.length > 0
              ) {
                const isSecondaryInvestor =
                  fetchedSecurityDetails[0]?.secondaryInvestors?.find(
                    (data) =>
                      data?.investor?.id?.toLowerCase() ===
                        investorAddress.toLowerCase() ||
                      data?.issuer?.id?.toLowerCase() ===
                        investorAddress.toLowerCase()
                  );
                if (isSecondaryInvestor && web3) {
                  const resolutionPromises =
                    fetchedSecurityDetails[0]?.resolutions?.map(
                      async (resolution) => {
                        const securityTokenContract = new web3.eth.Contract(
                          SECURITYABI,
                          resolution.security.id
                        );
                        const securityTokenSymbol = securityDeatils.symbol;
                        const voteCounts = await securityTokenContract.methods
                          .countVotes(resolution.recordDate)
                          .call()
                          .then((res) => res);
                        let votingDisabled = false;
                        const currentDate = new Date();
                        const fileData = await readIpfsDocumentFromHash(
                          resolution.resolution
                        );
                        if (
                          new Date(resolution.recordDate * 1000) < currentDate
                        )
                          votingDisabled = true;
                        return {
                          id: resolution.security.id + resolution.recordDate,
                          securityAddress: resolution.security.id,
                          securityTokenSymbol: securityTokenSymbol,
                          offeringDocs: resolution.resolution,
                          dateRaw: resolution.recordDate,
                          date: convertFromBlockTimestamp(
                            resolution.recordDate
                          ),
                          fileData,
                          isVotable: resolution.voting ? "Yes" : "No",
                          poolType: pool.poolType,
                          votingDisabled,
                          totalVotes: voteCounts?.total,
                          totalPositives: voteCounts?.yes,
                          resolutionType: votingDisabled
                            ? "closed"
                            : resolution.voting
                            ? "upcoming-votable"
                            : "upcoming",
                        };
                      }
                    );
                  resolutions = await Promise.all(resolutionPromises).then(
                    (res) => res
                  );
                }
              }
            }

            //format margin issue pools
            if (pool.poolType === PoolType.marginPool) {
              const buyOrders = pool.marginOrders.filter(
                (ord) => ord.tokenIn.address === pool.currency
              );
              const sellOrders = pool.marginOrders.filter(
                (ord) => ord.tokenIn.address === pool.security
              );
              const buyOrdersFmt = buyOrders.reduce(
                (acc, curr) => {
                  acc.totalAmount += Number(curr.amountOffered);

                  if (!acc.investors.includes(curr.creator?.toLowerCase())) {
                    acc.investors.push(curr.creator?.toLowerCase());
                  }
                  return acc;
                },
                { totalAmount: 0, investors: [] }
              );
              const sellOrdersFmt = sellOrders.reduce(
                (acc, curr) => {
                  acc.totalAmount += Number(curr.amountOffered);

                  if (!acc.investors.includes(curr.creator)) {
                    acc.investors.push(curr.creator);
                  }
                  return acc;
                },
                { totalAmount: 0, investors: [] }
              );

              const marginPrices =
                fetchedSecurityDetails.length > 0
                  ? fetchedSecurityDetails[0].marginTraders
                      ?.sort(
                        (a, b) => Number(b.timestamp) - Number(a.timestamp)
                      )
                      .map((trd) => {
                        const timeFmt = convertFromBlockTimestamp(
                          trd.timestamp
                        );
                        const timeFmtArray = timeFmt.split("/");
                        volume.push([
                          Date.UTC(
                            Number(timeFmtArray[2]),
                            Number(timeFmtArray[0]) - 1,
                            Number(timeFmtArray[1])
                          ),
                          Number(
                            Number(
                              ethers.utils.formatUnits(
                                trd.securityTraded,
                                securityDeatils.decimals
                              )
                            ).toFixed(6)
                          ),
                        ]);
                        pricesRaw.push(
                          Number(
                            Number(
                              ethers.utils.formatUnits(
                                trd.cashTraded,
                                currencyDetails.decimals
                              ) /
                                ethers.utils.formatUnits(
                                  trd.securityTraded,
                                  securityDeatils.decimals
                                )
                            ).toFixed(6)
                          )
                        );
                        return [
                          Date.UTC(
                            Number(timeFmtArray[2]),
                            Number(timeFmtArray[0]) - 1,
                            Number(timeFmtArray[1])
                          ),
                          Number(
                            Number(
                              ethers.utils.formatUnits(
                                trd.cashTraded,
                                currencyDetails.decimals
                              ) /
                                ethers.utils.formatUnits(
                                  trd.securityTraded,
                                  securityDeatils.decimals
                                )
                            ).toFixed(6)
                          ),
                        ];
                      })
                  : [];

              const marginCurrentPrice = "0.00";

              //Number(
              //   await fetchMarginPoolCurrentPrice(
              //     currencyDetails.symbol,
              //     securityDeatils.symbol,
              //     pool.cficode
              //       ? ethers.utils
              //           .parseBytes32String(pool.cficode)
              //           .startsWith("0x")
              //         ? _web3.utils.hexToAscii(
              //             ethers.utils.parseBytes32String(pool.cficode)
              //           )
              //         : ethers.utils.parseBytes32String(pool.cficode)
              //       : "",
              //     "BUY"
              //   )
              // ).toFixed(2);
              currentPrice =
                Number(marginCurrentPrice) > 0
                  ? marginCurrentPrice
                  : marginPrices[0]
                  ? Number(marginPrices[0][1])
                  : "0.00";
              prices = marginPrices;
              poolInvestorsRaw = buyOrdersFmt.investors;
              poolInvestors = buyOrdersFmt.investors.length;
              totalBuyers = buyOrdersFmt.investors.length;
              totalSellers = sellOrdersFmt.investors.length;
              totalBought = buyOrdersFmt.totalAmount.toFixed(6);
              poolAveragePrice = (
                buyOrdersFmt.totalAmount / buyOrders.length
              ).toFixed(6);
              totalSold = sellOrdersFmt.totalAmount.toFixed(6);
              const typeInString = ethers.utils.parseBytes32String(
                pool.securityType
              );
              securityType = typeInString;
              // if (typeInString.toLocaleLowerCase().startsWith("0x")) {
              //   securityType = web3.utils.toAscii(typeInString);
              // } else {
              //   securityType = typeInString;
              // }
              margin = ethers.utils.formatEther(pool.margin);
              minOrderSize = ethers.utils.formatEther(pool.minOrderSize);
              collateral = ethers.utils.formatEther(pool.collateral);
              const yesterdayDateRaw = getYesterdayTimeFromNow();
              const pricesBeforeToday = prices.filter(
                (pr) => new Date().getTime() > Number(pr[0])
              );
              pricesBeforeTodayFmt = pricesBeforeToday.reduce(
                (acc, curr) => {
                  if (
                    Number(curr[0]) >= Number(yesterdayDateRaw) &&
                    acc.priceBeforeToday < Number(curr[1])
                  ) {
                    acc.yesterdayPrice = Number(curr[1]);
                  }

                  if (
                    Number(curr[0]) < Number(yesterdayDateRaw) &&
                    acc.priceBeforeToday < Number(curr[1])
                  ) {
                    acc.priceBeforeToday = Number(curr[1]);
                  }
                  return acc;
                },
                { yesterdayPrice: 0, priceBeforeToday: 0 }
              );
            }

            const investorSecurityBalance =
              web3 && investorAddress?.length > 0
                ? await new web3.eth.Contract(
                    SECURITYABI,
                    pool.security
                  ).methods
                    .balanceOf(investorAddress)
                    .call()
                : 0;

            const lineChartOptions = {
              title: {
                text: null,
              },
              chart: {
                type: "line",
                zoomType: "x",
                height: "300px",
                backgroundColor: "transparent",
              },
              subtitle: {
                text: null,
              },
              xAxis: {
                categories: Array.from(
                  { length: pricesRaw.length },
                  (_, idx) => idx + 1
                ),
                labels: {
                  enabled: false,
                },
                gridLineWidth: 0,
                tickLength: 0,
                lineWidth: 0,
                reversed: true,
              },
              yAxis: [
                {
                  title: {
                    text: null,
                  },
                  labels: {
                    enabled: false,
                  },
                  gridLineWidth: 0,
                  tickLength: 0,
                  lineWidth: 0,
                  opposite: true,
                },
              ],
              tooltip: {
                enabled: false,
              },
              credits: {
                enabled: false,
              },
              series: [
                {
                  name: `Price`,
                  data: pricesRaw,
                  tooltip: {
                    valueDecimals: 4,
                  },
                  marker: {
                    enabled: false,
                  },
                  tickLength: 2,
                  lineWidth: 2,
                  color:
                    Number(currentPrice) >=
                    Number(pricesBeforeTodayFmt?.priceBeforeToday)
                      ? "#38602b"
                      : "#bc1919",
                },
              ],
              legend: {
                enabled: false,
              },
              exporting: {
                enabled: true,
                type: "image/svg+xml",
              },
            };

            const chart = (
              <HighchartsReact
                highcharts={Highcharts}
                options={lineChartOptions}
                ref={chartRef}
              />
            );
            let poolChartImg;
            if (chartRef.current) {
              const chartImg = chartRef.current.chart.getSVG();
              poolChartImg = `data:image/svg+xml;base64,${btoa(chartImg)}`;
            }

            // Prepare the formatted pool data
            const formattedPool = {
              id: pool.id,
              address: pool.address,
              poolType: pool.poolType,
              chart,
              poolChartImg,
              chartImg: i === allPoolsRaw.length - 1 ? allPoolChartImgs : null,
              security: pool.security,
              currency: pool.currency,
              vpt: pool.address,
              securityName: securityDeatils.name,
              securitySymbol: securityDeatils.symbol,
              securityBalance: securityDeatils.balance,
              securityDecimals: securityDeatils.decimals,
              securityType,
              securityCategory,
              securityCategoryAlias:
                securityCategoriesAlias[securityCategory.toLowerCase()],
              currencyName: currencyDetails.name,
              currencySymbol: currencyDetails.symbol,
              currencyBalance: currencyDetails.balance,
              currencyDecimals: currencyDetails.decimals,
              currencyFiatSymbol: currenciesToFiat[currencyDetails.symbol]
                ? currenciesToSymbol[currenciesToFiat[currencyDetails.symbol]]
                : currenciesToSymbol[currencyDetails.symbol]
                ? currenciesToSymbol[currencyDetails.symbol]
                : currencyDetails.symbol,
              vptName: vptDetails.name,
              vptSymbol: vptDetails.symbol,
              vptBalance: vptDetails.balance,
              vptDecimals: vptDetails.decimals,
              prices,
              currentPrice,
              volume,
              tokens: pool.tokens,
              swapFee: pool.swapFee,
              tokensList: pool.tokensList,
              margin,
              cfiCode: pool.cficode
                ? ethers.utils.parseBytes32String(pool.cficode)
                : "",
              collateral,
              minOrderSize,
              minimumOrderSize: minOrderSize,
              minPrice: pool.minimumPrice,
              minimumPrice: pool.minimumPrice,
              owner,
              factory: pool.factory,
              orderBook: pool.orderBook,
              offeringDocs: pool.offeringDocs,
              cutoffTime: pool.cutoffTime,
              cutoffTimeFmt: convertFromBlockTimestamp(Number(pool.cutoffTime)),
              createTime: pool.createTime,
              createTimeFmt: convertFromBlockTimestamp(Number(pool.createTime)),
              securityData: fetchedSecurityDetails[0],
              SecurityToFiatPair: currenciesToFiat[currencyDetails.symbol]
                ? `${securityDeatils.symbol}/${
                    currenciesToFiat[currencyDetails.symbol]
                  }`
                : `${securityDeatils.symbol}/${currencyDetails.symbol}`,
              primarySubscriptions: pool.primarySubscriptions,
              secondaryPreTrades: pool.secondaryPreTrades,
              secondaryTrades: pool.secondaryTrades,
              securityOffered: pool.securityOffered,
              swapEnabled: pool.swapEnabled,
              orders: pool.orders,
              marginOrders: pool.marginOrders,
              totalShares: pool.totalShares,
              totalSwapFee: pool.totalSwapFee,
              totalSwapVolume: pool.totalSwapVolume,
              poolInvestorsRaw,
              poolInvestors,
              poolBuyPrice,
              poolSellPrice,
              totalBought,
              totalBuyers,
              totalSold,
              totalSellers,
              offeringDocData,
              totalInvestorBalance:
                Number(investorSecurityBalance) > 0
                  ? ethers.utils.formatUnits(
                      investorSecurityBalance,
                      securityDeatils.decimals
                    )
                  : 0,
              totalInvestorSecurityValue:
                Number(investorSecurityBalance) > 0
                  ? ethers.utils.formatUnits(
                      investorSecurityBalance,
                      securityDeatils.decimals
                    ) * currentPrice
                  : 0,
              totalInvestorSecurityValueYesterday:
                Number(investorSecurityBalance) > 0 &&
                pricesBeforeTodayFmt?.yesterdayPrice
                  ? ethers.utils.formatUnits(
                      investorSecurityBalance,
                      securityDeatils.decimals
                    ) * pricesBeforeTodayFmt?.yesterdayPrice
                  : 0,
              totalInvestorSecurityValueBeforeToday:
                Number(investorSecurityBalance) > 0 &&
                pricesBeforeTodayFmt?.priceBeforeToday
                  ? ethers.utils.formatUnits(
                      investorSecurityBalance,
                      securityDeatils.decimals
                    ) * Number(pricesBeforeTodayFmt?.priceBeforeToday)
                  : 0,
              totalInvestorSecurityValueDefault:
                Number(investorSecurityBalance) > 0 && prices.length > 0
                  ? ethers.utils.formatUnits(
                      investorSecurityBalance,
                      securityDeatils.decimals
                    ) * Number(prices[prices.length - 1][1])
                  : 0,
              poolAveragePrice,
              resolutions,
              name: securityDeatils.name,
              label: securityCategory,
              description: securityDeatils.symbol,
              favourite: false,
              categories: capitalizeFirstLetter(securityCategory),
              region: fetchedSecurityDetails[0]?.ccountry,
              pricesBeforeTodayFmt,
            };
            if (lazyRender) {
              if (!data.find((dt) => dt.id === formattedPool.id)) {
                data.push(formattedPool);
              }

              if (poolChartImg && !poolChartImgs.includes(poolChartImg)) {
                setPoolChartImgs((prev) => [...prev, poolChartImg]);
              }
            }

            formatedPoolsPromises.push(formattedPool);

            // if (i < allPoolsRaw.length - 1) {
            //   await new Promise((resolve) => setTimeout(resolve, 1000)); //1 second timeout?
            // }
          }

          return formatedPoolsPromises;
        }

        formatPoolsSequentially().then((formattedPools) => {
          lazyRender = false;
          setData(
            formattedPools.sort(
              (a, b) => Number(b.createTime) - Number(a.createTime)
            ) //no need since subgraphh fetch in desending order???
          );
          setLoading(false);
        });
      } catch (err) {
        setLoading(false);
        console.error("Error while fetching all pools: ", err);
        setError(
          err.message || "Error while fetching all pools. Try again later."
        );
      }
    };

    // if (investorAddress) {
    //   //clear data on user connnection
    //   setData([]);
    // }
    fetchAndFormatAllPools();
  }, [
    chainId,
    provider,
    web3,
    updateList,
    investorAddress,
    includeResolutions,
  ]);

  return {
    getAllPoolsData: data,
    getAllPoolsChart: poolChartImgs,
    getAllPoolsLoading: loading,
    getAllPoolsError: error,
  };
};
